<div class="container-fluid">
    <div class="row">
        <div class="col-12 text-center py-3 calendar_heading">
            <h4 class="fw-semibold Gabriola m-0">{{annualCalendar}}</h4>
        </div>
    </div>
    <div class="row">
        <div class="col-12 px-5 text-center">
            <img src="assets/images/NewCalendar.jpg" alt="Manasamskriti annual calendar" class="img-fluid"/>
        </div>
    </div>
</div>