import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { CourseDetailsService } from 'src/app/services/course-details.service';
import { EnrollmentsService } from 'src/app/services/enrollments.service';
import { HomeService } from 'src/app/services/home.service';
import { LoggingService } from 'src/app/services/logging.service';
import { LoginService } from 'src/app/services/login.service';
import { ParentDetailsService } from 'src/app/services/parent-details.service';
import { SharedService } from 'src/app/services/shared.service';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
  editParentDetails: FormGroup;
  selectedEnrollment: any;
  selectedEnrollmentDetails: any;
  disableRefund: boolean = true;
  disableCourseChange: boolean = true;
  paginationValues: any;
  editParentData:any
  loggedInUser: any;
  loader: boolean = false;
  selectedAcademicYear: any;
  academicYearsList: any;
  showChangeCourseDialog: boolean = false;
  @ViewChild('enrollmentsData') enrollmentsData: Table | undefined;
  courseTypeArray = [
    { id: '1', label: 'Online' },
    { id: '2', label: 'In-Person' },
  ];
  selectedCourseType: any;
  enrollments = [];
  showLocations: boolean = false;
  completeCourseDetails: any;
  filteredCompleteData: any;
  locationsArray: any;
  categoryList: any;
  selectedLocation: any;
  coursesList: any;
  timings: any;
  showRefundCourseDialog: boolean = false;
  genderArray=[{id:'M', label:"Male"},{id:'F', label:"Female"}];
  previousCentersArray=[{id:"Bellevue",label:"Bellevue"},{id:"Redmond",label:"Redmond"},{id:"Bothell",label:"Bothell"},{id:"Sammamish",label:"Sammamish"},{id:"Olympia",label:"Olympia"},]
  relationShipArray = [{id:'parent', label:'Parent'},{id:'guardian', label:'Guardian'},{id:'self', label:'Self'}]
  countryApiArray: any;
  // refundDetails:any;
  constructor(
    private fb: FormBuilder,
    private appinsightsService: LoggingService,
    private enrollmentService: EnrollmentsService,
    private parentDetails: ParentDetailsService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private courseDetails: CourseDetailsService,
    private sharedService: SharedService,
    public validation: ValidationService
  ) {
    this.editParentDetails = this.fb.group({
      email: new FormControl(
        null,
        Validators.compose([Validators.required,Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')])
      ),
      Alteremail: new FormControl(
        null,
        // Validators.compose([Validators.required])
      ),
      Country: new FormControl(
        null,
        Validators.compose([
          Validators.required,
        ])
      ),
      State: new FormControl(
        null,
        Validators.compose([
          Validators.required,
        ])
      ),
      zipCode: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]{5}$')
        ])
      ),
      city: new FormControl(
        null,
        Validators.compose([
          Validators.required,
        ])
      ),
      firstname: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      Address: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      Lastname: new FormControl(null, Validators.compose([Validators.required])),
      phone: new FormControl(null, Validators.compose([Validators.required])),
      Alterphone: new FormControl(null,),
      childfirstname: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      childLastname: new FormControl(null, Validators.compose([Validators.required])),
      childteluguname: new FormControl(null, Validators.compose([Validators.required])),
      Age: new FormControl(null,Validators.compose([Validators.required])),
      gender: new FormControl(null, Validators.compose([Validators.required])),
      relation: new FormControl(null, Validators.compose([Validators.required])),
      center: new FormControl(null),
    });
  }

  ngOnInit(): void {
    try {
      this.getCountries()
      this.getLogginUsedData();
      this.getAcademicYears();

      
      // this.getCoursesList();
    } catch (error: any) {
      this.appinsightsService.logException(error, 1);
    }
  }
  getCountries(){
    try{
      this.loader =true;
      this.courseDetails.getCountry().subscribe((data)=>{
        this.loader = false;
       this.countryApiArray=data.result;
       console.log(this.countryApiArray)
      },(error:any)=>{
        // this.errorHandling.ErrorHandling(error);
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: Getting error in get all courses Country list api", {error:JSON.stringify(error)})
      })
    }
    catch(error:any){
      this.appinsightsService.logException(error,2)
    }
  }

  public  getPaginationOptions(): any {
    return [5, 10, 15, 20];
  }
  getAcademicYears() {
    try {
      this.courseDetails.getAcademicYears().subscribe((data: any) => {
        this.academicYearsList = data.result;
        this.academicYearsList.forEach((item: any) => {
          if (item.isRegistrationsOpen == true) {
            this.selectedAcademicYear = item;
          }
        });
        this.getCoursesList(this.selectedAcademicYear.id);
        this.getEnrollments(null, this.selectedAcademicYear.id, null);
      });
    } catch (error: any) {
      this.appinsightsService.logException(error);
    }
  }
  getLogginUsedData() {
    try {
      this.loader = true;
      this.parentDetails.getParentDetails().subscribe(
        (data) => {
          if (data.statusCode == 200) {
            this.loggedInUser = data.result;
          }
        },
        (error: any) => {
          if (error.status == 401) {
            this.sharedService.token_Expired.next(true);
            localStorage.clear();
          }
          this.loader = false;
          this.appinsightsService.logException(error);
          this.appinsightsService.logEvent('Error: Getting user details api', {
            error: JSON.stringify(error),
          });
        }
      );
    } catch (error: any) {
      this.loader = false;
      this.appinsightsService.logException(error, 1);
    }
  }
  getEnrollments(enrollmentId: any, accademicYrId: any, ParentId: any) {
    try {
      const enrollmentsData = {
        enrollmentId: enrollmentId,
        academicYearId: accademicYrId,
        parentId: ParentId,
      };
      this.enrollmentService.getEnrollments(enrollmentsData).subscribe(
        (data) => {
          if (enrollmentId == null) {
            this.loader = false;
            this.enrollments = data.result;
            if (  this.enrollments.length > 20) {
              let length = Math.ceil( this.enrollments.length / 10) * 10;
              this.paginationValues[this.getPaginationOptions().length] = length;
            }
          } else {
            this.selectedEnrollmentDetails = data.result[0];
            console.log("userdata", this.selectedEnrollmentDetails)
            if (this.selectedEnrollmentDetails?.courseType == 'In-Person') {
              this.editParentDetails?.get('center')?.setValidators([Validators.required]);
              this.editParentDetails?.get('center')?.updateValueAndValidity();
          
            } else {
              this.editParentDetails?.get('center')?.clearValidators();
              this.editParentDetails?.get('center')?.updateValueAndValidity();
            }
            this.prepopulateCourseDetails(this.selectedEnrollmentDetails);
          }
        },
        (error: any) => {
          if (error.status == 401) {
            this.sharedService.token_Expired.next(true);
            localStorage.clear();
          }
          this.loader = false;
          this.appinsightsService.logException(error);
          this.appinsightsService.logEvent('Error: Getting enrollments api', {
            error: JSON.stringify(error),
          });
        }
      );
    } catch (error: any) {
      this.loader = false;
      this.appinsightsService.logException(error, 2);
    }
  }
  selectEnrollment(enrollment: any) {
    try {
      this.disableCourseChange = false;
      this.disableRefund = false;
    
    } catch (error: any) {
      this.appinsightsService.logException(error, 2);
    }
  }
  filterGlobal($event: any, stringVal: any) {
    this.enrollmentsData!.filterGlobal(
      ($event.target as HTMLInputElement).value,
      stringVal
    );
  }
  openChangeCourseDialog() {
    this.editParentDetails.reset();
    this.showChangeCourseDialog = true;
    this.getEnrollments(
      this.selectedEnrollment.enrollmentId,
      this.selectedAcademicYear.id,
      this.selectedEnrollment.parentId
    );
  }
  // openRefundDialog() {
  //   this.showChangeCourseDialog = false;
  //   this.showRefundCourseDialog = true;
  //   this.getEnrollmentRefundDetails(this.selectedEnrollment.enrollmentId);
  // }
  closeScreen() {
    this.showChangeCourseDialog = false;
    this.showRefundCourseDialog = false;
  }
  onSubmit() {
    try {
   
      if (this.editParentDetails.valid) {
        const userUpdateObj = {
          parentFirstName:   this.editParentDetails.controls['firstname'].value,
          parentLastName: this.editParentDetails.controls['Lastname'].value,
          email: this.editParentDetails.controls['email'].value,
          alternateEmail:  this.editParentDetails.controls['Alteremail'].value,
          phone:  this.editParentDetails.controls['phone'].value,
          alternatePhone:  this.editParentDetails.controls['Alterphone'].value,
          address:  this.editParentDetails.controls['Address'].value,
          studentFirstName:  this.editParentDetails.controls['childfirstname'].value,
          studentLastName:  this.editParentDetails.controls['childLastname'].value,
          age: this.editParentDetails.controls['Age'].value,
          previousAttendedCenter:  this.editParentDetails.controls['center'].value?.id || null,
          teluguName:  this.editParentDetails.controls['childteluguname'].value,
          gender:  this.editParentDetails.controls['gender'].value?.id,
          relation:  this.editParentDetails.controls['relation'].value?.id
        };
        this.enrollmentService
          .updateUserEnrollment(
            userUpdateObj,
            this.selectedEnrollmentDetails?.parentId,
            this.selectedEnrollmentDetails?.studentId
          )
          .subscribe((data) => {
            if(data.statusCode == 200){
              this.messageService.add({
                severity:'success',
                summary: 'Success',
                detail: 'User Data Updated successfully'
              });
            }
            this.showChangeCourseDialog = false;
            this.showRefundCourseDialog = false;
            this.getEnrollments(null, this.selectedAcademicYear.id, null);
          },
          (error: any) => {
            this.appinsightsService.logException(error, 1);
            this.appinsightsService.logEvent(
              'Error: Getting error in change course api',
              { error: JSON.stringify(error) }
            );
            if (error.status == 401) {
              this.sharedService.token_Expired.next(true);
              localStorage.clear();
            } else {
              this.messageService.add({
                severity:'warn',
                summary: 'Warning',
                detail: error.error.title
              });
              this.appinsightsService.logException(error, 2);
            }
          });
      } else {
        for (const field in this.editParentDetails.controls) {
          this.editParentDetails.controls[field].markAsDirty();
        }
      }
    } catch (error: any) {
      this.appinsightsService.logException(error, 2);
    }
  }

  navigateToDetailView(enrollment: any) {
    this.router.navigate([`/enrollments/${enrollment.enrollmentId}`]);
  }
  onCourseTypeSelection() {
    const locationName = this.editParentDetails.controls['locationName'];
    if (this.selectedCourseType.id == 1) {
      this.showLocations = false;
       locationName.reset();
      locationName.clearValidators();
      this.filteredCompleteData = this.completeCourseDetails.onlineCourses;
      this.filterCategoryBasedOnType(this.filteredCompleteData);
    } else {
      this.showLocations = true;
       locationName.reset();
      locationName.setValidators(Validators.required);
      this.filteredCompleteData = this.completeCourseDetails.offlineCourses;
      this.filterLocationsBasedOnType(this.filteredCompleteData);
    }
    locationName.updateValueAndValidity();
  }
  async getCoursesList(academicId:any) {
    try {
      await this.validation.decodeLoggedInToken();
      await this.courseDetails.getCourseDetails(academicId,null).subscribe(
        (data) => {
          if (data.statusCode == 200) {
            this.completeCourseDetails = data.result;
          }
        },
        (error: any) => {
          this.appinsightsService.logException(error, 1);
          this.appinsightsService.logEvent(
            'Error: Getting error in get course list api',
            { error: JSON.stringify(error) }
          );
          if (error.status == 401) {
            this.sharedService.token_Expired.next(true);
            localStorage.clear();
          } else {
            this.appinsightsService.logException(error, 2);
          }
        }
      );
    } catch (error: any) {
      this.appinsightsService.logException(error, 2);
    }
  }
  filterLocationsBasedOnType(courseTypeData: any) {
    try {
      // this.duplicateEntry = false;
      this.locationsArray = [];
      courseTypeData.map((x: any) => {
        x.branchSlots.map((y: any) => {
          this.locationsArray.push(y);
        });
      });
      const locationsFiltered = Array.from(
        this.locationsArray
          .reduce((m: any, t: any) => m.set(t.branchName, t), new Map())
          .values()
      );
      this.locationsArray = locationsFiltered;
    } catch (error: any) {
      this.appinsightsService.logException(error, 2);
    }
  }

  filterCategoryBasedOnType(courseTypeData: any) {
    try {
      // this.duplicateEntry = false;
      this.categoryList = [];
      courseTypeData.map((x: any) => {
        this.categoryList.push({ label: x.categoryName, id: x.categoryId });
      });
      const catogoriesFiltered = Array.from(
        this.categoryList
          .reduce((m: any, t: any) => m.set(t.label, t), new Map())
          .values()
      );
      this.categoryList = catogoriesFiltered;
    } catch (error: any) {
      this.appinsightsService.logException(error, 2);
    }
  }
  onLocationSelection() {
    try {
      // this.duplicateEntry = false;
      let filteredcoursesBasedOnLocation: any = [];
      for (let i of this.filteredCompleteData) {
        if (i?.branchSlots[0]?.branchId == this.selectedLocation.branchId) {
          filteredcoursesBasedOnLocation.push(i);
        }
      }
      // this.filteredCompleteData = filteredcoursesBasedOnLocation
      this.filterCategoryBasedOnType(filteredcoursesBasedOnLocation);
    } catch (error: any) {
      this.appinsightsService.logException(error, 2);
    }
  }
  onCategorySelection(event: any) {
    try {
      // this.duplicateEntry = false;
      this.coursesList = [];
      this.filteredCompleteData.map((x: any) => {
        if (x.categoryId == event?.value?.id || x.categoryId == event?.id)
          if (
            x.branchSlots &&
            x.branchSlots[0].branchId == this.selectedLocation.branchId
          ) {
            this.coursesList.push({ label: x.courseName, id: x.courseId });
          } else if (!x.branchSlots) {
            this.coursesList.push({ label: x.courseName, id: x.courseId });
          }
      });
      const coursesFiltered = Array.from(
        this.coursesList
          .reduce((m: any, t: any) => m.set(t.label, t), new Map())
          .values()
      );
      this.coursesList = coursesFiltered;
    } catch (error: any) {
      this.appinsightsService.logException(error, 2);
    }
  }
  onCourseSelection(event: any) {
    try {
      // this.duplicateEntry = false;
      this.timings = [];
      const amount = this.editParentDetails.controls['amount'];
      const groupId = this.editParentDetails.controls['groupId'];
      const courseCategoryName =
        this.editParentDetails.controls['courseCategoryName'];
      this.filteredCompleteData.map((x: any) => {
        if (
          (x.courseId == event?.value?.id || x.courseId == event?.id) &&
          this.editParentDetails.controls['category'].value.id == x.categoryId
        ) {
          if (
            x.branchSlots &&
            x.branchSlots[0].branchId == this.selectedLocation.branchId
          ) {
            this.timings = x.branchSlots[0]?.slots;
          } else if (!x.branchSlots) {
            this.timings = x.courseSlots;
          }
          amount.patchValue(x.amount);
          groupId.patchValue(x.groupId);
          courseCategoryName.patchValue(x.courseCategory);
        }
      });
    } catch (error: any) {
      this.appinsightsService.logException(error, 2);
    }
  }
  prepopulateCourseDetails(courseDetails: any) {
    this.editParentData = courseDetails
    const email = this.editParentDetails.controls['email'];
    const firstname = this.editParentDetails.controls['firstname'];
    const lastname = this.editParentDetails.controls['Lastname'];
    const phone = this.editParentDetails.controls['phone'];
    const Alteremail = this.editParentDetails.controls['Alteremail']; 
    const Address = this.editParentDetails.controls['Address']; 
    const alterphone = this.editParentDetails.controls['Alterphone']; 
    const childFirst =  this.editParentDetails.controls['childfirstname'];
    const childlast =   this.editParentDetails.controls['childLastname'];
    const childTelugu =  this.editParentDetails.controls['childteluguname'];
    const age =  this.editParentDetails.controls['Age'];
    const gender =  this.editParentDetails.controls['gender'];
    const relation =  this.editParentDetails.controls['relation'];
    const center =  this.editParentDetails.controls['center'];
    const country = this.editParentDetails.controls['Country'];
    const state = this.editParentDetails.controls['State'];
    const city = this.editParentDetails.controls['city'];
    const zipCode = this.editParentDetails.controls['zipCode'];
    let SelectedCountry = this.countryApiArray?.filter((x:any)=>(x.id == courseDetails.countryId))
    country.patchValue(SelectedCountry[0])
    zipCode.patchValue(courseDetails.pinCode)
    city.patchValue(courseDetails.city)
    state.patchValue(courseDetails.state)
    email.patchValue(this.editParentData.parentEmail);
    firstname.patchValue(this.editParentData.parentName)
    childFirst.patchValue(this.editParentData.studentName )
    childTelugu.patchValue(this.editParentData.studentTeluguName)
    alterphone.patchValue(this.editParentData.alternatePhoneNumber)
    Alteremail.patchValue(this.editParentData.alternateEmail)
    lastname.patchValue(this.editParentData.parentLastName)
    Address.patchValue(this.editParentData.address)
    childlast.patchValue(this.editParentData.studentLastName)
    age.patchValue(this.editParentData.studentAge)
    phone.patchValue(this.editParentData.phoneNumber)
    const SelectedGender = this.genderArray?.filter((x)=>(x.id == this.editParentData.gender))
    gender.patchValue(SelectedGender[0])
    const selectedRelation = this.relationShipArray?.filter((x)=>(x.id == this.editParentData.relation))
    relation.patchValue(selectedRelation[0])
    const SelectedCenter = this.previousCentersArray?.filter((x)=>x.id==this.editParentData.centerName)
    center.patchValue(SelectedCenter[0])


    const locations = this.editParentDetails.controls['locationName'];
    const category = this.editParentDetails.controls['category'];
    const course = this.editParentDetails.controls['coursename'];
    const timings = this.editParentDetails.controls['timings'];
    // this.selectedCourseType = this.courseTypeArray.filter(
    //   (item) => item.label == this.selectedEnrollmentDetails.courseType
    // );
    // this.selectedCourseType = this.selectedCourseType[0];
  
    this.onCourseTypeSelection();
    const locationSelected = this.locationsArray?.filter(
      (item: any) => item.branchId == this.selectedEnrollmentDetails.branchId
    );
    if(locationSelected){
    locations.patchValue(locationSelected[0]);
    this.selectedLocation = locationSelected[0];
    }
    this.onLocationSelection();
    const categorySelected = this.categoryList.filter(
      (item: any) => item.id == this.selectedEnrollmentDetails.courseCategoryId
    );
    this.onCategorySelection(categorySelected[0]);
    const courseSelected = this.coursesList.filter(
      (item: any) => item.id == this.selectedEnrollmentDetails.courseId
    );
    category.patchValue(categorySelected[0]);
    this.onCourseSelection(courseSelected[0]);
    course.patchValue(courseSelected[0]);
    const slotSelected = this.timings.filter(
      (item: any) => item.name == this.selectedEnrollmentDetails.timing
    );
    timings.patchValue(slotSelected[0]);
  }

}

