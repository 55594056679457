<div class="container-fluid p-md-1">
    <div class="row">
        <div class="col-12 p-4">
            <h2 class="fw-semibold text-center">Terms and Conditions</h2>
        </div>
    </div>
    <div class="row p-4 mx-3 rounded bg-white card_view shadow">
        <div class="col-12">
            <h5 class="fw-semibold stawberry m-2 p-2">Responsibility/Warranty</h5>
        </div>
        <div class="col-12">
            <ul>
                <li>Mana Samskriti and its staff are NOT responsible for any student during the time of instruction. Parents and guardians are welcome to stay during class. Neither Mana Samskriti nor its staff are responsible for any injury during the time of instruction or during the time any Mana Samskriti event is conducted.</li>
                <li>Neither Mana Samskriti nor its staff provide any kind of warranty on the instruction or resource use.</li>
            </ul>
        </div>
        <div class="col-12">
            <h5 class="fw-semibold stawberry m-2 p-2">Expectations</h5>
        </div>
        <div class="col-12">
            <ul>
                <li>Parents and guardians are expected to provide student access to computer/internet for the student is expected as homework requires access.</li>
                <li>Parents and guardians are expected to help their children with homework. This will help the student reach the goals quickly and effectively. Learning dance or music requires a lot of practice. Parents and guardians are requested and expected to ensure their children practice as much as possible.</li>
                <li>For effective learning, ensure the student attends at least 90% of the classes in the academic year.</li>
                <li> Parents and guardians are encouraged to talk with Mana Samskriti volunteers frequently about the progress of their child’s learning.</li>
            </ul>
        </div>
        <div class="col-12">
            <h5 class="fw-semibold stawberry m-2 p-2">Consent</h5>
        </div>
        <div class="col-12">
            <ul>
            <li>I give permission for son/daughter to be photographed for use for Mana Samsrikti advertising, materials, and/or use on website/social media.</li>
            </ul>
        </div>
        <div class="col-12 text-center">
            <button pButton pRipple type="button" label="Back" class="btn-blue p-0 px-4 py-2" (click)="navigateToCourses()"></button>
        </div>
    </div>
</div>