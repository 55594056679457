import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EnrollmentsService {

  constructor(private http: SharedService) { }

  getEnrollments(enrollmentObj:{enrollmentId?:number | null, academicYearId?:number | null,parentId?:number | null}){
    let url = 'api/Enrollments';
    return this.http.get(url,enrollmentObj)
    .pipe(map((data: any) => data));
  }
  getEnrollmentDetails(enrollmentId:number){
    let url = `api/Enrollments/${enrollmentId}`;
    return this.http.get(url)
    .pipe(map((data: any) => data));
  }

  updateEnrollment(passwordObj:any,courseId:any,studentId:any,enrollmentId:any){
    let url = `api/Enrollments?courseId=${courseId}&studentId=${studentId}&enrollmentId=${enrollmentId}`;
    return this.http.put(url,passwordObj).pipe(map(data => data)) 
  }
  updateUserEnrollment(userData:any,parentId:any,childId:any){
    let url = `api/user/${parentId}/Student/${childId}`;
    return this.http.put(url,userData).pipe(map(data => data)) 
  }
getParentDetails(parentId:any){
  let url = `api/Enrollments/ParentStudentEnrollments?parentId=${parentId}`;
  return this.http.get(url)
    .pipe(map((data: any) => data));
    
}

updateParentDetails(userData:any,parentId:any){
  let url = `api/User/${parentId}`;
  return this.http.put(url,userData).pipe(map(data => data)) 
    
}

updateStudentDetails(userData:any,studenId:any){
  let url = `api/Students/${studenId}`
  return this.http.put(url,userData).pipe(map(data => data)) 
}

  getRefund(enrollmentId:any){
    let url = `api/Payment/refund?enrollmentId=${enrollmentId}`;
    return this.http.post(url)
    .pipe(map((data: any) => data));
  }
  getEnrolledUserData(id:any)
  {
    let url = `api/Enrollments/downloadexcel?academicYearId=${id}`;
    return this.http.get(url,)
    .pipe(map(data => data));
  }
}
