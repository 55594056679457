import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Subject, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  private headers = new HttpHeaders({'Content-Type': 'application/json'});
  constructor( private http: SharedService,) { }
  isUserLoggedIn:any =  new BehaviorSubject<any>(false)
  allCourses: any = new BehaviorSubject(null);

  getLatestNews(numberOfNews:number){
    let url = `api/Home/News?top=${numberOfNews}`;
    return this.http.get(url)
    .pipe(map((data: any) => data));
  }
}
