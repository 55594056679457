import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';
import { BehaviorSubject, ReplaySubject, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CourseDetailsService {

  constructor(private http: SharedService) { }
  
  // country:any =  new ReplaySubject<any>()

  getCourseDetails(academicYearId: any, categoryId: any){
    let url = `api/Courses?academicYearId=${academicYearId}&countryId=${categoryId}`;
    return this.http.get(url)
    .pipe(map((data: any) => data));
  }
  getTimingDetails(academicYearId: any){
    let url = `api/Slots?academicYearId=${academicYearId}`;
    return this.http.get(url)
    .pipe(map((data: any) => data));
  }
  getLocation(academicYearId: any){
    if(academicYearId){ let url = `api/Branches/${academicYearId}`;
      return this.http.get(url)
      .pipe(map((data: any) => data));}else{ let url = `api/Branches`;
      return this.http.get(url)
      .pipe(map((data: any) => data));}
   
  }
getBranchDetails(id:any){
  let url = `api/Branches/${id}`
  return this.http.get(url)
  .pipe(map((data: any) => data));
}

  getCountry(){
    let url = `api/Countries`;
    return this.http.get(url)
    .pipe(map((data: any) => data));
  }

  addNewsCourses(coursesData:any){
    let url = 'api/Enrollments';
    return this.http.post(url, coursesData)
    .pipe(map((data: any) => data));
  }
  getAllCoursesData(academicYearId:any,countryID:any){
    let url = `api/Courses/GroupedByCategory?academicYearId=${academicYearId}&countryId=${countryID}`;
    return this.http.get(url)
    .pipe(map((data: any) => data));
  }
  getCategories(academicYearId:any,countryId:any){
    let url = `api/CourseCategories?academicYearId=${academicYearId}&countryId=${countryId}`
    return this.http.get(url)
    .pipe(map((data: any) => data));
  }

  getAcademicYears(){
    let url = 'api/AcademicYears';
    return this.http.get(url)
    .pipe(map((data: any) => data));
  }
  addNewCategory(catgoriesData:any,type:any,id:any){
    if(type=='Edit'){
      let url = `api/CourseCatgories/${id}`;
      return this.http.put(url, catgoriesData)
      .pipe(map((data: any) => data));

    }
    else{
      let url = 'api/CourseCatgories';
      return this.http.post(url, catgoriesData)
      .pipe(map((data: any) => data));
    }
  }
  
  
}
