<div class="container-fluid">
    <p-toast position="top-center"></p-toast>
    <div class="row">
        <div class="col-12">
            <form [formGroup]="addCourseDetails" (ngSubmit)="onSubmit()">
                <div class="row p-4 rounded bg-white card_view shadow">
                    <div class="col-12 p-2 px-md-5">
                        <h4 class="fw-bold">Add Courses for <span class="text-danger text-capitalize">{{selectedStudentName}}</span> </h4>
                    </div>
            <div class="col-md-12 p-2 px-md-5">
          
    <label for="courseType" class="dark-ask pt-md-3">Course Type<span class="text-danger"> *</span></label>
                        <div class="d-flex align-items-center">
                            <!-- [disabled]="validateTypeofCourse&&course.id === '1'" -->
                            <div  *ngFor="let course of courseTypeArray" class="d-flex align-items-center">
                                <p-radioButton formControlName="courseType" class="pr-2" required [inputId]="course.id" 
                                name="courseType"
                                [value]="course" [(ngModel)]="selectedCourseType" (click)="onCourseTypeSelection()"></p-radioButton>
                                <label class="m-2">{{ course.label }}</label>
                                
                            </div>
                       
                            
                        </div>

                        <div class="text-danger" >
                            <span class="text-capitalize">{{ validation.validateFormControl('courseType','Course Type',addCourseDetails) }}</span>
                        </div>
                        <div *ngIf="this?.validateTypeofCourse?.cityFlag&&selectedCourseType.id === '1'" class="col-md-12 ">
                            <div  class="col-12 p-0"> 
                                <span class=" col-12  fw-semibold text-danger" style="font-size: 14px;"> It looks like you live near Redmond, Bothell, Sammamish, Bellevue, and Monroe areas. Online is more for the students living outside of Seattle area.
                            </span></div>
                    </div>

                    </div>
            
                    <div class="col-xs-12 col-md-6 p-2 px-md-5"  *ngIf="this.selectedCourseType.id != 1" >
                        <label for="Country" class="dark-ask">Country<span class="text-danger"> *</span></label>
                        <p-dropdown name="Country" id="Country"  
                                    formControlName="Country" class="dropdown d-block studentDetail_dropdown" (ngModelChange)="onSelectedCountry($event?.id)" [options]="countryApiArray"  optionLabel="countryName"></p-dropdown>
                        <div class="text-danger" >
                            <span class="text-capitalize"><p-progressSpinner *ngIf="loader" class="custom-spinner" strokeWidth="3"
                                animationDuration=".5s"></p-progressSpinner></span>
                            <span class="text-capitalize">{{ validation.validateFormControl('Country','Country',addCourseDetails) }}</span>
                        </div>
                    </div>
                    <!-- <div class="col-12 pb-md-2 px-md-5" *ngIf="!showLocations">
                      
                        <span class="text-danger col-12  " style="font-size: 14px;">( Note: 1. International shipping charges: $60 , &#160; 2. Domestic shipping charges: $12 )</span>
                    </div> -->
                   
                    <div class="col-12 p-2 px-md-5" *ngIf="showLocations && locationsArray.length > 0; else noLocations">
                        <h6 class="fw-bold">Preferred Location Center</h6>

                    </div>
                    <ng-template #noLocations>
                        <div class="col-12 p-2 px-md-5" *ngIf="showLocations&&!loader">
                            <h6 class="fw-bold"> In-person courses are not available for {{ this.addCourseDetails.controls['Country']?.value?.countryName}}. Please check out our online courses.</h6>
                        </div>
                    </ng-template>
                    
                    <div class=" col-md-12 p-2 px-md-5" *ngIf="this?.showLocations">
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="row d-flex w-100">
                                <div *ngFor="let location of locationsArray" class=" col-md-2 d-flex align-items-center">
                                    <p-radioButton formControlName="locationName" class="pr-2" [inputId]="location.branchId" name="locationName"
                                    [value]="location" [(ngModel)]="selectedLocation" (click)="onLocationSelection()"></p-radioButton>
                                    <label  class="m-2">{{ location.branchName }}</label>
                                </div>
                                <div class="row p-3" >
                                    <div class="colxs-12 col-md-12  ">
                                        {{ this?.selectedLocation?.address}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-danger" >
                            <span class="text-capitalize">{{ validation.validateFormControl('locationName','Location',addCourseDetails) }}</span>
                        </div>
                    </div>

                
                    <div class="col-md-6 p-2 px-md-5">
                        <label for="category" class="dark-ask">Category<span class="text-danger"> *</span></label>
                        <p-dropdown name="category" id="category" placeholder="Select a Category" [options]="categoryList" optionLabel="label" 
                        (onChange)="onCategorySelection($event)" formControlName="category" class="d-block dropdown"></p-dropdown>
                        <div class="text-danger" >
                            <span class="text-capitalize">{{ validation.validateFormControl('category','category',addCourseDetails) }}</span>
                        </div>
                    </div>
                    <div class=" col-md-6 p-2 px-md-5">
                        <label for="coursename" class="dark-ask">Course Name<span class="text-danger"> *</span></label>
                        <p-dropdown name="coursename" id="coursename" placeholder="Select a Course" [options]="coursesList" optionLabel="label" 
                        (onChange)="onCourseSelection($event)" formControlName="coursename" class="d-block dropdown"></p-dropdown>
                        <div class="text-danger" >
                            <span class="text-capitalize">{{ validation.validateFormControl('coursename','Course Name',addCourseDetails) }}</span>
                        </div>
                    </div>
                    <div class=" col-md-6 p-2 px-md-5">
                        <label for="timings" class="dark-ask">Timings<span class="text-danger"> *</span> (Available slots are in Pacific time)</label>
                        <p-dropdown name="timings" id="timings" placeholder="Select a slot" [options]="timings" optionLabel="name" (onChange)="checkAvailability()"
                        formControlName="timings" class="d-block dropdown"></p-dropdown>
                        <div class="text-danger" >
                            <span class="text-capitalize">{{ validation.validateFormControl('timings','timings',addCourseDetails) }}</span>
                        </div>
                    </div>
                    <div *ngIf="duplicateEntry">
                        <p class="text-danger p-2 px-md-5">{{duplicateEntryText}}</p>
                    </div>
                    <div class="col-12 text-xs-center text-md-left p-2 px-md-5">
                        <button pButton pRipple type="button" *ngIf="this.CourseAvailable" label="Add" (click)="addCoursesToArray()"
                        class="btn-blue btn-lg "></button>
                    </div>
                </div>
                <div class="row p-4 rounded bg-white card_view shadow mt-4">
                    <div class="col-12 p-2 px-md-5">
                        <h4 class="fw-bold">Added Courses </h4>
                    </div>
                    <div class="col-12 p-2 px-md-5">
                        <p-table [tableStyle]="{ 'min-width': '50rem' }" [value]="addedCoursesList">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="bg-white">S.No</th>
                                    <th class="bg-white">Type</th>
                                    <th class="bg-white">Location</th>
                                    <th class="bg-white">Category</th>
                                    <th class="bg-white">Course Name</th>
                                    <th class="bg-white">Timings</th>
                                    <th class="bg-white">Amount</th>
                                    <th class="bg-white"></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-course let-rowIndex="rowIndex">
                                <tr *ngIf="addedCoursesList.length == 0">
                                    <p>No courses added</p>
                                </tr>
                                <tr *ngIf="addedCoursesList.length > 0">
                                    <td>{{rowIndex + 1 }}</td>
                                    <td>{{ course.courseType.label }}</td>
                                    <td *ngIf="course?.courseType.id==2; else showCountry">{{ course?.locationName?.branchName }}</td>
                                    <ng-template #showCountry [ngSwitch]="this.countryId">
                                            <td *ngSwitchCase="1">USA</td>
                                            <td *ngSwitchCase="2">Khuwait</td>
                                            <td *ngSwitchCase="3">Canada</td>
                                            <td *ngSwitchCase="4">UK</td>
                                            <td *ngSwitchDefault></td>
                                    </ng-template>
                                    <td>{{ course.category.label }}</td>
                                    <td>{{ course.coursename.label }}</td>
                                    <td>{{ course.timings.name }}</td>
                                    <td class="text-end">{{ course.amount }}</td>
                                    <td class="cursor-pointer"><i class="pi pi-minus-circle text-danger cursor-pointer" (click)="deleteCourse(rowIndex)"></i></td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="footer">
                                <tr>
                                    <!-- <td class="bg-white"></td>
                                    <td class="bg-white"></td>
                                    <td class="bg-white"></td>
                                    <td class="bg-white"></td>
                                    <td class="bg-white"></td> -->
                                    <td class="bg-white text-end amoutText"   [colSpan]="6">Shipping Charge</td>
                                    <td class="bg-white text-end amoutText"  >${{this.shippingAmount}}</td>
                                </tr>
                                <tr>
                                    <!-- <td class="bg-white"></td>
                                    <td class="bg-white"></td>
                                    <td class="bg-white"></td>
                                    <td class="bg-white"></td>
                                    <td class="bg-white"></td> -->
                                    <td class="bg-white text-end"  [colSpan]="6">Total</td>
                                    <td class="bg-white text-end">${{totalAmount}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
                <div class="row d-flex align-items-center justify-content-between">
                    <div class="col-6">
                        <form  [formGroup]="termsAndConditions">
                            <p-checkbox formControlName="terms" value="New York" inputId="ny"></p-checkbox>
                            <label for="ny">By checking this you have agreed to our <a routerLink="/terms&conditions" class="text-dark">terms and conditions</a></label>
                            <div class="text-danger" >
                                <span class="text-capitalize px-4">{{ validation.validateFormControl('terms','Terms and Conditions',termsAndConditions) }}</span>
                            </div>
                        </form>
                    </div>
                    <div class="col-6 p-0 py-3 text-end">
                        <a routerLink="/registration/student-details"><span
                                class="text-danger d-inline-block p-3">Previous</span></a>
                        <button pButton pRipple type="submit" label="Proceed to Payment"
                            class="btn-blue  btn-lg"></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>