import { Component,  OnInit, Renderer2 } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { GlobalResources } from '../../../../services/global-resource/global-resource';
import { ParentDetailsService } from 'src/app/services/parent-details.service';
import { loggedInParent, parentDetails } from './parent-details';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { HomeService } from 'src/app/services/home.service';
import { ValidationService } from 'src/app/services/validation.service';
import { LoggingService } from 'src/app/services/logging.service';
import { LoginService } from 'src/app/services/login.service';
import { SharedService } from 'src/app/services/shared.service';
import { CourseDetailsService } from 'src/app/services/course-details.service';

@Component({
  selector: 'app-parent-details',
  templateUrl: './parent-details.component.html',
  styleUrls: ['./parent-details.component.css'],
})
export class ParentDetailsComponent implements OnInit {
  addParentDetails !: FormGroup;
  previousCentersArray=[{id:6,label:"Bellevue"},{id:7,label:"Redmond"},{id:8,label:"Bothell"},{id:9,label:"Sammamish"},{id:10,label:"Monroe"},]
  otpForm: FormGroup;
  cityFlag:any;
  isValidationNotRequried:any;
  public globalResource: any;
  showOTPScreen: boolean = false;
  disableEmail: boolean = false;
  loader: boolean = false;
  otpError:boolean=false;
  showSuccessMsgForUserVerify: boolean = false;
  editControl: boolean = true;
  otpErrorMsg: string = "";
  showOTPInvalidError: boolean = false;
  loggedInUserData:any;
  selected_CountryId:any;
  validateEmailError: string = "";
  validatePhoneNumberError: string = "";
  userDetailsLoader: boolean = false;
  countryArray=[{id:1,label:"USA"},{id:2,label:"Khuwait"}]
  countryApiArray: any;
  stateApiArray:any=[];
  storeStateApiArray:any=[];
  constructor(private fb: FormBuilder, 
              private homeDetails: HomeService,
              private router: Router,
              private renderer: Renderer2,
              private courseDetails: CourseDetailsService,
              private parentDetails: ParentDetailsService, 
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              private appinsightsService: LoggingService,
              private loginService: LoginService,
              private sharedService: SharedService,
              public validation: ValidationService) {
   
    this.otpForm = this.fb.group({
      firstDigit: new FormControl(null, this.otpValidator()),
      secondDigit: new FormControl(null, this.otpValidator()),
      thirdDigit: new FormControl(null, this.otpValidator()),
      fourthDigit: new FormControl(null, this.otpValidator()),
    })
 
 
    
    this.getCountries()
 
  }

   ngOnInit(): void {
    try{
      this.addParentDetails = this.fb.group({
        email: new FormControl(
          null,
          Validators.compose([
            Validators.required,
            Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
          ])
        ),
        Country: new FormControl(
          null,
          Validators.compose([
            Validators.required,
          ])
        ),
        State: new FormControl(
          null,
          Validators.compose([
            Validators.required,
          ])
        ),
        zipCode: new FormControl(
          null,
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(10)
          ])
        ),
        city: new FormControl(
          null,
          Validators.compose([
            Validators.required,
          ])
        ),
        alternateEmail: new FormControl(
          null,
          Validators.compose([
            Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
          ])
        ),
        firstName: new FormControl(
          null,
          Validators.compose([Validators.required, Validators.maxLength(150), Validators.pattern('^[^a-zA-Z]*[a-zA-Z]+.*$')])
        ),
        lastName: new FormControl(
          null,
          Validators.compose([Validators.required, Validators.maxLength(150),Validators.pattern('^[^a-zA-Z]*[a-zA-Z]+.*$')])
        ),
        phoneNumber: new FormControl(
          null,
          Validators.compose([
            Validators.required,
            Validators.pattern('[0-9]{3}[0-9]{3}[0-9]{4}'),
          ])
        ),
        alternatePhoneNumber: new FormControl(
          null,
          Validators.compose([Validators.pattern('[0-9]{3}[0-9]{3}[0-9]{4}')])
        ),
        address: new FormControl(null, Validators.compose([Validators.required, Validators.maxLength(255)])),
      });
      this.globalResource = GlobalResources.getResources().en;
    this.getState()
    let token = JSON.parse(localStorage.getItem('token')|| 'null')
    this.isValidationNotRequried = JSON.parse(localStorage.getItem('isValidationNotRequried')|| 'null')
    if(token!==null){
      this.disableEmail = true;
      this.getState()
      this.getCountries()    
      }else{
      this.homeDetails.isUserLoggedIn.subscribe((data:any)=>{
        if(localStorage.getItem('token')){
          this.getState()
          this.disableEmail = true;
          this.getParentDetails();
        }
        else{
          this.reset();
        }
      })
    }
    
      this.appinsightsService.logEvent('parent-details form viewed');
    }
    catch(error:any){
      this.appinsightsService.logException(error,2);
    }


  }


 otpValidator(){
  return Validators.compose([
    Validators.required,
    Validators.pattern('[0-9]'),
    Validators.maxLength(1),
    Validators.minLength(1)
  ]);
 } 
 cityId:any;
 async onSubmit(formdata: any) {
  
    try{
     
      localStorage.setItem('CountryID',this.addParentDetails.controls['Country'].value?.id)
      if((this.isValidationNotRequried && localStorage.getItem('token'))){
   
        const city = this.addParentDetails.controls['city'].value;
        this.cityFlag = this?.previousCentersArray.some((x: any) => x?.label?.toUpperCase() === city?.toUpperCase());
        this.cityId = this?.previousCentersArray.filter((x: any) => 
          {
            if(x?.label?.toUpperCase() === city?.toUpperCase()){
              return x?.id
            }
          }     
      ); 

      const dataToStore = {
        cityFlag: this?.cityFlag,
        cityId: this?.cityId[0]?.id
      };
    
      const jsonData = JSON.stringify(dataToStore);
      formdata?.Country?.id===1&&this?.cityFlag?localStorage.setItem("cityFlag",jsonData):localStorage.setItem("cityFlag","null");
      this.router.navigate(['registration/student-details']);
      }
      else if(!this.isValidationNotRequried && localStorage.getItem('token')){
        const city = this.addParentDetails.controls['city'].value;
        this.cityFlag = this.previousCentersArray.some((x: any) => x?.label?.toUpperCase() === city?.toUpperCase());
          this.cityId = this?.previousCentersArray.filter((x: any) => 
            {
              if((x?.label?.toUpperCase() === city?.toUpperCase())){
                return x?.id
              }
            }     
        ); 
        
      const dataToStore = {
        cityFlag: this.cityFlag,
        cityId: this.cityId[0]?.id
      };
    
        const jsonData = JSON.stringify(dataToStore);
        formdata?.Country?.id===1&&this.cityFlag?localStorage.setItem("cityFlag",jsonData):localStorage.setItem("cityFlag","false")
   
     
       if (this.addParentDetails.valid) {
         let userObj:parentDetails={
           "firstName": formdata.firstName,
           "lastName": formdata.lastName,
           "email": formdata.email,
           "alternateEmail": formdata.alternateEmail,
           "phone": formdata.phoneNumber,
           "alternatePhone": formdata.alternatePhoneNumber,
           "address": formdata.address,
           "countryId": formdata?.Country?.id?formdata?.Country?.id:this.addParentDetails.controls['Country'].value.id,
           "stateName": formdata.State?.stateName?formdata.State?.stateName:formdata.State ,
           "city": formdata.city,
           "pinCode": formdata.zipCode
         }
         if(this.addParentDetails.dirty){
           await this.validation.decodeLoggedInToken();
           await this.parentDetails.addNewUser(userObj,(this.loggedInUserData?.id?this.loggedInUserData?.id:null)).subscribe((data)=>{
            if(data.statusCode == 200){
              this.messageService.add({
                severity:'success', 
                summary: 'Success', 
                detail: 'Details saved successfully',
                sticky: true
              });
              this.addParentDetails.reset();
              setTimeout(()=>{
                this.router.navigate(['registration/student-details']);
              },1000)
            }
           if(data.statusCode == 201){
            localStorage.setItem("userData", JSON.stringify( data.result))
             this.messageService.add({
               severity:'success', 
               summary: 'Success', 
               detail: 'Details saved successfully',
               sticky: true
             });
             this.addParentDetails.reset();
             setTimeout(()=>{
               this.router.navigate(['registration/student-details']);
             },1000)
           }
         },(error:any)=>{
           this.appinsightsService.logException(error,1);
           this.appinsightsService.logEvent("Error: Getting error in upsertParent api", {  payload: JSON.stringify(userObj),error:JSON.stringify(error)})
           if(error.status == 401){
             this.sharedService.token_Expired.next(true);
             localStorage.clear()
            this.confirmationService.confirm({
              message: 'Apologies, but your session has timed out. To proceed, please log in again.',
              header: 'Confirmation',
              icon: 'pi pi-exclamation-triangle',
              key:'toastMsg',
              accept: () => {
               localStorage.clear()
               this.homeDetails.isUserLoggedIn.next(false);
               this.showSuccessMsgForUserVerify = false;
               this.reset();
              },
            })
          }
          else if(error.status == 404){
           this.messageService.add({
             severity:'warn',
             summary: 'Warning',
             detail: error.message
           });
          }
          else if(error.status == 500){
            this.messageService.add({
              severity:'warn',
              summary: 'Warning',
              detail: "Something went wrong at server side!"
            });
          }
          else{
            this.messageService.add({
              severity:'warn',
              summary: 'Warning',
              detail: error.message
            });
          }
        })
         }
         else{
           this.addParentDetails.reset();
           setTimeout(()=>{
             this.router.navigate(['registration/student-details']);
           },1000)
         }
       } else {
         for (const field in this.addParentDetails.controls) {
           this.addParentDetails.controls[field].markAsDirty();
         }
       }

      }

    }
    catch(error:any){
      this.appinsightsService.logException(error,2);
    }
  }
  changeDetails(id:any){
    const state = this.addParentDetails.controls['State']
    const zipCode = this.addParentDetails.controls['zipCode']
    const city = this.addParentDetails.controls['city']
    const address = this.addParentDetails.controls['address']  
    state.reset();
    zipCode.reset();
    city.reset();
    address.reset();
    this.storeStateApiArray = this?.stateApiArray?.filter((x:any)=>x.countryId==id)
    // this.stateApiArray = this.storeStateApiArray
  }
  verifyUser(){
    try{
      this.otpForm.reset();
      this.otpErrorMsg="";
      this.showOTPInvalidError = false;
      if(this.addParentDetails.controls['email'].valid && this.addParentDetails.controls['email'].value != null){
        this.loader= true;
        let userData ={
          email:this.addParentDetails.controls['email'].value
        }
        this.parentDetails.verifyParent(userData).subscribe((data)=>{
          if(data.statusCode == 200){
            this.loader = false;
            this.showOTPScreen = true;
          }
        },(error:any)=>{
          console.log(error)
          this.showOTPScreen = false;
          this.loader = false;
          this.appinsightsService.logException(error,1);
          this.appinsightsService.logEvent("Error: Getting error in verifyUser api", {  payload: JSON.stringify(userData),error:JSON.stringify(error)})
        })
      }
      else{
        this.addParentDetails.controls['email'].markAsDirty();
      }
    }
    catch(error:any){
      this.showOTPScreen = false;
      this.loader = false;
      this.appinsightsService.logException(error,2);
    }
  }

  otpController(event:any, next:any, prev:any, index:number) {
    try{
      if(event.target.value && next){
        next.focus();
        return;
      }
      else if(event.target.value && !next){
       document.getElementById('continueOtpVerify')?.focus()
       return;
      }
      else if(event.target.value==""){
        prev.focus();
        return;
      }
    }
    catch(error:any){
      console.log(error)
      this.appinsightsService.logException(error,2);
    }
  }
  onOTPSubmit(formdata:any){
    try{
      this.otpErrorMsg ="";
      this.otpError = true
      this.showOTPInvalidError = false;
      if(this.otpForm.valid){
        this.otpError = false;
        this.loader = true;
        let verifyOtp = {
          "email": this.addParentDetails.controls['email'].value,
          "otp": formdata.firstDigit+formdata.secondDigit+formdata.thirdDigit+formdata.fourthDigit
        }
        this.parentDetails.verifyOtp(verifyOtp).subscribe((data)=>{
          if(data.statusCode == 200){
            localStorage.setItem("email", JSON.stringify(this.addParentDetails.controls['email'].value))
            localStorage.setItem("token", JSON.stringify(data.result.token))
            this.isValidationNotRequried = data?.result?.isExistingUser
            
      localStorage.setItem("isValidationNotRequried", JSON.stringify(data?.result?.isExistingUser));

            this.loader= false;
            this.showOTPScreen = false;
            this.homeDetails.isUserLoggedIn.next(true);
            this.showSuccessMsgForUserVerify = true;
            this.disableEmail = true;
            this.getParentDetails()
          }
        },(error:any)=>{
          if(error.error.statusCode == 400){
            this.otpErrorMsg = error.error.responseException;
            this.showOTPInvalidError = true;
            this.otpForm.reset();
          }
          this.loader= false;
          this.appinsightsService.logException(error,1);
          this.appinsightsService.logEvent("Error: Getting error in otp submit api", {  payload: JSON.stringify(verifyOtp),error:JSON.stringify(error)})
        })
      }
    }
    catch(error:any){
      this.appinsightsService.logException(error,1);
      this.loader = false;
    }
  }

  async getParentDetails(){
    try{
      this.showSuccessMsgForUserVerify = true;
      this.editControl = false;
      this.userDetailsLoader = true;
      await this.validation.decodeLoggedInToken();
      await this.parentDetails.getParentDetails().subscribe((data)=>{
        if(data.statusCode == 200){
          this.userDetailsLoader  = false;
          this.loggedInUserData = data.result
          localStorage.setItem("userData", JSON.stringify(this.loggedInUserData))

          this.prepopulateParentDetails((this.loggedInUserData|| data.result));
        }
      },(error:any)=>{
        const email = this.addParentDetails.controls['email'];
        const emailid: any  = (localStorage.getItem('email'));
        let emailAddress =  emailid? JSON.parse(emailid): "";
        this.userDetailsLoader  = false;
        email.patchValue(emailAddress);

        if(error.status == 401){
          this.sharedService.token_Expired.next(true);
          localStorage.clear();
          this.confirmationService.confirm({
            message: 'Apologies, but your session has timed out. To proceed, please log in again.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key:'toastMsg',
            accept: () => {
             localStorage.clear()
             this.homeDetails.isUserLoggedIn.next(false);
             this.reset();
            },
          })
          this.showSuccessMsgForUserVerify = false;
          this.homeDetails.isUserLoggedIn.next(false);
          this.reset();
       }
       else if(error.status == 500){
         this.messageService.add({
           severity:'warn',
           summary: 'Warning',
           detail: "Something went wrong at server side!"
         });
       }
       else if(error.status == 404){
        // this.messageService.add({
        //   severity:'warn',
        //   summary: 'Warning',
        //   detail: error.message
        // });
       }
       else{
        this.messageService.add({
          severity:'warn',
          summary: 'Warning',
          detail: error.message
        });
       }
       this.appinsightsService.logException(error,1);
       this.appinsightsService.logEvent("Error: Getting error in get parent details api", { error:JSON.stringify(error)})
     })
    }
    catch(error:any){
      this.userDetailsLoader  = false;
      this.appinsightsService.logException(error);
    }
  }

  prepopulateParentDetails(userData:any){
    try{
      this.disableEmail = true
      const email = this.addParentDetails.controls['email'];
      const alternateEmail = this.addParentDetails.controls['alternateEmail'];
      const firstName = this.addParentDetails.controls['firstName'];
      const lastName = this.addParentDetails.controls['lastName'];
      const phoneNumber = this.addParentDetails.controls['phoneNumber'];
      const alternatePhoneNumber = this.addParentDetails.controls['alternatePhoneNumber'];
      const address = this.addParentDetails.controls['address'];
      const country = this.addParentDetails.controls['Country'];
      const state = this.addParentDetails.controls['State'];
      const city = this.addParentDetails.controls['city'];
      const zipCode = this.addParentDetails.controls['zipCode'];
      let SelectedCountry = this.countryApiArray?.filter((x:any)=>(x.id == userData?.countryId))
      if(userData.stateId){
        this.storeStateApiArray  =  this.stateApiArray.filter((x:any)=>(x.countryId ==userData.countryId))
        let selected_state = this.stateApiArray.filter((x:any)=>(x.id==userData?.stateId))
        state.patchValue(selected_state[0])
      }else{
        this.storeStateApiArray = 0
        state.patchValue(userData.state)
      }
     
      country.patchValue(SelectedCountry[0])
      zipCode.patchValue(userData.pinCode)
      city.patchValue(userData.city)
     
      // state.patchValue(this.storeStateApiArray[0]?this.storeStateApiArray[0]:userData.state)
      const emailid: any  = (localStorage.getItem('email'));
      let emailAddress =  emailid? JSON.parse(emailid): "";
      email.patchValue(emailAddress);
      alternateEmail.patchValue(userData.alternateEmail);
      firstName.patchValue(userData.firstName);
      lastName.patchValue(userData.lastName);
      phoneNumber.patchValue(userData.phone);
      alternatePhoneNumber.patchValue(userData.alternatePhone);
      address.patchValue(userData.address);
  
    }
    catch(error:any){
      this.appinsightsService.logException(error,2);
    }
  }

  validateAlternateEmail(){
    if(this.addParentDetails['controls']['email'].value == this.addParentDetails['controls']['alternateEmail'].value && this.addParentDetails['controls']['alternateEmail'].dirty){
      this.addParentDetails['controls']['alternateEmail'].setErrors({'incorrect': true});
      this.validateEmailError = "Email and alternate Email cannot be same"
    }
    else{
      this.validateEmailError = ""
    }
  }

  async getState(){
    try{
      this.loader = true;
      let token = JSON.parse(localStorage.getItem('token')|| 'null')
  await this.parentDetails.getStates().subscribe((data)=>{
        this.loader = false;
       this.stateApiArray=data.result;     
       this.storeStateApiArray = this.stateApiArray;

      },(error:any)=>{
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: Getting error in get all courses Country list api", {error:JSON.stringify(error)})
      })
    }
    catch(error:any){
      this.appinsightsService.logException(error,2)
    }
  }
 async getCountries(){
    try{
      this.loader = true;
      let token = JSON.parse(localStorage.getItem('token')|| 'null')
  await this.courseDetails.getCountry().subscribe((data)=>{
        this.loader = false;
       this.countryApiArray=data.result;     
       if(token!==null){
     this.disableEmail = true;
     this.getParentDetails()
        }

      },(error:any)=>{
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: Getting error in get all courses Country list api", {error:JSON.stringify(error)})
      })
    }
    catch(error:any){
      this.appinsightsService.logException(error,2)
    }
  }

  validatePhoneNumber(){
    if(this.addParentDetails.controls['phoneNumber'].value == this.addParentDetails.controls['alternatePhoneNumber'].value && this.addParentDetails.controls['alternatePhoneNumber'].dirty)
    {
      this.addParentDetails['controls']['alternatePhoneNumber'].setErrors({'incorrect': true});
       this.validatePhoneNumberError ="Phone number and alternate phone number cannot be same"
    }
    else{
      this.validatePhoneNumberError = ""
    }
  }
  reset(){
    this.disableEmail= false;
    this.editControl = true;
    this.loader = false;
    this.userDetailsLoader  = false;
    this.showSuccessMsgForUserVerify = false;
    this.addParentDetails.reset();
  }
}
