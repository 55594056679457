import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EnrollmentsService } from 'src/app/services/enrollments.service';
import { LoggingService } from 'src/app/services/logging.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-enrollment-detail-view',
  templateUrl: './enrollment-detail-view.component.html',
  styleUrls: ['./enrollment-detail-view.component.css'],
})
export class EnrollmentDetailViewComponent implements OnInit {
  enrollmentId: number = 0;
  enrollmentDetails: any;
  loader:boolean = false;
  constructor(
    private route: ActivatedRoute,
    private appinsightsService: LoggingService,
    private enrollmentService: EnrollmentsService,
    private router: Router,
    private sharedService: SharedService,
  ) {}

  ngOnInit(): void {
    try {
      this.route.params.forEach((urlParams: any) => {
        this.enrollmentId = urlParams['enrollmentId'];
      });
      if (this.enrollmentId) {
        this.getEnrollmentData(this.enrollmentId);
      }
    } catch (error: any) {
      this.appinsightsService.logException(error, 2);
    }
  }

  getEnrollmentData(enrollmentId: number) {
    try {
      this.loader = true;
      this.enrollmentService.getEnrollmentDetails(enrollmentId).subscribe(
        (data) => {
          this.loader = false;
          this.enrollmentDetails = data.result;
        },
        (error: any) => {
          if (error.status == 401) {
            this.sharedService.token_Expired.next(true);
          }
          this.loader = false;
          this.appinsightsService.logException(error);
          this.appinsightsService.logEvent('Error: Getting Enrollment details api', {
            error: JSON.stringify(error),
          });
        }
      );
    } catch (error: any) {
      this.appinsightsService.logException(error, 2);
    }
  }
  navigateBack() {
    this.router.navigate(['Admin/enrollments']);
  }
}
