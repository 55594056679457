<div class="container-fluid">
    <p-toast position="top-center"></p-toast>
    <div class="row">
        <div class="col-12 text-center  py-3">
            <h4 class="fw-semibold m-0">User Management</h4>
        </div>
    </div>
    <div class="row pt-4" *ngIf="loader">
        <div class="col text-center">
            <p-progressSpinner *ngIf="loader" class="custom-spinner" strokeWidth="3"
                animationDuration=".5s"></p-progressSpinner>
        </div>
    </div>
    <div class="row" *ngIf="!loader">
        <div class="col-12 p-0">
            <p-table #enrollmentsData [tableStyle]="{ 'min-width': '50rem' }" [value]="enrollments"
                class="enrollments_table" sortMode="single" [responsive]="true" [rows]="5" [paginator]="true"
                [rowsPerPageOptions]="getPaginationOptions()"
                [globalFilterFields]="['enrollmentId', 'parentName', 'studentName','regDate','centerName','courseType','courseName','studentAge','parentId',]">
                <ng-template pTemplate="caption">
                    <div class="flex row d-flex align-items-center">
                        <div class="col-12 col-md-6 p-input-icon-left ml-auto">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <p-dropdown name="academicYr" id="academicYr" placeholder="Select a Academic Year" [options]="academicYearsList" optionLabel="name" 
                                    [(ngModel)]="selectedAcademicYear" class="d-block dropdown" (onChange)="getEnrollments(null,selectedAcademicYear.id,null)"></p-dropdown>
                                </div>
                                <div class="col-12 col-md-6">
                                    <input pInputText type="text" class="form-control search_field"
                                    (input)="filterGlobal($event, 'contains')" placeholder=" Search " />
                                </div>
                            </div>
                        </div>
                        <div
                            class="col-12 col-md-4 offset-md-2 p-input-icon-left ml-auto d-flex align-items-center justify-content-end">
                            <button pButton pRipple type="button" label="Edit" [disabled]="disableCourseChange"
                                class="btn-blue register_btn m-1" (click)="openChangeCourseDialog()"></button>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th class="bg-white"></th>
                        <!-- <th class="bg-white">Enrollment ID</th> -->
                        <th class="bg-white">Parent ID</th>
                        <th class="bg-white">Parent Name</th>
                        <th class="bg-white">Student Name</th>
                        <th class="bg-white">Age</th>           
                        <!-- <th class="bg-white">Gender</th> -->
                        <th class="bg-white">Reg Date</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-enrollment let-rowIndex="rowIndex">
                    <tr *ngIf="enrollments.length > 0">
                        <td> <p-radioButton class="pr-2" required name="enrollment" [inputId]="enrollment.enrollmentId"
                                name="enrollment" [value]="enrollment" [(ngModel)]="selectedEnrollment"
                                [value]="enrollment" (click)="selectEnrollment(enrollment)"></p-radioButton></td>
                        <!-- <td (click)="navigateToDetailView(enrollment)" role="button"
                            class="blue-berry text-decoration-underline">{{ enrollment.enrollmentId }}</td> -->
                        <td>{{ enrollment.parentId }}</td>
                        <td>{{ enrollment.parentName }}</td>
                        <td>{{ enrollment.studentName }}</td>
                        <td>{{ enrollment.studentAge }}</td>
                        <!-- integrate the age here <td>{{ enrollment.courseName }}</td> -->
                        <td>{{ enrollment.regDate | date:'mediumDate'}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">No User Enrollments found.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <p-dialog [(visible)]="showChangeCourseDialog" [style]="{width: '80vw'}" [modal]=true>
        <div class="row">
            <div class="col-12">
                <h4 class="fw-bold text-dark mb-4">Edit User </h4>
                <h5 class="fw-bold text-dark mb-4">Academic Year - {{selectedAcademicYear?.name}} </h5>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h5 class="fw-bold text-dark mb-4">Parent Details </h5>
            </div>
        </div>
 <form [formGroup]="editParentDetails" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-12 col-md-4 pb-md-3  pb-2">
                <label class="dark-ask">Parent ID</label>
                <p class="text-dark">{{selectedEnrollmentDetails?.parentId}}</p>
            </div>
            
            <div class="col-12 col-md-4 pb-md-3  pb-2">
                <label class="dark-ask">Registration Date</label>
                <p class="text-dark">{{selectedEnrollmentDetails?.regDate | date:'mediumDate'}}</p>
            </div>
            <div class="col-12 col-md-4 pb-md-3  pb-2">
                <label class="dark-ask">Email <span class="text-danger">
                    *</span></label>
                <input type="text" id="email" name="email"  class="clsControl form-control"   placeholder="Email" formControlName="email">
                <div class="text-danger">
                    <span class="text-capitalize">{{ validation.validateFormControl('email','Email',editParentDetails) }}</span>
                </div>
            </div>
            <div class="col-12 col-md-4  pb-md-3 pb-2">
                <label class="dark-ask">Alternative Email</label>
                <input type="text" id="Alteremail" name="Alteremail"  class="clsControl form-control"   placeholder="Alternative Email" formControlName="Alteremail">
                <!-- <div class="text-danger">
                    <span class="text-capitalize">{{ validation.validateFormControl('Alteremail','Email',editParentDetails) }}</span>
                </div> -->
            </div>
            <div class="col-12 col-md-4 pb-md-3  pb-2">
                <label class="dark-ask">First Name<span class="text-danger">
                    *</span></label>
                <input type="text" id="parent" name="parent"  class="clsControl form-control"   placeholder="First Name" formControlName="firstname">
                <div class="text-danger">
                    <span class="text-capitalize">{{ validation.validateFormControl('firstname','First Name',editParentDetails) }}</span>
                </div>
            </div>
            <div class="col-12 col-md-4 pb-md-3  pb-2">
                <label class="dark-ask">Last Name<span class="text-danger">
                    *</span></label>
                <input type="text" id="parent" name="parent"  class="clsControl form-control"   placeholder="First Name" formControlName="Lastname">
                <div class="text-danger">
                    <span class="text-capitalize">{{ validation.validateFormControl('Lastname','last Name',editParentDetails) }}</span>
                </div>
            </div>
            <div class="col-12 col-md-4 pb-md-3  pb-2">
                <label class="dark-ask">Phone<span class="text-danger">
                    *</span></label>
                <input type="text" id="phone" name="phone"  class="clsControl form-control"   placeholder="Phone Number" formControlName="phone">
                <div class="text-danger">
                    <span class="text-capitalize">{{ validation.validateFormControl('phone','Phone Number',editParentDetails) }}</span>
                </div>
            </div>
            <div class="col-12 col-md-4 pb-md-3  pb-2">
                <label class="dark-ask">Alternative Phone</label>
                <input type="text" id="Alterphone" name="Alterphone"  class="clsControl form-control"   placeholder="Alternative Phone Number" formControlName="phone">
                <div class="text-danger">
                    <span class="text-capitalize">{{ validation.validateFormControl('phone','Phone Number',editParentDetails) }}</span>
                </div>
            </div>                
            <div class="col-12 col-md-4 pb-md-3  pb-2">
                <label for="Country" class="dark-ask">Country<span class="text-danger"> *</span></label>
                <p-dropdown name="Country" id="Country"   
                            formControlName="Country" class="dropdown d-block studentDetail_dropdown" placeholder="Select Country" [options]="countryApiArray" optionLabel="countryName"></p-dropdown>
                <div class="text-danger" >
                    <span class="text-capitalize">{{ validation.validateFormControl('Country','Country',editParentDetails) }}</span>
                </div>
            </div>
            <div class="col-12 col-md-4 pb-md-3  pb-2">
                <label for="State" class="dark-ask">State<span class="text-danger">*</span></label>
                <input pInputText name="State"  id="State" type="text"
                  class="clsControl form-control" formControlName="State" placeholder="State" />
                <div class="text-danger" >
                    <span class="text-capitalize">{{ validation.validateFormControl('State','State',editParentDetails) }}</span>
                </div>
            </div>
            <div class="col-12 col-md-4 pb-md-3  pb-2">
                <label for="City" class="dark-ask">Town/City<span class="text-danger">*</span></label>
                <input pInputText name="City"  id="City" type="text"  
                   class="clsControl form-control" formControlName="city" placeholder="Town/City" />
                <div class="text-danger" >
                    <span class="text-capitalize">{{ validation.validateFormControl('city','City',editParentDetails) }}</span>
                </div>
            </div>
            <div class="col-12 col-md-4 pb-md-3  pb-2">
                <label for="zipCode" class="dark-ask">Zip Code<span class="text-danger">*</span></label>
                <input pInputText name="zipCode"  id="zipCode" type="text"  
                 class="clsControl form-control" formControlName="zipCode" placeholder="Zip Code" />
                <div class="text-danger" >
                    <span class="text-capitalize">{{ validation.validateFormControl('zipCode','Zip Code',editParentDetails) }}</span>
                </div>
            </div>


            <div class="col-12  pb-md-1  pb-2">
                <label class="dark-ask">Address<span class="text-danger">
                    *</span></label>
                    <textarea type="text" id="Address" name="Address"  class="clsControl form-control "   formControlName="Address" rows="8" cols="80"></textarea>
                <!-- <input > -->
                <div class="text-danger pt-2 pb-2">
                    <span class="text-capitalize">{{ validation.validateFormControl('Address','Address',editParentDetails) }}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <h5 class="fw-bold text-dark mb-4">Student Details </h5>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-4 pb-md-3  pb-2">
                    <label class="dark-ask">First Name<span class="text-danger">
                        *</span></label>
                    <input type="text" id="childFirstName" name="childFirstName"  class="clsControl form-control"   placeholder="First Name" formControlName="childfirstname">
                    <div class="text-danger">
                        <span class="text-capitalize">{{ validation.validateFormControl('childfirstname','First Name',editParentDetails) }}</span>
                    </div>
                </div>
                <div class="col-12 col-md-4 pb-md-3  pb-2">
                    <label class="dark-ask">Last Name<span class="text-danger">
                        *</span></label>
                    <input type="text" id="childLastName" name="childLastName"  class="clsControl form-control"   placeholder="Last Name" formControlName="childLastname">
                    <div class="text-danger">
                        <span class="text-capitalize">{{ validation.validateFormControl('childLastname','Last Name',editParentDetails) }}</span>
                    </div>
                </div>
                <div class="col-12 col-md-4 pb-md-3  pb-2">
                    <label class="dark-ask">Telugu Name<span class="text-danger">
                        *</span></label>
                    <input type="text" id="childteluguName" name="childteluguName"  class="clsControl form-control"   placeholder="Last Name" formControlName="childteluguname">
                    <div class="text-danger">
                        <span class="text-capitalize">{{ validation.validateFormControl('childteluguname','Telugu Name',editParentDetails) }}</span>
                    </div>
                </div>
                <div class="col-12 col-md-4 pb-md-3  pb-2">
                    <label class="dark-ask">Age<span class="text-danger">
                        *</span></label>
                    <input type="text" id="Age" name="Age"  class="clsControl form-control"   placeholder="Age" formControlName="Age">
                    <div class="text-danger">
                        <span class="text-capitalize">{{ validation.validateFormControl('Age','Age',editParentDetails) }}</span>
                    </div>
                </div>
                <div class="col-12 col-md-4 pb-md-3  pb-2">
                    <label for="gender" class="dark-ask">Gender<span class="text-danger"> *</span></label>
                    <p-dropdown name="gender" id="gender"
                                formControlName="gender" class="dropdown d-block studentDetail_dropdown" placeholder="Select Gender" [options]="genderArray" optionLabel="label"></p-dropdown>
                    <div class="text-danger" >
                        <span class="text-capitalize">{{ validation.validateFormControl('gender','Gender',editParentDetails) }}</span>
                    </div>
                </div>
                <div class="col-12 col-md-4 pb-md-3  pb-2">
                    <label for="gender" class="dark-ask">Relation<span class="text-danger"> *</span></label>
                    <p-dropdown name="relation" id="relation"
                                formControlName="relation" class="dropdown d-block studentDetail_dropdown" placeholder="Select Relation" [options]="relationShipArray" optionLabel="label"></p-dropdown>
                    <div class="text-danger" >
                        <span class="text-capitalize">{{ validation.validateFormControl('relation','Relation',editParentDetails) }}</span>
                    </div>
                </div>
                <div class="col-12 col-md-4 pb-md-3  pb-2" *ngIf="this.selectedEnrollmentDetails?.courseType =='In-Person'">
                    <label for="gender" class="dark-ask">Previous Attended Center<span class="text-danger"> *</span></label>
                    <p-dropdown name="center" id="center"
                                formControlName="center" class="dropdown d-block studentDetail_dropdown" placeholder="Select Previous Center" [options]="previousCentersArray" optionLabel="label" ></p-dropdown>
                    <div class="text-danger" >
                        <span class="text-capitalize">{{ validation.validateFormControl('center','Previous Attended Center',editParentDetails) }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 d-flex align-items-center justify-content-start">
                <button pButton pRipple type="submit" class="btn-blue d-flex align-items-center justify-content-center"
                [ngClass]="{'px-5 text-center text-white': loader}">
                <span *ngIf="!loader" class="text-white">Save</span>
                <p-progressSpinner *ngIf="loader" class="custom-spinner" strokeWidth="3"
                    animationDuration=".5s"></p-progressSpinner>
                </button>
                <a (click)="closeScreen()"><span
                    class="text-danger d-inline-block p-3 cursor">Cancel</span></a>
            </div>
        </div>
     </form>
   
    </p-dialog>

   
</div>
<p-confirmDialog key="confirmationMsg" #rcd [style]="{width: '50vw'}">
    <ng-template pTemplate="header">
        <h3 class="text-dark fw-semibold mb-4">Refund</h3>
    </ng-template>
    <ng-template pTemplate="footer">
        <button type="button" pButton   class="btn-blue " label="OK" (click)="rcd.accept()"></button>
        <a (click)="rcd.reject()"><span
            class="text-danger d-inline-block p-3">Cancel</span></a>
    </ng-template>
  </p-confirmDialog>