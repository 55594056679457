import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { CourseDetailsService } from 'src/app/services/course-details.service';
import { EnrollmentsService } from 'src/app/services/enrollments.service';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { HomeService } from 'src/app/services/home.service';
import { LoggingService } from 'src/app/services/logging.service';
import { LoginService } from 'src/app/services/login.service';
import { ParentDetailsService } from 'src/app/services/parent-details.service';
import { SharedService } from 'src/app/services/shared.service';
import { ValidationService } from 'src/app/services/validation.service';
import { environment } from 'src/environments/environment';
const baseUrl = environment.apiEndpoint;
@Component({
  selector: 'app-enrollments',
  templateUrl: './enrollments.component.html',
  styleUrls: ['./enrollments.component.css'],
})
export class EnrollmentsComponent implements OnInit {
  addCourseDetails: FormGroup;
  selectedEnrollment: any;
  selectedEnrollmentDetails: any;
  disableRefund: boolean = true;
  disableCourseChange: boolean = true;
  paginationValues: any;
  loggedInUser: any;
  loader: boolean = false;
  selectedAcademicYear: any;
  academicYearsList: any;
  showChangeCourseDialog: boolean = false;
  @ViewChild('enrollmentsData') enrollmentsData: Table | undefined;
  courseTypeArray = [
    { id: '1', label: 'Online' },
    { id: '2', label: 'In-Person' },
  ];
  selectedCourseType: any;
  enrollments = [];
  showLocations: boolean = false;
  completeCourseDetails: any;
  filteredCompleteData: any;
  locationsArray: any;
  categoryList: any;
  selectedLocation: any;
  coursesList: any;
  timings: any;
  showRefundCourseDialog: boolean = false;
  refundDetails:any;
  storeFilterList: any;
  countryApiArray: any;
  preFerredLocation: boolean=false;
  constructor(
    private fb: FormBuilder,
    private appinsightsService: LoggingService,
    private enrollmentService: EnrollmentsService,
    private parentDetails: ParentDetailsService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private courseDetails: CourseDetailsService,
    private sharedService: SharedService,
    public validation: ValidationService,
    private errorHandling: ErrorHandlingService
  ) {
    this.addCourseDetails = this.fb.group({
      courseType: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),  Country: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      category: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      coursename: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      timings: new FormControl(null, Validators.compose([Validators.required])),
      locationName: new FormControl(null),
    });
  }

  ngOnInit(): void {
    try {
      this.getLogginUsedData();
      this.getAcademicYears();
      this.getCountries()
   
    } catch (error: any) {
      this.appinsightsService.logException(error, 1);
    }
  }
  public  getPaginationOptions(): any {
    return [5, 10, 15, 20];
  }
  getAcademicYears() {
    try {
      this.courseDetails.getAcademicYears().subscribe((data: any) => {
        this.academicYearsList = data.result;
        this.academicYearsList.forEach((item: any) => {
          if (item.isRegistrationsOpen == true) {
            this.selectedAcademicYear = item;
          }
        });
        this.getCoursesList( this.selectedAcademicYear.id);
        this.getEnrollments(null, this.selectedAcademicYear.id, null);
      });
    } catch (error: any) {
      this.appinsightsService.logException(error);
    }
  }
  getLogginUsedData() {
    try {
      this.loader = true;
      this.parentDetails.getParentDetails().subscribe(
        (data) => {
          this.loader = false;
          if (data.statusCode == 200) {
            this.loggedInUser = data.result;
          }
        },
        (error: any) => {
          this.loader = false;
          if (error.status == 401) {
            this.sharedService.token_Expired.next(true);
            localStorage.clear();
          }
          this.appinsightsService.logException(error);
          this.appinsightsService.logEvent('Error: Getting user details api', {
            error: JSON.stringify(error),
          });
        }
      );
    } catch (error: any) {
      this.loader = false;
      this.appinsightsService.logException(error, 1);
    }
  }
  getEnrollments(enrollmentId: any, accademicYrId: any, ParentId: any) {
    try {
      this.loader = true;
      const enrollmentsData = {
        enrollmentId: enrollmentId,
        academicYearId: accademicYrId,
        parentId: ParentId,
      };
      this.enrollmentService.getEnrollments(enrollmentsData).subscribe(
        (data) => {
          this.loader = false;
          if (enrollmentId == null) {
         
            this.enrollments = data?.result;
            if (  this.enrollments?.length > 20) {
              let length = Math?.ceil( this.enrollments.length / 10) * 10;
              this.paginationValues[this?.getPaginationOptions()?.length] = length;
            }
          } else {
            this.selectedEnrollmentDetails = data?.result[0];
            this.prepopulateCourseDetails(this.selectedEnrollmentDetails);
          }
        },
        (error: any) => {
          this.loader = false;
          if (error.status == 401) {
            this.sharedService.token_Expired.next(true);
            localStorage.clear();
          }
          this.appinsightsService.logException(error);
          this.appinsightsService.logEvent('Error: Getting enrollments api', {
            error: JSON.stringify(error),
          });
        }
      );
    } catch (error: any) {
      this.loader = false;
      this.appinsightsService.logException(error, 2);
    }
  }
  selectEnrollment(enrollment: any) {
    try {
      this.disableCourseChange = false;
      this.disableRefund = false;
      // if (enrollment.refundable) {
      //   this.disableRefund = false;
      // } else {
      //   this.disableRefund = true;
      // }
    } catch (error: any) {
      this.appinsightsService.logException(error, 2);
    }
  }
  filterGlobal($event: any, stringVal: any) {
    this.enrollmentsData!.filterGlobal(
      ($event.target as HTMLInputElement).value,
      stringVal
    );
  }
  openChangeCourseDialog() {
    this.addCourseDetails.reset();
    this.showChangeCourseDialog = true;
    this.getEnrollments(
      this.selectedEnrollment.enrollmentId,
      this.selectedAcademicYear.id,
      this.selectedEnrollment.parentId
    );
  }
  openRefundDialog() {
    this.showChangeCourseDialog = false;
    this.showRefundCourseDialog = true;
    this.getEnrollmentRefundDetails(this.selectedEnrollment.enrollmentId);
  }
  closeScreen() {
    this.showChangeCourseDialog = false;
    this.showRefundCourseDialog = false;
  }
  onSubmit() {
    try {
      if (this.addCourseDetails.valid) {
        const courseUpdateObj = {
          courseTypeId: this.addCourseDetails.controls['category'].value?.id,
          CountryId:this.addCourseDetails.controls['Country'].value?.id,
          courseCategoryId:
            this.addCourseDetails.controls['courseType'].value?.id,
          courseId: this.addCourseDetails.controls['coursename'].value?.id,
          SlotId: this.addCourseDetails.controls['timings'].value?.slotId,
          branchSlotId: this.addCourseDetails.controls['locationName'].value?.branchId ?this.addCourseDetails.controls['locationName'].value?.branchId : null ,
          academicYearId: this.selectedAcademicYear?.id,
        };
        this.enrollmentService
          .updateEnrollment(
            courseUpdateObj,
            this.selectedEnrollment.courseId,
            this.selectedEnrollment.studentId,
            this.selectedEnrollment.enrollmentId
          )
          .subscribe((data) => {
            if(data.statusCode == 200){
              this.messageService.add({
                severity:'success',
                summary: 'Success',
                detail: 'Course Updated successfully'
              });
            }
            this.showChangeCourseDialog = false;
            this.showRefundCourseDialog = false;
            this.getEnrollments(null, this.selectedAcademicYear.id, null);
          },
          (error: any) => {
            this.appinsightsService.logException(error, 1);
            this.appinsightsService.logEvent(
              'Error: Getting error in change course api',
              { error: JSON.stringify(error) }
            );
            if (error.status == 401) {
              this.sharedService.token_Expired.next(true);
              localStorage.clear();
            } else {
              this.messageService.add({
                severity:'warn',
                summary: 'Warning',
                detail: error.error.title
              });
              this.appinsightsService.logException(error, 2);
            }
          });
      } else {
        for (const field in this.addCourseDetails.controls) {
          this.addCourseDetails.controls[field].markAsDirty();
        }
      }
    } catch (error: any) {
      this.appinsightsService.logException(error, 2);
    }
  }
 async downloadExcel(){
try{

  let url = `${baseUrl}/api/Enrollments/downloadexcel?academicYearId=${this.selectedAcademicYear.id}`
  const newWindow =  window.open(url, '_blank');
  newWindow?.focus();
}
catch(err:any){
  throw new Error("Error fetching download Excel Data");
}
    
  }
  getEnrollmentRefundDetails(enrollmentId:any){
    try{
      this.loader = true;
      this.enrollmentService.getEnrollmentDetails(enrollmentId).subscribe(
        (data) => {
          this.loader = false;
          this.refundDetails = data.result;
        },
        (error: any) => {
          this.loader = false;
          if (error.status == 401) {
            this.sharedService.token_Expired.next(true);
            localStorage.clear();
          }
          this.appinsightsService.logException(error);
          this.appinsightsService.logEvent('Error: Getting Enrollment details api', {
            error: JSON.stringify(error),
          });
        }
      );
    }
    catch(error:any){

    }
  }
  navigateToDetailView(enrollment: any) {
    this.router.navigate([`/enrollments/${enrollment.enrollmentId}`]);
  }
  // onCourseTypeSelection() {
  //   const locationName = this.addCourseDetails.controls['locationName'];
  //   if (this.selectedCourseType.id == 1) {
  //     this.showLocations = false;
  //      locationName.reset();
  //     locationName.clearValidators();
  //     this.filteredCompleteData = this.completeCourseDetails.onlineCourses;
  //     this.filterCategoryBasedOnType(this.filteredCompleteData);
  //   } else {
  //     this.showLocations = true;
  //      locationName.reset();
  //     locationName.setValidators(Validators.required);
  //     this.filteredCompleteData = this.completeCourseDetails.offlineCourses;
  //     this.filterLocationsBasedOnType(this.filteredCompleteData);
  //   }
  //   locationName.updateValueAndValidity();
  // }
  async getCoursesList(accademicYrId:any) {
    try {
      this.loader = true;
      await this.validation.decodeLoggedInToken();
      await this.courseDetails.getCourseDetails(accademicYrId,null).subscribe(
        (data) => {
          this.loader = false;
          if (data.statusCode == 200) {
            this.completeCourseDetails = data.result;
    
          }
        },
        (error: any) => {
          this.loader = false;
          this.appinsightsService.logException(error, 1);
          this.appinsightsService.logEvent(
            'Error: Getting error in get course list api',
            { error: JSON.stringify(error) }
          );
          if (error.status == 401) {
            this.sharedService.token_Expired.next(true);
            localStorage.clear();
          } else {
            this.appinsightsService.logException(error, 2);
          }
        }
      );
    } catch (error: any) {
      this.appinsightsService.logException(error, 2);
    }
  }
  filterLocationsBasedOnType(courseTypeData: any) {
    try {
      // this.duplicateEntry = false;
      this.locationsArray = [];
      courseTypeData.map((x: any) => {
        x.branchSlots.map((y: any) => {
          this.locationsArray.push(y);
        });
      });
      const locationsFiltered = Array.from(
        this.locationsArray
          .reduce((m: any, t: any) => m.set(t.branchName, t), new Map())
          .values()
      );
      this.locationsArray = locationsFiltered;
    } catch (error: any) {
      this.appinsightsService.logException(error, 2);
    }
  }

  filterCategoryBasedOnType(courseTypeData: any) {
    try {
      // this.duplicateEntry = false;
      this.categoryList = [];
      courseTypeData.map((x: any) => {
        this.categoryList.push({ label: x.categoryName, id: x.categoryId });
      });
      const catogoriesFiltered = Array.from(
        this.categoryList
          .reduce((m: any, t: any) => m.set(t.label, t), new Map())
          .values()
      );
      this.categoryList = catogoriesFiltered;
    } catch (error: any) {
      this.appinsightsService.logException(error, 2);
    }
  }
  reset(){
    this.addCourseDetails.controls['category'].reset();
     this.addCourseDetails.controls['coursename'].reset();
     this.addCourseDetails.controls['timings'].reset();
    }
  onLocationSelection() {
    try {
      if(this.preFerredLocation){
        this.reset()
      }
      this.preFerredLocation = true;
      
      let filteredcoursesBasedOnLocation: any = [];
      for (let i of this.filteredCompleteData) {
        if (i?.branchSlots[0]?.branchId == this.selectedLocation.branchId) {
          filteredcoursesBasedOnLocation.push(i);
        }
      }
      // this.filteredCompleteData = filteredcoursesBasedOnLocation
      this.filterCategoryBasedOnType(filteredcoursesBasedOnLocation);
    } catch (error: any) {
      this.appinsightsService.logException(error, 2);
    }
  }
  onCategorySelection(event: any) {
    try {
      // this.duplicateEntry = false;
      this.coursesList = [];
      this.filteredCompleteData.map((x: any) => {
        if (x.categoryId == event?.value?.id || x.categoryId == event?.id)
          if (
            x.branchSlots &&
            x.branchSlots[0].branchId == this.selectedLocation.branchId
          ) {
            this.coursesList.push({ label: x.courseName, id: x.courseId });
          } else if (!x.branchSlots) {
            this.coursesList.push({ label: x.courseName, id: x.courseId });
          }
      });
      const coursesFiltered = Array.from(
        this.coursesList
          .reduce((m: any, t: any) => m.set(t.label, t), new Map())
          .values()
      );
      this.coursesList = coursesFiltered;
    } catch (error: any) {
      this.appinsightsService.logException(error, 2);
    }
  }
  onCourseSelection(event:any){
    try{
      this.timings =[];
      this.filteredCompleteData.map((x:any)=>{
        if((x.courseId == (event?.value?.id || event.id)) && this.addCourseDetails['controls']['category'].value.id ==  x.categoryId){
          if(x.branchSlots && x.branchSlots[0].branchId == this.selectedLocation.branchId ){
            this.timings = x.branchSlots[0]?.slots
          }
          else if(!x.branchSlots){
            this.timings = x.courseSlots;
          }
        }
      })
     
    }
    catch(error:any){
      this.appinsightsService.logException(error,2);
    }
  }
  
  // onCourseSelection(event: any) {
  //   try {
  //     // this.duplicateEntry = false;
  //     this.timings = [];
  //     const amount = this.addCourseDetails['controls']['amount'];
  //     const groupId = this.addCourseDetails['controls']['groupId'];
  //     const courseCategoryName =
  //       this.addCourseDetails['controls']['courseCategoryName'];
  //     this.filteredCompleteData.map((x: any) => {
  //       if (
  //         (x.courseId == event?.value?.id || x.courseId == event?.id) &&
  //         this.addCourseDetails['controls']['category'].value.id == x.categoryId
  //       ) {
  //         if (
  //           x.branchSlots &&
  //           x.branchSlots[0].branchId == this.selectedLocation.branchId
  //         ) {
  //           this.timings = x.branchSlots[0]?.slots;
  //         } else if (!x.branchSlots) {
  //           this.timings = x.courseSlots;
  //         }
  //         amount.patchValue(x.amount);
  //         groupId.patchValue(x.groupId);
  //         courseCategoryName.patchValue(x.courseCategory);
  //       }
  //     });
  //   } catch (error: any) {
  //     this.appinsightsService.logException(error, 2);
  //   }
  // }
  prepopulateCourseDetails(courseDetails: any) {
    const country = this.addCourseDetails.controls['Country'];
    let SelectedCountry = this.countryApiArray?.filter((x:any)=>(x.id == this.selectedEnrollmentDetails.countryId))
    country.patchValue(SelectedCountry[0])

    const locations = this.addCourseDetails.controls['locationName'];
    if(this.selectedEnrollmentDetails.
      courseTypeId == 1){
      this.showLocations = false;
      locations.clearValidators();
      this.storeFilterList = this.completeCourseDetails?.
      onlineCourses.filter((x:any)=>x.countryId == this.selectedEnrollmentDetails.countryId)
      this.filteredCompleteData = this.storeFilterList;
      this.filterCategoryBasedOnType(this.filteredCompleteData)
     
    }
    else{
      this.showLocations = true;
      locations.setValidators(Validators.required)
      // this.reset();
      this.storeFilterList  = this.completeCourseDetails?.offlineCourses.filter((x:any)=>x.countryId == this.selectedEnrollmentDetails.countryId)
      this.filteredCompleteData =  this.storeFilterList;
      this.filterLocationsBasedOnType(this.filteredCompleteData)
    }
    locations.updateValueAndValidity();


    const courseType = this.addCourseDetails.controls['courseType'];
   
    const category = this.addCourseDetails.controls['category'];
    const course = this.addCourseDetails.controls['coursename'];
    const timings = this.addCourseDetails.controls['timings'];
    this.selectedCourseType = this.courseTypeArray.filter(
      (item) => item.label == this.selectedEnrollmentDetails.courseType
    );

 
    this.selectedCourseType = this.selectedCourseType[0];
    courseType.patchValue(this.selectedCourseType);
    
    // this.onCourseTypeSelection();
    const locationSelected = this.locationsArray?.filter(
      (item: any) => item.branchId == this.selectedEnrollmentDetails.branchId
    );
    if(locationSelected){
    locations.patchValue(locationSelected[0]);
    this.selectedLocation = locationSelected[0];
    }
    this.onLocationSelection();
    const categorySelected = this.categoryList.filter(
      (item: any) => item.id == this.selectedEnrollmentDetails.courseCategoryId
    );
    this.onCategorySelection(categorySelected[0]);
    const courseSelected = this.coursesList.filter(
      (item: any) => item.id == this.selectedEnrollmentDetails.courseId
    );
    category.patchValue(categorySelected[0]);
    this.onCourseSelection(courseSelected[0]);
    course.patchValue(courseSelected[0]);
    const slotSelected = this.timings.filter(
      (item: any) => item.name == this.selectedEnrollmentDetails.timing
    );
   
    timings.patchValue(slotSelected[0]);
   
  }

  onRefundInitiation(){
    this.showChangeCourseDialog = false;
    this.showRefundCourseDialog = false;
    this.confirmationService.confirm({
      message: 'Are you sure you want to refund the amount of $'+ this.refundDetails.courseFee +'?',
      icon: 'pi pi-exclamation-triangle',
      key:'confirmationMsg',
      accept: () => {
        this.onRefundActivate(this.selectedEnrollment.enrollmentId);
      },
    })
  }
  onRefundActivate(enrollmentId:any){
    try{
      this.enrollmentService.getRefund(enrollmentId).subscribe((data)=>{
          if(data.statusCode == 200){
            this.messageService.add({
              severity:'success',
              summary: 'Success',
              detail:'Refund completed successfully'
            });
            this.getEnrollments(null, this.selectedAcademicYear.id, null);
          }
      },
       (error: any) => {
        this.appinsightsService.logException(error, 1);
        this.appinsightsService.logEvent(
          'Error: Getting error in get refund api',
          { error: JSON.stringify(error) }
        );
        if (error.status == 401) {
          this.sharedService.token_Expired.next(true);
          localStorage.clear();
        } else {
          this.appinsightsService.logException(error, 2);
        }
      })
    }
    catch(error:any){
      this.appinsightsService.logException(error, 2);
    }
  }
  getCountries(){
    try{
      this.loader =true;
      this.courseDetails.getCountry().subscribe((data)=>{
        this.loader = false;
       this.countryApiArray=data.result;
      },(error:any)=>{
        // this.errorHandling.ErrorHandling(error);
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: Getting error in get all courses Country list api", {error:JSON.stringify(error)})
      })
    }
    catch(error:any){
      this.appinsightsService.logException(error,2)
    }
  }
}
