import { Component, OnInit } from '@angular/core';
import { latestNews } from './latestNews';
import { HomeService } from 'src/app/services/home.service';
import { LoaderService } from 'src/app/services/loader.service';
import { Router } from '@angular/router';
import { LoggingService } from 'src/app/services/logging.service';
import { CourseDetailsService } from 'src/app/services/course-details.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  latestNews: latestNews[]=[];
  latestNewsCount: number = 2;
  responsiveOptions: any;
  loader: boolean = false;
  selectedAcademicYear: any;
  academicYearsList: any;
  carousalImages = [
    // {id:0, imagepath:"assets/images/UgadiInvitaion.jpg", showButton: false},
    {id:0, imagepath:"assets/images/carousalImages/secondcarousalImage.png", showButton: false},
    {id:0, imagepath:"assets/images/carousalImages/thirdcarousalImage.png",  showButton: false},
    {id:0, imagepath:"assets/images/carousalImages/fourthcarousalImame.png", showButton: false},
    {id:0, imagepath:"assets/images/carousalImages/fivethcarousalImage.png",  showButton: false},
  ]
  constructor(
    private router: Router,
    private home: HomeService,
    private loaderService: LoaderService,
    private courseDetails: CourseDetailsService,
    private appinsightsService: LoggingService
  ) { }

  ngOnInit(): void {
    this.responsiveOptions = [
      {
          breakpoint: '1024px',
          numVisible: 5
      },
      {
          breakpoint: '768px',
          numVisible: 3
      },
      {
          breakpoint: '560px',
          numVisible: 1
      }
  ];
    this.getLatestNewsData(this.latestNewsCount);
    this.getAcademicYears()
    this.getCourseDetails(this.selectedAcademicYear?.id);
    this.appinsightsService.logEvent('Home viewed')
  }

  ngAfterViewInit(){
    setTimeout(()=>{
      this.carousalImages[0].showButton = true;
    },1000)
  }

  getLatestNewsData(numberOfNews:number){
    try{
      this.loaderService.display(true);
      this.loader = true;
      this.home.getLatestNews(numberOfNews).subscribe((data:any)=>{
        if(data.statusCode == 200){
          this.loader = false;
          this.latestNews = data.result;
        }
      },(error:any)=>{
        console.log(error)
        this.appinsightsService.logException(error);
        this.appinsightsService.logEvent("Error: Getting latest news api", {error:JSON.stringify(error)})
      })
    }
    catch(error:any){
      console.log(error)
       this.loaderService.display(false);
       this.appinsightsService.logException(error,1);
    }
  }
  getCourseDetails(academicYearId:any){
    try{
      this.courseDetails.getAllCoursesData(academicYearId,null).subscribe((data)=>{
        this.home.allCourses.next(data);
      },(error:any)=>{
        this.appinsightsService.logException(error);
        this.appinsightsService.logEvent("Error: Getting all courses list api in home screen", {error:JSON.stringify(error)})
      })
    }
    catch(error:any){
      this.loaderService.display(false);
      this.appinsightsService.logException(error,1);
    }
  }

  getAcademicYears() {
    try {
      this.loader = true;
      this.courseDetails.getAcademicYears().subscribe((data: any) => {
        this.loader = false;
        this.academicYearsList = data.result;
        this.academicYearsList.forEach((item: any) => {
          if (item.isRegistrationsOpen == true) {
            this.selectedAcademicYear = item;
          }
        });
        this.getCourseDetails(this.selectedAcademicYear.id)
        // this.getCategoriesDetails(null, this.selectedAcademicYear.id);
      });
    } catch (error: any) {
      this.appinsightsService.logException(error);
    }
  }

  navigateToCourses(id:number){
    this.router.navigate(['/all-courses',id])
  }
  navigateToRegistration(){
    this.router.navigate(['/registration/parent-details']);
  }
  navigateToDonate(){
    this.router.navigate(['/Donate']);
  }
    
  // getAcademicYears() {
  //   try {
  //     this.loader = true;
  //     this.courseDetails.getAcademicYears().subscribe((data: any) => {
  //       this.loader = false;
  //       this.academicYearsList = data.result;
  //       this.academicYearsList.forEach((item: any) => {
  //         if (item.isRegistrationsOpen == true) {
  //           this.selectedAcademicYear = item;
  //         }
  //       });
  //       this.getCourseDetails(this.selectedAcademicYear.id)
  //     });
  //   } catch (error: any) {
  //     this.appinsightsService.logException(error);
  //   }
  // }


  
}
