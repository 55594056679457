<div class="container-fluid">
    <p-toast position="top-center"></p-toast>
    <div class="row">
        <div class="col-12 text-center py-3">
            <h4 class="fw-semibold m-0">Login</h4>
        </div>
    </div>
    <div class="row px-md-5 mx-md-5">
        <div class="col-12 col-lg-6 col-md-9 offset-md-2">
            <form [formGroup]="RoleBasedAccessForm">
                <div class="row">
                    <div
                        class="col-12 col-md-6 offset-md-3 p-2 py-4 px-md-5 d-flex align-items-center justify-content-around">
                        <div *ngFor="let role of roles" class="d-flex align-items-center">
                            <p-radioButton formControlName="roleType" class="pr-2" required [inputId]="role.id"
                                name="roleType" [value]="role" [(ngModel)]="selectedrole"
                                (click)="selectRole()"></p-radioButton>
                            <label class="m-2">{{ role.label }}</label>
                        </div>
                    </div>
                </div>
            </form>

            <form [formGroup]="parentLoginForm" *ngIf="showParentForm"
                (ngSubmit)="onParentLogin(parentLoginForm.value)">
                <div class="row p-2 p-lg-5 rounded bg-white card_view shadow">
                    <div class="col-12 px-md-5">
                        <h4 class="fw-bold">Welcome Back</h4>
                        <p>Enter your email address to login to your ManaSamskriti account!</p>
                    </div>
                    <div class="col-12 px-md-5">
                        <label for="email" class="dark-ask">Email<span class="text-danger"> *</span></label>
                        <input pInputText name="email" id="email" placeholder="Eg: abc@gmail.com" type="text"
                            class="clsControl form-control" formControlName="email" (keyup)="resetError()" />
                        <div class="text-danger">
                            <span class="text-capitalize">{{
                                validation.validateFormControl('email','Email',parentLoginForm) }}</span>
                        </div>
                    </div>
                    <div class="col-md-8 offset-md-2 px-md-5" *ngIf="invalidCredentials != '' && !parentLoginForm.controls['email'].errors">
                        <p class="p-2 text-danger m-0">{{invalidCredentials}}</p>
                    </div>
                    <div class="col-12 text-center px-md-5 py-4">
                        <button pButton pRipple type="submit" class="btn-blue btn-lg w-100 d-flex align-items-center justify-content-center"
                            [ngClass]="{'px-5 text-center text-white': loader}">
                            <span *ngIf="!loader" class="text-white">Send Code</span>
                            <p-progressSpinner *ngIf="loader" class="custom-spinner" strokeWidth="3"
                                animationDuration=".5s"></p-progressSpinner>
                        </button>
                    </div>
                </div>
            </form>
       
            <form [formGroup]="otpValidationForm" *ngIf="showOTPForm"
                (ngSubmit)="onOTPValidation(otpValidationForm.value)">
                <div class="row p-2 p-lg-3 rounded bg-white card_view shadow  ">
                    <div class="text-end "> <a class="cursor text-decoration-none text-danger" href="/home"><i class="fa fa-arrow-left" style="font-size: 16px;" ></i><span class="p-1" >Back</span></a>  </div>
                    <div class="col-12 px-md-5">
                        <div>
                            <h2 class="fw-bold">Verification required</h2>
                            <p class="dark-ask">We've sent a verification code to your email ({{enteredEmail}}). Enter the verification code to login to you account.</p>
                        </div>
                        <div class="row">
                            <div class="col-12 px-md-5">
                                <div class="d-flex align-items-center py-3">
                                    <input pInputText name="firstDigit" maxlength="1" id="firstDigit" type="text" #firstDigit
                                        class="clsControl form-control mx-2 text-center otp_control" formControlName="firstDigit"
                                        (keyup)="otpController($event, secondDigit, '', 1)" />
                                    <input pInputText name="secondDigit" maxlength="1" id="secondDigit" type="text" #secondDigit
                                        class="clsControl form-control w-10 mx-2 text-center otp_control" formControlName="secondDigit"
                                        (keyup)="otpController($event, thirdDigit, firstDigit, 1)" />
                                    <input pInputText name="thirdDigit" maxlength="1" id="thirdDigit" type="text" #thirdDigit
                                        class="clsControl form-control w-10 mx-2 text-center otp_control" formControlName="thirdDigit"
                                        (keyup)="otpController($event, fourthDigit, secondDigit, 1)" />
                                    <input pInputText name="fourthDigit" maxlength="1" id="fourthDigit" type="text" #fourthDigit
                                        class="clsControl form-control w-10 mx-2 text-center otp_control" formControlName="fourthDigit"
                                        (keyup)="otpController($event, '', thirdDigit, 1)" />
                                </div>
                            </div>
                        </div>
                        <div class="text-center">
                            <button pButton pRipple type="submit" class="btn-blue btn-lg w-100 d-flex align-items-center justify-content-center"
                                [ngClass]="{'px-5 text-center text-white': loader}">
                                <span *ngIf="!loader" class="text-white">Login</span>
                                <p-progressSpinner *ngIf="loader" class="custom-spinner" strokeWidth="3"
                                    animationDuration=".5s"></p-progressSpinner>
                            </button>
                        </div>
                        <div class="text-center">
                            <p *ngIf="otpErrorMsg != ''" class="p-2 text-danger">{{otpErrorMsg}}</p>
                            <p *ngIf="this.otpValidationForm.invalid&&this.otpError" class="p-2 text-danger">Please fill OTP</p>
                            <a class="d-inline-block dark-ask pt-2 cursor" (click)="sendOTP(enteredEmail)">Resend
                                OTP</a>
                        </div>
                    </div>
                </div>
            </form>
      
            <form [formGroup]="adminLoginForm" *ngIf="showAdminForm" (ngSubmit)="onAdminLogin(adminLoginForm.value)">
                <div class="row p-2 p-lg-5 rounded bg-white card_view shadow">
                    <div class="col-12 px-md-5 py-2">
                        <div>
                            <h2 class="fw-bold">Welcome Back</h2>
                            <p>Let's login to your Manasamskriti Admin account</p>
                        </div>
                    </div>
                    <div class="col-12 px-md-5 py-2">
                        <label for="email" class="dark-ask">Email<span class="text-danger"> *</span></label>
                        <input pInputText name="email" id="email" placeholder="Eg: abc@gmail.com" type="text"
                            class="clsControl form-control" formControlName="email" (keyup)="resetError()"/>
                        <div class="text-danger">
                            <span class="text-capitalize">{{
                                validation.validateFormControl('email','Email',adminLoginForm) }}</span>
                        </div>
                    </div>
                    <div class="col-12 px-md-5 py-2">
                        <label for="password" class="dark-ask">Password<span class="text-danger"> *</span></label>
                        <input pInputText name="password" id="password" type="password" class="clsControl form-control"
                            formControlName="password" (keyup)="resetError()"/>
                        <div class="text-danger">
                            <span class="text-capitalize">{{
                                validation.validateFormControl('password','Password',adminLoginForm) }}</span>
                        </div>
                    </div>
                    <div class="col-12 px-md-5" *ngIf="invalidCredentials != ''&& (!adminLoginForm.controls['email'].errors ||!adminLoginForm.controls['password'].errors) ">
                        <p class="p-2 text-danger m-0">{{invalidCredentials}}</p>
                    </div>
                    <div class="col-12 text-center px-md-5 py-4">
                        <button pButton pRipple type="submit" class="btn-blue btn-lg w-100 d-flex align-items-center justify-content-center"
                            [ngClass]="{'px-5 text-center text-white': loader}">
                            <span *ngIf="!loader" class="text-white">Login</span>
                            <p-progressSpinner *ngIf="loader" class="custom-spinner" strokeWidth="3"
                                animationDuration=".5s"></p-progressSpinner>
                        </button>
                        <div class="py-2">
                            <a class="d-inline-block dark-ask pt-2 cursor text-underline"
                                (click)="ForgotPassword()">Forgot your Password?</a>
                        </div>
                    </div>
                </div>
            </form>
            <form [formGroup]="forgotPasswordForm" *ngIf="showForgotPasswordForm"
                (ngSubmit)="onResetPassword(forgotPasswordForm.value)">
                <div class="row p-2 p-lg-5 rounded bg-white card_view shadow">
                    <div class="col-12 px-md-5 py-2">
                        <div>
                            <h2 class="fw-bold">Forgot your password?</h2>
                            <p class="dark-ask">Enter the email address associated with Manasamskriti account</p>
                        </div>
                    </div>
                    <div class="col-12 px-md-5 py-2">
                        <label for="email" class="dark-ask">Email<span class="text-danger"> *</span></label>
                        <input pInputText name="email" id="email" placeholder="Eg: abc@gmail.com" type="text"
                            class="clsControl form-control" formControlName="email" />
                        <div class="text-danger">
                            <span class="text-capitalize">{{
                                validation.validateFormControl('email','Email',forgotPasswordForm) }}</span>
                        </div>
                    </div>
                    <div class="col-12 px-md-5" *ngIf="invalidCredentials != ''&& (!forgotPasswordForm.controls['email'].errors) ">
                        <p class="p-2 text-danger m-0">{{invalidCredentials}}</p>
                    </div>
                    <div class="col-12 text-center px-md-5 py-4">
                        <button pButton pRipple type="submit" class="btn-blue btn-lg w-100 d-flex align-items-center justify-content-center"
                            [ngClass]="{'px-5 text-center text-white': loader}">
                            <span *ngIf="!loader" class="text-white">Send</span>
                            <p-progressSpinner *ngIf="loader" class="custom-spinner" strokeWidth="3"
                                animationDuration=".5s"></p-progressSpinner>
                        </button>
                    </div>
                </div>
            </form>
            <form [formGroup]="createPasswordForm" *ngIf="showcreatePasswordForm"
                (ngSubmit)="onCreateNewPassword(createPasswordForm.value)">
                <div class="row p-2 p-lg-5 rounded bg-white card_view shadow">
                    <div class="col-12 px-md-5 py-2">
                        <div>
                            <h2 class="fw-bold">Create Password</h2>
                        </div>
                    </div>
                    <div class="col-12 px-md-5 py-2">
                        <label for="newPw" class="dark-ask">New Password<span class="text-danger"> *</span></label>
                        <input pInputText name="newPw" id="newPw" type="text" class="clsControl form-control"
                            formControlName="newPw" (keyup)="resetError()"/>
                        <div class="text-danger">
                            <span class="text-capitalize">{{
                                validation.validateFormControl('newPw','Password',createPasswordForm) }}</span>
                        </div>
                    </div>
                    <div class="col-12 px-md-5 py-2">
                        <label for="confirmPassword" class="dark-ask">Repeat New Password<span class="text-danger">
                                *</span></label>
                        <input pInputText name="confirmPassword" id="confirmPassword" type="password"
                            class="clsControl form-control" formControlName="confirmPassword" (keyup)="resetError()"/>
                        <div class="text-danger">
                            <span class="text-capitalize">{{
                                validation.validateFormControl('confirmPassword','Password',createPasswordForm)
                                }}</span>
                        </div>
                    </div>
                    <div class="col-12 px-md-5" *ngIf="passwordErr != ''&& (!createPasswordForm.controls['newPw'].errors || !createPasswordForm.controls['confirmPassword'].errors) ">
                        <p class="p-2 text-danger m-0">{{passwordErr}}</p>
                    </div>
                    <div class="col-12 text-center px-md-5 py-4">
                        <button pButton pRipple type="submit" class="btn-blue btn-lg w-100 d-flex align-items-center justify-content-center"
                            [ngClass]="{'px-5 text-center text-white': loader}">
                            <span *ngIf="!loader" class="text-white">Save</span>
                            <p-progressSpinner *ngIf="loader" class="custom-spinner" strokeWidth="3"
                                animationDuration=".5s"></p-progressSpinner>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>