import { Injectable } from '@angular/core';
import { GlobalResources } from './global-resource/global-resource';
import { ParentDetailsService } from './parent-details.service';
import { LoggingService } from './logging.service';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  globalResource = GlobalResources.getResources().en;
  constructor(private parentDetails: ParentDetailsService,private appinsightsService: LoggingService,) {}

  validateFormControl(
    formControlName: string,
    errorControlName: string,
    formname: any
  ) {
    if (
      !formname.controls[formControlName].valid &&
      formname.controls[formControlName].dirty
    ) {
      if (
        formname.controls[formControlName] &&
        formname.controls[formControlName].errors &&
        formname.controls[formControlName].errors?.['required']
      ) {
        return errorControlName + ' required';
      } else if (
        formname.controls[formControlName] &&
        formname.controls[formControlName].errors &&
        formname.controls[formControlName].errors?.['pattern']
      ) {
        return errorControlName + ' invalid';
      } else if (
        formname.controls[formControlName] &&
        formname.controls[formControlName].errors &&
        formname.controls[formControlName].errors?.['maxlength']
      ) {
        return this.globalResource.exceedCharLimit;
      }
      else if (
        formname.controls[formControlName] &&
        formname.controls[formControlName].errors &&
        formname.controls[formControlName].errors?.['minlength']
        
      )
       {
        return errorControlName + ` should have minimum  ${ formname.controls[formControlName].errors?.['minlength'].requiredLength} characters length`;
      }
    }
    return;
  }

  validateNames(controlname:any, firstControlName:string, secondControlName:string){
    if(controlname.controls[firstControlName].value != null && controlname.controls[firstControlName].value == controlname.controls[secondControlName].value && controlname.controls[secondControlName].dirty)
    {
      controlname['controls'][secondControlName].setErrors({'incorrect': true});
       const error ="First name and Last name can not be same";
       return error;
    }
    else{
      return "";
    }
  }

  async decodeLoggedInToken(){
    try{
      const value: any  = (localStorage.getItem('token'));
      let token =  value? JSON.parse(value): "";
      let decodedToken = this.decodeToken(token);
      const tokenExpDate = new Date(decodedToken.exp * 1000);
      const currentDate = new Date();
      let difference =  tokenExpDate.getTime() - currentDate.getTime();
      const minutesDifference = Math.floor(difference / (1000 * 60)); // Convert to minutes
      if( minutesDifference <= 5 && minutesDifference > 0){
        const data = await( await this.parentDetails.getRenewedToken(token).toPromise())
          localStorage.setItem("token", JSON.stringify(data.result));
          this.appinsightsService.logEvent("Info: Received renewal token successfully")
        return ;
      }
      return ;
    }
    catch(error:any){
      this.appinsightsService.logException(error,2);
      return ;
    }
  }
  decodeToken(token: string): any {
    try {
      const decodedToken = jwt_decode(token);
      return decodedToken;
    } catch (error:any) {
      this.appinsightsService.logException(error,2);
      return null;
    }
  }
}