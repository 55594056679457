<div class="container-fluid">
    <p-toast position="top-center"></p-toast>
    <div class="row">
        <div class="col-12">
            <form [formGroup]="addStudentDetails" (ngSubmit)="onSubmit(addStudentDetails.value)">
                <div class="row p-4 rounded bg-white card_view shadow">
                    <div class="col-12 p-2 px-md-5">
                        <div class="row">
                            <div class="col-xs-12 col-md-6">
                                <h4 for="addOrEditStudent" class="fw-bold">Add/Edit Student<span class="text-danger">
                                        *</span></h4>
                                <p-dropdown name="addOrEditStudent" id="addOrEditStudent"
                                    formControlName="addOrEditStudent" class="dropdown d-block student_details" placeholder="Select a Student" [options]="studentsList" optionLabel="label" (onChange)="onSelectingStudent($event)"></p-dropdown>
                                <div class="text-danger" >
                                    <span class="text-capitalize">{{ validation.validateFormControl('addOrEditStudent','student selection',addStudentDetails) }}</span>
                                </div>
                                <p-progressSpinner *ngIf="loader" class="custom-spinner"  strokeWidth="3"
                                animationDuration=".5s" ></p-progressSpinner>  
                            </div>
                        </div>
                    </div>
                    <div class="col-12 p-2 px-md-5" *ngIf="showData">
                        <label  class="dark-ask">Student ID</label>
                        <p *ngIf="showData && addStudentDetails['controls']['studentId'].value != null " class="pt-2">{{addStudentDetails['controls']['studentId'].value}}</p>
                    </div>
                    <div class="col-xs-12 col-md-6 p-2 px-md-5">
                        <label for="firstName" class="dark-ask">First Name<span class="text-danger"> *</span></label>
                        <input pInputText name="firstName" id="firstName" type="text" class="clsControl form-control"
                            formControlName="firstName"/>
                        <div class="text-danger" >
                            <span class="text-capitalize">{{ validation.validateFormControl('firstName','First Name',addStudentDetails) }}</span>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 p-2 px-md-5">
                        <label for="lastName" class="dark-ask">Last Name<span class="text-danger"> *</span></label>
                        <input pInputText name="lastName" id="firstName" type="text" class="clsControl form-control"
                            formControlName="lastName"/>
                        <div class="text-danger" >
                            <span class="text-capitalize">{{ validation.validateFormControl('lastName','Last Name',addStudentDetails) }}</span>
                        </div>
                        <div class="text-danger" >
                            <span class="text-capitalize">{{ validation.validateNames(addStudentDetails, 'firstName', 'lastName') }}</span>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 p-2 px-md-5">
                        <label for="teluguName" class="dark-ask d-flex align-items-center">Telugu Name
                            <span><i
                                    class="pi pi-info-circle d-inline-block m-2 blue-berry"
                                    pTooltip="Paste Telugu name to show in your certificate"
                                    tooltipPosition="top"></i></span>
                                    <span  pTooltip="Navigate to google translator"
                                    tooltipPosition="top"><svg style="height:20px; width:15px" class="cursor" (click)="navigateToTranslator()" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M22.401 4.818h-9.927L10.927 0H1.599C.72 0 .002.719.002 1.599v16.275c0 .878.72 1.597 1.597 1.597h10L13.072 24H22.4c.878 0 1.597-.707 1.597-1.572V6.39c0-.865-.72-1.572-1.597-1.572zm-15.66 8.68c-2.07 0-3.75-1.68-3.75-3.75 0-2.07 1.68-3.75 3.75-3.75 1.012 0 1.86.375 2.512.976l-.99.952a2.194 2.194 0 0 0-1.522-.584c-1.305 0-2.363 1.08-2.363 2.409S5.436 12.16 6.74 12.16c1.507 0 2.13-1.08 2.19-1.808l-2.188-.002V9.066h3.51c.05.23.09.457.09.764 0 2.147-1.434 3.669-3.602 3.669zm16.757 8.93c0 .59-.492 1.072-1.097 1.072h-8.875l3.649-4.03h.005l-.74-2.302.006-.005s.568-.488 1.277-1.24c.712.771 1.63 1.699 2.818 2.805l.771-.772c-1.272-1.154-2.204-2.07-2.89-2.805.919-1.087 1.852-2.455 2.049-3.707h2.034v.002h.002v-.94h-4.532v-1.52h-1.471v1.52H14.3l-1.672-5.21.006.022h9.767c.605 0 1.097.48 1.097 1.072v16.038zm-6.484-7.311c-.536.548-.943.873-.943.873l-.008.004-1.46-4.548h4.764c-.307 1.084-.988 2.108-1.651 2.904-1.176-1.392-1.18-1.844-1.18-1.844h-1.222s.05.678 1.7 2.61z" fill="#605DEC"></path></svg></span>
                                </label>
                        <input pInputText name="teluguName" id="teluguName" type="text" class="clsControl form-control"
                            formControlName="teluguName" placeholder="హర్ష"/>
                        <div class="text-danger" >
                            <span class="text-capitalize">{{ validation.validateFormControl('teluguName','Telugu Name',addStudentDetails) }}</span>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 p-2 px-md-5">
                        <label for="age" class="dark-ask">Age<span class="text-danger"> *</span></label>
                        <input pInputText name="age" id="age" type="text" class="clsControl form-control"
                            formControlName="age"/>
                        <div class="text-danger" >
                            <span class="text-capitalize">{{ validation.validateFormControl('age','Age',addStudentDetails) }}</span>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 p-2 px-md-5">
                        <label for="gender" class="dark-ask">Gender<span class="text-danger"> *</span></label>
                        <p-dropdown name="gender" id="gender"
                                    formControlName="gender" class="dropdown d-block studentDetail_dropdown" placeholder="Select Gender" [options]="genderArray" optionLabel="label"></p-dropdown>
                        <div class="text-danger" >
                            <span class="text-capitalize">{{ validation.validateFormControl('gender','gender',addStudentDetails) }}</span>
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-6 p-2 px-md-5">
                        <label for="relationship" class="dark-ask">Relationship<span class="text-danger"> *</span></label>
                        <p-dropdown name="relationship" id="relationship"
                                    formControlName="relationship" class="dropdown d-block studentDetail_dropdown" placeholder="Select Relation" [options]="relationShipArray" optionLabel="label"></p-dropdown>
                        <div class="text-danger" >
                            <span class="text-capitalize">{{ validation.validateFormControl('relationship','Relationship',addStudentDetails) }}</span>
                        </div>
                    </div>
                    <!-- <div class="col-xs-12 col-md-6 p-2 px-md-5">
                        <label for="previousCenter" class="dark-ask">Previous Attended Center</label>
                        <p-dropdown name="previousCenter" id="previousCenter"
                                    formControlName="previousCenter" class="dropdown d-block studentDetail_dropdown" placeholder="Select Previous Center" [options]="previousCentersArray" optionLabel="label"></p-dropdown>
                    </div> -->
                    <div class="col-12 p-2 px-md-5" *ngIf="showCourses">
                        <h4 class="fw-bold">Registered Courses</h4>
                    </div>
                    <div class="col-12 p-2 px-md-5" *ngIf="showCourses">

                        <p-table [tableStyle]="{ 'min-width': '50rem' }" [value]="registeredCoursesList">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="bg-white">S.No</th>
                                    <th class="bg-white">Type</th>
                                    <th class="bg-white">Location</th>
                                    <th class="bg-white">Category</th>
                                    <th class="bg-white">Course Name</th>
                                    <th class="bg-white">Timings</th>
                                    <th class="bg-white">Payment Status</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-course let-rowIndex="rowIndex">
                                <tr *ngIf="registeredCoursesList.length > 0">
                                    <td>{{rowIndex + 1 }}</td>
                                    <td>{{ course.courseType }}</td>
                                    <td>{{ course.location }}</td>
                                    <td>{{ course.category }}</td>
                                    <td>{{ course.courseName }}</td>
                                    <td>{{ course.timings }}</td>
                                    <td [class]="{'text-danger' :  course?.paymentStatus == 'InProcess', 'text-success':course?.paymentStatus == 'Completed'}">{{ course.paymentStatus }}</td>
                                </tr>
                                <tr *ngIf="registeredCoursesList.length == 0">
                                    <p>No Registered courses available</p>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12  p-0 py-3 text-end">
                        <a routerLink="/registration/parent-details"><span
                                class="text-danger d-inline-block p-3">Previous</span></a>
                        <button pButton pRipple type="submit" label="Next"
                            class="btn-blue  btn-lg"></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>