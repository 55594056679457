<div class="container-fluid">
    <div class="row">
        <div class="col-12 py-3">
            <h4 class="fw-semibold text-center m-0">Courses</h4>
            <p class="text-center m-0">(Timeslots are in PST)</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12  py-3">
           <div class="row">
            <div class="col-xs-12 col-lg-3 col-md-4">
                <p-dropdown name="Country" id="Country" 
                 class="dropdown d-block dropDownBorder "  [(ngModel)]="defaultCountry" (onChange)="selectedCountry($event.value?.id)"  [options]="countryApiArray"  optionLabel="countryName"></p-dropdown>
            </div>
           </div>
       
        </div>
    </div>
    <div class="row pt-4" *ngIf="loader">
        <div class="col text-center">
            <p-progressSpinner *ngIf="loader" class="custom-spinner" strokeWidth="3"
                animationDuration=".5s"></p-progressSpinner>
        </div>
    </div>
    <div class="row">
     
        <p-tabView *ngIf="!loader"  [(activeIndex)]="activeIndex">
            <span *ngIf="this.errorMessage" class="text-center">{{this.errorMessage}}</span>
            <p-tabPanel *ngFor="let course of courseData" [header]="course.categoryName">
                <div class="d-flex align-items-center justify-content-start" *ngIf="activeIndex == 0">
                    <div *ngFor="let course of courseTypeArray" class="d-flex align-items-center">
                        <p-radioButton class="pr-2" required [inputId]="course.id" name="courseType" [value]="course"
                            [(ngModel)]="selectedCourseType" (click)="filterCourses()"></p-radioButton>
                        <label [for]="course.id" class="m-2">{{ course.label }}</label>
                    </div>
                </div>
                <hr class="m-2 border-bottom breakLine">
                <p-accordion [activeIndex]="0">
                    
                    <p-accordionTab *ngFor="let courseData of course.courses" [header]="courseData.courseName">
                        <div class="row">
                            <div class="col-12">
                                <h5 class="dark-pista fw-semibold">Minimum Age: {{courseData.minimumAge}} years AND
                                    pre-requisite: {{courseData.preRequisite}} </h5>
                            </div>
                            <div class="col-12">
                                <p>
                                    {{courseData.description}}
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <h5 class="dark-pista fw-semibold">Time Slots:</h5>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-md-2 card_view shadow p-0 rounded">
                                <div
                                    class="p-2 text-center leaf d-flex align-items-center justify-content-center rounded-top">
                                    <h5 class="fw-semibold text-white m-0 p-2">Online</h5>
                                </div>
                                <div *ngIf="courseData.onlineSlots.length > 0" class="p-2">
                                    <div  class="p-2" *ngFor="let slots of courseData.onlineSlots">
                                        <p class="m-0"><b>{{slots?.day}}</b></p>
                                        <p class="m-0">{{slots?.slot}}</p>
                                    </div>
                                </div>
                                <div *ngIf="courseData.onlineSlots.length == 0" class="p-2 text-center  d-flex align-items-center justify-content-center">
                                    <p class="m-0">Not available</p>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-10  ps-0 pe-0 pt-3 pt-md-0 pe-md-0 ps-md-3 mediumScreenOverFlow">
                                <div class="card_view shadow p-0 rounded  h-100 mediumScreenWidth">
                                    <div class="p-2 text-center leaf rounded-top">
                                        <h5 class="fw-semibold mediumScreenText text-white m-0 p-2">In-Person</h5>
                                    </div>
                                    <div class="row m-0 inperson_row position-relative"
                                        *ngIf="courseData.inPersonSlots.length > 0">
                                        <div class="col h-100 md_view "
                                            *ngFor="let inpersonSlots of courseData.inPersonSlots; let i = index"
                                            [ngClass]="{'bg-pumkin': i%2 == 0}">
                                            <div class="p-2 text-center" *ngIf="inpersonSlots.slots.length == 0">
                                                <p class="m-0">Not available</p>
                                            </div>
                                            <div class="py-2" *ngFor="let slots of inpersonSlots.slots">
                                                <p class="m-0"><b>{{slots?.day}}</b></p>
                                                <p class="m-0">{{slots?.name}}</p>
                                            </div>
                                            
                                            <div class="py-2" *ngFor="let slots of inpersonSlots.slots">
                                                <p class="m-0 " style="font-size: small;" >{{inpersonSlots.address}}</p> 
                                            </div>

                                            <div class="course_location">
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <p class="p-0 m-0 shadow text-white bg-success rounded px-2">
                                                        {{inpersonSlots.branchName}}<span *ngIf="inpersonSlots?.bingMapLink == 'NA'"> (TBD)</span></p>
                                                    <p class="p-0 m-0 shadow text-white btn-blue rounded px-2 mx-4 cursor" (click)="navigateToLocation(inpersonSlots?.bingMapLink)"  *ngIf="inpersonSlots?.bingMapLink != 'NA'"><i
                                                            class="pi pi-map-marker"></i></p>
                                                </div>
                                            </div>
                      
                                       
                                        </div>
                                        <div class="col-scroll h-100 p-0">
                                            <div class="col h-100"
                                                *ngFor="let inpersonSlots of courseData.inPersonSlots; let i = index"
                                                [ngClass]="{'bg-pumkin': i%2 == 0}">
                                                <div class="column-content">
                                                    <div class="p-2 text-center" *ngIf="inpersonSlots.slots.length == 0">
                                                        <p class="m-0">Not available</p>
                                                    </div>
                                                    <div class="py-2" *ngFor="let slots of inpersonSlots.slots">
                                                        <p class="m-0"><b>{{slots?.day}}</b></p>
                                                        <p class="m-0 ">{{slots?.name}}</p></div>
                                                        <p class="m-0 pb-2">{{inpersonSlots.address}}</p> 
                                                    <div class="course_location">
                                                        <div class="d-flex align-items-center justify-content-between">
                                                            <p
                                                                class="p-0 m-0 shadow text-white bg-success rounded px-2">
                                                                {{inpersonSlots.branchName}}<span *ngIf="inpersonSlots?.bingMapLink == 'NA'"> (TBD)</span></p>
                                                            <p
                                                                class="p-0 m-0 shadow text-white btn-blue rounded px-2 mx-4 cursor" (click)="navigateToLocation(inpersonSlots?.bingMapLink)"  *ngIf="inpersonSlots?.bingMapLink != 'NA'">
                                                                <i class="pi pi-map-marker"></i>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div *ngIf="courseData.inPersonSlots.length == 0" class="text-center d-flex align-items-center justify-content-center">
                                        <p class="p-3 text-center m-0">Not available</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </p-accordionTab>

                </p-accordion>
            </p-tabPanel>
            
        </p-tabView>
      
    </div>
</div>