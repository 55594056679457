<div class="container-fluid">
    <div class="row">
        <div class="col-12 text-center py-3">
             <h4 class="fw-bold m-0">Registration for the 2024-2025 Academic Year</h4>
             <p class="ash">Please fill out this form with the required information</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <p-steps [model]="items" [readonly]="true" [activeIndex]="activeIndex" [styleClass]="'completed-'+completed" (activeIndexChange)="onActiveIndexChange($event)"></p-steps>
        </div>
    </div>
    <div class="row px-md-5">
        <div class="col-12 p-md-5 pb-md-0">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>