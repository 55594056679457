<div class="container-fluid">
    <div class="row">
        <div class="col-12 text-center py-3">
            <h4 class="fw-semibold m-0">Donate</h4>
        </div>
        <div class=" col-md-10 offset-md-1">
            <h6 >Thank you for the thought of donating to Mana Samskriti. We appreciate your help in achieving our mission to impart the language and cultural aspects of India. We do not have any administrative costs and all the proceeds will be used to achieve our stated mission.</h6>
            <h6 class="pt-3">Your donations might be tax-deductible as per IRS norms.<span class="fw-semibold text-danger">  Tax Id: 91-1918588.</span></h6>
            <h6 >You may use <span class="fw-semibold">Credit Card</span> or <span class="fw-semibold">PayPal</span> to donate. Please click the <span class="fw-semibold">‘Donate’ </span>button below to proceed.</h6>
        </div>
    </div>
    <div class="row px-md-5">
        <div class="col-md-6 p-3 bg-light-pista border dark-pista rounded offset-md-3 text-center" *ngIf="showPrompt">
            <p> Please continue in the next tab! </p>
        </div>

        <div class=" col-md-6 offset-md-3 pt-3">
            <form [formGroup]="donateForm" (ngSubmit)="onSubmit(donateForm.value,$event)" action="https://www.paypal.com/donate" target="_blank">
                <div class="row p-3 rounded bg-white card_view shadow">
                    <div class="col-12 p-2 px-md-5">
                        <label for="firstname" class="dark-ask">First Name<span class="text-danger"> *</span></label>
                        <input pInputText name="firstname" id="firstname" type="text" class="clsControl form-control"
                            formControlName="firstname"  />
                        <div class="text-danger">
                            <span class="text-capitalize">{{ validation.validateFormControl('firstname','First Name', donateForm) }}</span>
                        </div>
                    </div>
                    <div class="col-12 p-2 px-md-5">
                        <label for="lastname" class="dark-ask">Last Name<span class="text-danger"> *</span></label>
                        <input pInputText name="lastname" id="lastname" type="text" class="clsControl form-control"
                            formControlName="lastname"  />
                        <div class="text-danger">
                            <span class="text-capitalize">{{ validation.validateFormControl('lastname','Last Name', donateForm) }}</span>
                        </div>
                        <div class="text-danger" >
                            <span class="text-capitalize">{{ validation.validateNames(donateForm, 'firstname', 'lastname') }}</span>
                        </div>
                    </div>
                    <div class="col-12 p-2 px-md-5">
                        <label for="email" class="dark-ask">E-Mail<span class="text-danger"> *</span></label>
                        <input pInputText name="email" id="email" type="text" class="clsControl form-control"
                            formControlName="email"  placeholder="Eg: abc@gmail.com" />
                        <div class="text-danger">
                            <span class="text-capitalize">{{ validation.validateFormControl('email','Email',donateForm) }}</span>
                        </div>
                    </div>
                    <div class="col-12 p-2 px-md-5">
                        <label for="donationamount" class="dark-ask">Amount<span class="text-danger"> *</span></label>
                        <input pInputText name="donationamount" id="donationamount" type="text"
                            class="clsControl form-control" formControlName="donationamount" />
                        <div class="text-danger">
                            <span class="text-capitalize">{{ validation.validateFormControl('donationamount','Amount',donateForm) }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 p-3 text-end d-flex align-items-center justify-content-end">
                            <a (click)="reset()"><span
                                class="text-danger d-inline-block p-3 cursor">Reset</span></a>
                                <input type="hidden" name="business" value="payments@manasamskriti.org" />
                                <input type="hidden" name="no_recurring" value="1" />
                                <input type="hidden" name="item_name" value="Donate to ManaSamskriti to serve you better !" />
                                <input type="hidden" name="currency_code" value="USD" />
                                <input type="hidden" name="amount" value="0" />
                                <input pButton type="submit" class="btn-blue  btn-lg rounded" (click)="validateAmount(donateForm['controls']['donationamount'].value)" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="donatebtn" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                                <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            
                            <p-progressSpinner *ngIf="loader" class="custom-spinner"  strokeWidth="3"
                                animationDuration=".5s" ></p-progressSpinner>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>