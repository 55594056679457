import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { contactus } from './contact-us';
import { ParentDetailsService } from 'src/app/services/parent-details.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { HomeService } from 'src/app/services/home.service';
import { ValidationService } from 'src/app/services/validation.service';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-conctac-us',
  templateUrl: './conctac-us.component.html',
  styleUrls: ['./conctac-us.component.css'],
})
export class ConctacUsComponent implements OnInit {
  contactusForm: FormGroup;
  loader: boolean = false;
  branchesData:any;
  categoryList = [
    {id:"Website", label:"Website"},
    {id:"Course", label:"Course"},
    {id:"Suggestion", label:"Suggestion"},
    {id:"Volunteer", label:"Volunteer"},
    {id:"Other", label:"Other"},
  ]
  constructor(private fb: FormBuilder, 
    private parentDetails: ParentDetailsService, 
    private router: Router,
    private homeDetails: HomeService,
    private messageService: MessageService,
    public validation: ValidationService,
    private appinsightsService: LoggingService,
    private confirmationService: ConfirmationService,) {
    this.contactusForm = this.fb.group({
      fullname: new FormControl(
        null,
        Validators.compose([Validators.required, Validators.maxLength(150),Validators.pattern('^[^a-zA-Z]*[a-zA-Z]+.*$')])
      ),
      phonenumber: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('[0-9]{3}[0-9]{3}[0-9]{4}'),
        ])
      ),
      email: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
        ])
      ),
      category: new FormControl(null, Validators.required),
      comment: new FormControl(null, Validators.compose([Validators.required, Validators.maxLength(255)])),
    });
  }

  ngOnInit(): void {
    this.appinsightsService.logEvent('all courses screen viewed')
    this.getLocations();}


  onSubmit(formdata: any) {
    try {
      if (this.contactusForm.valid) {
        this.loader  = true;
        let contactus:contactus = {
          "fullName": formdata.fullname,
          "phoneNumber": formdata.phonenumber,
          "email": formdata.email,
          "category":formdata.category.id,
          "comments": formdata.comment
        }
        this.parentDetails.contactUs(contactus).subscribe((data)=>{
           if(data.statusCode == 200){
            this.loader=false;
            this.contactusForm.reset();
            this.messageService.add({
              severity:'success',
              summary: 'Success',
              detail: "Thanks for contacting us! We will contact you shortly."
            });
           }
        },(error:any)=>{
          this.appinsightsService.logException(error,1)
          this.appinsightsService.logEvent("Error: Getting error in contact us api", {payload: JSON.stringify(contactus),error:JSON.stringify(error)})
          if(error.status == 401){
           this.confirmationService.confirm({
             message: 'Apologies, but your session has timed out. To proceed, please log in again.',
             header: 'Confirmation',
             icon: 'pi pi-exclamation-triangle',
             key:'toastMsg',
             accept: () => {
              localStorage.clear()
              this.homeDetails.isUserLoggedIn.next(false);
              this.router.navigate(['/registration/parent-details']);
             },
           })
         }
         else if(error.status == 500){
           this.messageService.add({
             severity:'warn',
             summary: 'Warning',
             detail: "Something went wrong at server side!"
           });
         }
         else{
           this.messageService.add({
             severity:'warn',
             summary: 'Warning',
             detail: error.message
           });
         }
         this.loader=false;
       })
      } else {
        for (const field in this.contactusForm.controls) {
          this.contactusForm.controls[field].markAsDirty();
        }
      }
    } catch (error:any) {
      this.appinsightsService.logException(error,2)
    }
  }
  reset() {
    this.contactusForm.reset();
  }
  getLocations(){
    try{
      this.parentDetails.getBranches().subscribe((data)=>{
        if(data.statusCode == 200){
          this.branchesData = data.result;
        }
      },(error:any)=>{
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: error in contact us screen, while getting branches api", {error:JSON.stringify(error)})
      })
    }
    catch(error:any){
      this.appinsightsService.logException(error,2)
    }
  }
  navigateToLocation(link:string){
    window.open(link, "_blank");
  }
}
