<div class="container-fluid">
    <p-toast position="top-center"></p-toast>
    <div class="row">
        <div class="col-12 pt-4">
            <h3 class="text-dark fw-bold text-center">My Profile</h3>
        </div>
    </div>

    <div class="row pt-4" *ngIf="loader">
        <div class="col text-center">
            <p-progressSpinner *ngIf="loader" class="custom-spinner" strokeWidth="3"
                animationDuration=".5s"></p-progressSpinner>
        </div>
    </div>
    
<div *ngIf="!loader">
    <form [formGroup]="editParentDataForm"  >
    <div class="row px-md-3 ">
        <div class="col-md-3 col-12">
            <p class="dark-ask m-0">Parent ID</p>
            <p class="fw-semibold">{{this.parentData?.id}}</p>
        </div>
        <div class="col-md-3 col-12 ">
            <p class="dark-ask m-0">Registration Date</p>
            <p class="fw-semibold">{{ this.registeredCoursesList?.[0]?.createdOn}}</p>
            <!-- <p class="fw-semibold">{{enrollmentDetails.registrationDate | date:'mediumDate'}}</p> -->
        </div>
        <div class="col-md-3 col-12 ">
            <label for="email" class="dark-ask">Email <span class="text-danger">*</span></label>
            <input pInputText  [readOnly]="true" name="email" id="email" type="text" class="clsControl form-control disable_filed "
            formControlName="email" placeholder="Eg: abc@gmail.com" />
            <div class="text-danger" >
                <span class="text-capitalize">{{ validation.validateFormControl('email','Email',editParentDataForm) }}</span>
            </div>
        </div>

        <div class="col-md-3 col-12 ">
            <label for="alternateEmail" class="dark-ask">Alternative Email</label>
            <input pInputText name="alternateEmail" id="alternateEmail" type="text" 
                class="clsControl form-control" 
                formControlName="alternateEmail"
                placeholder="Eg: abc@gmail.com" (keyup)="validateAlternateEmail()" />
                <div class="text-danger" >
                    <span class="text-capitalize">{{ validation.validateFormControl('alternateEmail','Alternative Email',editParentDataForm) }}</span>
                    <span class="text-capitalize">{{ validateEmailError }}</span>
                </div>
        </div>
    </div>
    <div class="row px-md-3 pt-2">
        <div class="col-md-3 col-12">
            <label for="firstName" class="dark-ask">First Name <span class="text-danger">*</span></label>
            <input pInputText name="firstName" id="firstName" type="text" class="clsControl form-control"
              formControlName="firstName" />
            <div class="text-danger" >
                <span class="text-capitalize">{{ validation.validateFormControl('firstName','First Name',editParentDataForm) }}</span>
            </div>
        </div>
        <div class="col-md-3 col-12">
            <label for="lastName" class="dark-ask">Last Name <span class="text-danger">*</span></label>
            <input pInputText name="lastName" id="lastName" type="text" class="clsControl form-control"
            formControlName="lastName" />
            <div class="text-danger" >
                <span class="text-capitalize">{{ validation.validateFormControl('lastName','Last Name',editParentDataForm) }}</span>
            </div>
       
        </div>
        <div class="col-md-3 col-12">
            <label for="phoneNumber" class="dark-ask">Phone Number<span class="text-danger">*</span></label>
            <input pInputText name="phoneNumber"  id="phoneNumber" type="text" (keyup)="validatePhoneNumber()" 
   class="clsControl form-control" formControlName="phoneNumber" placeholder="Eg: 4251234567"/>
            <div class="text-danger" >
                <span class="text-capitalize">{{ validation.validateFormControl('phoneNumber','Phone Number',editParentDataForm) }}</span>
            </div>

        </div>

        <div class="col-md-3 col-12">
            <label for="alternatePhoneNumber" class="dark-ask">Alternative Phone Number</label>
            <input pInputText name="alternatePhoneNumber" id="alternatePhoneNumber" type="text"
            class="clsControl form-control" formControlName="alternatePhoneNumber"  placeholder="Eg: 4251234567" (keyup)="validatePhoneNumber()" 
                 />
            <div class="text-danger" >
                <span class="text-capitalize">{{ validation.validateFormControl('alternatePhoneNumber','Alternate Phone Number',editParentDataForm) }}</span>
                <span class="text-capitalize">{{ validatePhoneNumberError }}</span>
            </div>

        </div>
    </div>
    <div class="row px-md-3 pt-2">
        <div class="col-md-3 col-12">
            <label for="Country" class="dark-ask">Country <span class="text-danger">*</span></label>
            <p-dropdown name="Country" id="Country"                                                                                                                                                               
                        formControlName="Country"  class="dropdown d-block studentDetail_dropdown "  placeholder="Select Country" [options]="countryApiArray" (onChange)="changeDetails($event?.value?.id)" optionLabel="countryName"></p-dropdown>
            <div class="text-danger" >
                <span class="text-capitalize">{{ validation.validateFormControl('Country','Country',editParentDataForm) }}</span>
            </div>
        </div>
        <div class="col-md-3 col-12">
            <label for="State" class="dark-ask">State<span class="text-danger">*</span></label>
            <div *ngIf="this.storeStateApiArray?.length>0 ;else noState">
             <p-dropdown name="State" id="State"  
             formControlName="State"  class="dropdown d-block studentDetail_dropdown " placeholder="Select State" [options]="storeStateApiArray" optionLabel="stateName"></p-dropdown>
            </div>
            <ng-template #noState>
                 <input pInputText name="State"  id="State" type="text"
           class="clsControl form-control"   formControlName="State" placeholder="State" />
         </ng-template>
             <div class="text-danger" >
                 <span class="text-capitalize">{{ validation.validateFormControl('State','State',editParentDataForm) }}</span>
             </div>
       
        </div>
        <div class="col-md-3 col-12">

     <label for="City" class="dark-ask">Town/City<span class="text-danger">*</span></label>
                        <input pInputText name="City"  id="City" type="text"  
                        class="clsControl form-control "  formControlName="city" placeholder="Town/City"/>
                        <div class="text-danger" >
                            <span class="text-capitalize">{{ validation.validateFormControl('city','City',editParentDataForm) }}</span>
                        </div>

        </div>

        <div class="col-md-3 col-12">
            <label for="zipCode" class="dark-ask">Zip Code<span class="text-danger">*</span></label>
            <input  name="zipCode"   class="clsControl form-control " id="zipCode" type="text"  
            formControlName="zipCode" placeholder="Zip Code" />
            <div class="text-danger" >
                <span class="text-capitalize">{{ validation.validateFormControl('zipCode','Zip Code',editParentDataForm) }}</span>
            </div>

        </div>
    </div>

    <div class="row px-md-3 pt-2">
        <div class="col-12">
            <label for="address" class="dark-ask">Address (Area and Street) <span class="text-danger">*</span></label>
                        <textarea rows="5" cols="30"  class="clsControl form-control" 
                       formControlName="address" ></textarea>
                        <div class="text-danger" >
                            <span class="text-capitalize">{{ validation.validateFormControl('address','Address',editParentDataForm) }}</span>
                        </div>
        </div>
    </div>
   
    <div class="row">
        <div class="col-12 d-flex align-items-center p-4 m-md-1">
           <div class="p-2"> <button pButton pRipple type="submit" (click)="onSubmit(editParentDataForm.value)" class="btn-blue d-flex align-items-center justify-content-center"
            [ngClass]="{'px-5 text-center text-white': parentButton}">
            <span *ngIf="!parentButton" class="text-white">Save</span>
            <p-progressSpinner *ngIf="parentButton" class="custom-spinner" strokeWidth="3"
                animationDuration=".5s"></p-progressSpinner>
            </button></div>
          <div class="p-2"> 

             <button pButton pRipple type="submit" style="background-color: #A41034; border: 0.5px solid #962336;" class="btn btn-danger d-flex align-items-center justify-content-center" (click)=" rePrepopulate(this.registeredCoursesList?.[0])"
            [ngClass]="{'px-5 text-center text-white': parentButton}"> 
            <span  class="text-white">Reset</span>
            <!-- <p-progressSpinner *ngIf="parentButton" class="custom-spinner" strokeWidth="3"
                animationDuration=".5s"></p-progressSpinner>    -->
            </button>
        </div>
        </div>
    </div>

    </form>
 


    <div class="row pt-3 px-md-3">
        <div class="col-12">
            <!-- <p-accordion [activeIndex]="0">
            <p-accordionTab *ngFor="let data of registeredCoursesList[1]" [header]="data?.name">
                <form [formGroup]="editChildDataForm">
                    <div formArrayName="courses" >
                      <div *ngFor="let courseGroup of coursesFormArray.controls; let i = index" >
                        <div [formGroup]="courseGroup" class="row px-md-3 ">
                          <div *ngFor="let controlName of ['child name', 'child last name', 'age', 'child telugu']"  class="col-md-6 px-md-3 pt-md-2 pt-1  col-xs-12 ">
                            <label [for]="controlName + i" class="dark-ask d-block">{{ controlName | titlecase }} <span class="text-danger">*</span></label>
                            <input [formControlName]="controlName" [id]="controlName + i" [placeholder]="controlName | titlecase">
                            <div class="text-danger" >
                                <span class="text-capitalize">{{ validation.validateFormControl(controlName,controlName,courseGroup) }}</span>
                            </div>
                          </div>
                         
                        </div>
                      </div>
                    </div>
                   <div class="row p-3 ml-md-5">
                    <div class="col-12"> <button (click)="onSubmitChild(editChildDataForm,data.groupId)">Submit</button></div>
                   </div>
                  </form>
                  
         
            <div class="row px-md-3 pt-2">
                <div class="col-md-3 col-12">
                    <p class="dark-ask m-0">Previous Attended Center </p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="col-12 p-2 px-md-3" >
                        <h4 class="fw-bold">Registered Courses</h4>
                    </div>
                    <div class="col-12 p-2 px-md-3" >

                        <p-table [tableStyle]="{ 'min-width': '50rem' }" [value]="registeredCoursesList[1]">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th class="bg-white">S.No</th>
                                    <th class="bg-white">Type</th>
                                    <th class="bg-white">Location</th>
                                    <th class="bg-white">Category</th>
                                    <th class="bg-white">Course Name</th>
                                    <th class="bg-white">Timings</th>
                                    <th class="bg-white">Amount</th>
                                    <th class="bg-white">Payment Status</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-course let-rowIndex="rowIndex">
                                <tr *ngIf="registeredCoursesList.length > 0">
                                    <td>{{rowIndex + 1 }}</td>
                                    <td>{{ course.courseType }}</td>
                                    <td>{{ course.location }}</td>
                                    <td>{{ course.category }}</td>
                                    <td>{{ course.courseName }}</td>
                                    <td>{{ course.timings }}</td>
                                    <td>100</td>
                                    <td [class]="{'text-danger' :  course?.paymentStatus == 'InProcess', 'text-success':course?.paymentStatus == 'Completed'}">{{ course.paymentStatus }}</td>
                                </tr>
                                <tr *ngIf="registeredCoursesList.length == 0">
                                    <p>No Registered courses available</p>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
          
            </p-accordionTab>
            </p-accordion> -->

            <p-accordion>
                <ng-container *ngFor="let student of registeredCoursesList?.[0]?.students; let i = index">
                  <p-accordionTab [header]="student.studentName">
                    <form [formGroup]="coursesFormArray.at(i)" (ngSubmit)="onSubmitChild(coursesFormArray.at(i), student.id)">
                      <div class="row px-md-3">
                        <div *ngFor="let controlName of ['firstName', 'lastName', 'age', 'teluguName']" class="col-md-6 px-md-3 pt-md-2 pt-1 col-xs-12">
                          <label [for]="controlName + i" class="dark-ask d-block">{{ controlName | titlecase }} <span class="text-danger">*</span>
                          <span *ngIf="controlName === 'teluguName'"><i 
                            class="pi pi-info-circle d-inline-block m-2 blue-berry"
                            pTooltip="Paste Telugu name to show in your certificate"
                            tooltipPosition="top"></i></span>
                            <span  pTooltip="Navigate to google translator" *ngIf="controlName === 'teluguName'"
                            tooltipPosition="top"><svg style="height:20px; width:15px" class="cursor" (click)="navigateToTranslator(coursesFormArray.at(i).value.firstName)" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M22.401 4.818h-9.927L10.927 0H1.599C.72 0 .002.719.002 1.599v16.275c0 .878.72 1.597 1.597 1.597h10L13.072 24H22.4c.878 0 1.597-.707 1.597-1.572V6.39c0-.865-.72-1.572-1.597-1.572zm-15.66 8.68c-2.07 0-3.75-1.68-3.75-3.75 0-2.07 1.68-3.75 3.75-3.75 1.012 0 1.86.375 2.512.976l-.99.952a2.194 2.194 0 0 0-1.522-.584c-1.305 0-2.363 1.08-2.363 2.409S5.436 12.16 6.74 12.16c1.507 0 2.13-1.08 2.19-1.808l-2.188-.002V9.066h3.51c.05.23.09.457.09.764 0 2.147-1.434 3.669-3.602 3.669zm16.757 8.93c0 .59-.492 1.072-1.097 1.072h-8.875l3.649-4.03h.005l-.74-2.302.006-.005s.568-.488 1.277-1.24c.712.771 1.63 1.699 2.818 2.805l.771-.772c-1.272-1.154-2.204-2.07-2.89-2.805.919-1.087 1.852-2.455 2.049-3.707h2.034v.002h.002v-.94h-4.532v-1.52h-1.471v1.52H14.3l-1.672-5.21.006.022h9.767c.605 0 1.097.48 1.097 1.072v16.038zm-6.484-7.311c-.536.548-.943.873-.943.873l-.008.004-1.46-4.548h4.764c-.307 1.084-.988 2.108-1.651 2.904-1.176-1.392-1.18-1.844-1.18-1.844h-1.222s.05.678 1.7 2.61z" fill="#605DEC"></path></svg>
                        </span>
                    </label>
                          <input [formControlName]="controlName"     [pattern]="controlName === 'age' ? '^[0-9]+$' : controlName === 'firstName' || controlName === 'lastName' ? '^[a-zA-Z]+$' : ''"  [id]="controlName + i" [placeholder]="controlName | titlecase">
                          <div class="text-danger">
                            <span class="text-capitalize">{{ validation.validateFormControl(controlName, controlName, coursesFormArray.at(i)) }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 d-flex align-items-center p-4 m-md-1">
                            <button pButton pRipple type="submit" [disabled]="!coursesFormArray.at(i).valid" class="btn-blue d-flex align-items-center justify-content-center"
                            >
                            <span  class="text-white">Save</span>
                            </button>
                        </div>
                    </div>
                    </form>
                    <div class="row">
                        <div class="col-12">
                            <div class="col-12 p-2 px-md-3" >
                                <h4 class="fw-bold">Registered Courses- (Academic Year {{this.selectedAcademicYear?.name}})</h4>
                            </div>
                            <div class="col-12 p-2 px-md-3" >
        
                                <p-table [tableStyle]="{ 'min-width': '50rem' }" [value]="student.registeredCourses">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th class="bg-white">S.No</th>
                                            <th class="bg-white">Type</th>
                                            <th class="bg-white">Location</th>
                                            <th class="bg-white">Category</th>
                                            <th class="bg-white">Course Name</th>
                                            <th class="bg-white">Year</th>
                                            <th class="bg-white">Timings</th>
                                            <th class="bg-white">Amount</th>
                                            <th class="bg-white">Payment Status</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-course let-rowIndex="rowIndex">
                                        <tr *ngIf="registeredCoursesList?.length > 0">
                                            <td>{{rowIndex + 1 }}</td>
                                            <td>{{ course.courseType }}</td>
                                            <td>{{ course.location }}</td>
                                            <td>{{ course.category }}</td>
                                            <td>{{ course.courseName }}</td>
                                            <td>{{ course.enrolledOn | date:'yyyy' }}</td>
                                            <td>{{ course.timings }}</td>
                                            <td>{{ course.amount  }}</td>
                                            <td [class]="{'text-danger' :  course?.paymentStatus == 'InProcess', 'text-success':course?.paymentStatus == 'Completed'}">{{ course.paymentStatus }}</td>
                                        </tr>
                                        <tr *ngIf="registeredCoursesList?.length == 0">
                                            <p>No Registered courses available</p>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </div>
                  </p-accordionTab>
                </ng-container>
              </p-accordion>
              
        </div>

    </div>

</div>

</div>

<p-confirmDialog
key="positionDialog"
position="bottom-center"
rejectButtonStyleClass="p-button-outlined"
></p-confirmDialog>

<!-- <p-confirmDialog key="confirmationMsg" #rcd [style]="{width: '50vw'}">
    <ng-template pTemplate="header">
        <h3 class="text-dark fw-semibold mb-4">Refund</h3>
    </ng-template>
    <ng-template pTemplate="footer">
        <button type="button" pButton   class="btn-blue " label="OK" (click)="rcd.accept()"></button>
        <a (click)="rcd.reject()"><span
            class="text-danger d-inline-block p-3">Cancel</span></a>
    </ng-template>
  </p-confirmDialog> -->