import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CourseDetailsService } from 'src/app/services/course-details.service';
import { EnrollmentsService } from 'src/app/services/enrollments.service';
import { HomeService } from 'src/app/services/home.service';
import { LoggingService } from 'src/app/services/logging.service';
import { ParentDetailsService } from 'src/app/services/parent-details.service';
import { ValidationService } from 'src/app/services/validation.service';
import { parentDetails } from '../registration/registration-forms/parent-details/parent-details';
import { SharedService } from 'src/app/services/shared.service';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';

@Component({
  selector: 'app-parent-details-edit',
  templateUrl: './parent-details-edit.component.html',
  styleUrls: ['./parent-details-edit.component.css']
})
export class ParentDetailsEditComponent implements OnInit {
userData:any;
validatePhoneNumberError: string = "";
validateEmailError: string = "";
registeredCoursesList:any
countryApiArray: any;
childData:any;
parentData:any
  editParentDataForm!: FormGroup;
  editChildDataForm!:FormGroup;
  loader:boolean=false;
  parentButton:boolean=false;
  coursesFormArray: any;
  stateApiArray:any=[];
  storeStateApiArray:any=[];
  selectedAcademicYear: any;
  academicYearsList: any;
  constructor(  private fb: FormBuilder,
    private sharedService: SharedService,
    private courseDetails: CourseDetailsService,
    private parentDetails: ParentDetailsService,
    private appinsightsService: LoggingService,
    public validation: ValidationService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService, private enrollmentService: EnrollmentsService,
    private router: Router,    private homeDetails: HomeService,private errorHandling: ErrorHandlingService) {  }

  ngOnInit(): void {
    this.parentData = JSON.parse(localStorage.getItem('userData') || '{}')
 this.getState()
 this.getCountries()
 this.getAcademicYears()
    this.editParentDataForm = this.fb.group({
      email: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
        ])
      ),
      Country: new FormControl(
        null,
        Validators.compose([
          Validators.required,
        ])
      ),
      State: new FormControl(
        null,
        Validators.compose([
          Validators.required,
        ])
      ),
      zipCode: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(10)
        ])
      ),
      city: new FormControl(
        null,
        Validators.compose([
          Validators.required,
        ])
      ),
      alternateEmail: new FormControl(
        null,
        Validators.compose([
          Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
        ])
      ),
      firstName: new FormControl(
        null,
        Validators.compose([Validators.required, Validators.maxLength(150), Validators.pattern('^[^a-zA-Z]*[a-zA-Z]+.*$')])
      ),
      lastName: new FormControl(
        null,
        Validators.compose([Validators.required, Validators.maxLength(150),Validators.pattern('^[^a-zA-Z]*[a-zA-Z]+.*$')])
      ),
      phoneNumber: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('[0-9]{3}[0-9]{3}[0-9]{4}'),
        ])
      ),
      alternatePhoneNumber: new FormControl(
        null,
        Validators.compose([Validators.pattern('[0-9]{3}[0-9]{3}[0-9]{4}')])
      ),
      address: new FormControl(null, Validators.compose([Validators.required, Validators.maxLength(255)])),
    })
    this.getDetails()
// Initialize form and FormArray
this.editChildDataForm = this.fb.group({
  courses: this.fb.array([]) 
});
this.coursesFormArray = this.editChildDataForm.get('courses') as FormArray;

// Populate form with existing data


    this.userData = JSON.parse(localStorage.getItem("user") || '{}'); // Parse the user data from local storage
    this.homeDetails.isUserLoggedIn.subscribe((isLoggedIn: boolean) => {
      if (isLoggedIn && localStorage.getItem('token')) {
        this.getCountries()
      }
    });
    
  }

  getCoursesFormControls() {
    return (this.editChildDataForm.get('courses') as FormArray).controls;
  }
  

 async onSubmitChild(form: FormGroup, x:any) {
    const course = form.value;
    try{
        let userObj =course
          await this.validation.decodeLoggedInToken();
          await this.enrollmentService.updateStudentDetails(userObj,x).subscribe((data)=>{
            this.parentButton =false;
            if(data.statusCode == 200){   
            this.messageService.add({
              severity:'success', 
              summary: 'Success', 
              detail: 'Details saved successfully',
              sticky: true
            });
            this.getDetails()
          }
        },(error:any)=>{
          this.appinsightsService.logException(error,1);
          this.appinsightsService.logEvent("Error: Getting error in upsertParent api", {  payload: JSON.stringify(userObj),error:JSON.stringify(error)})
          if(error.status == 401){
            this.sharedService.token_Expired.next(true);
            localStorage.clear()
           this.confirmationService.confirm({
             message: 'Apologies, but your session has timed out. To proceed, please log in again.',
             header: 'Confirmation',
             icon: 'pi pi-exclamation-triangle',
             key:'toastMsg',
             accept: () => {
              localStorage.clear()
              this.homeDetails.isUserLoggedIn.next(false);
              this.editParentDataForm.reset();
             },
           })
         }
         else if(error.status == 404){
          this.messageService.add({
            severity:'warn',
            summary: 'Warning',
            detail: error.message
          });
         }
         else if(error.status == 500){
           this.messageService.add({
             severity:'warn',
             summary: 'Warning',
             detail: "Something went wrong at server side!"
           });
         }
         else{
           this.messageService.add({
             severity:'warn',
             summary: 'Warning',
             detail: error.message
           });
         }
       })
        
        
      
    }
    catch(error:any){
      this.appinsightsService.logException(error,2);
    }
  } 
  navigateToTranslator(x:any){
    window.open(`https://translate.google.com/?sl=auto&tl=te&text= ${x}`, "_blank");
  }
  getAcademicYears() {
    try {
      this.loader = true;
      this.courseDetails.getAcademicYears().subscribe((data: any) => {
        this.loader = false;
        this.academicYearsList = data.result;
        this.academicYearsList.forEach((item: any) => {
          if (item.isRegistrationsOpen == true) {
            this.selectedAcademicYear = item;
          }
        });
        // this.getCategoriesDetails(null, this.selectedAcademicYear.id);
      },(error: any) => {
        this.loader = false;
        this.appinsightsService.logException(error, 1);
        this.appinsightsService.logEvent(
          'Error: Getting error in change course api',
          { error: JSON.stringify(error) }
        );
        this.errorHandling.ErrorHandling(error);
      });
      
      
    } catch (error: any) {
      this.appinsightsService.logException(error);
    }
  }
  changeDetails(id:any){
    const state = this.editParentDataForm.controls['State']
    const zipCode = this.editParentDataForm.controls['zipCode']
    const city = this.editParentDataForm.controls['city']
    const address = this.editParentDataForm.controls['address']  
    state.reset();
    zipCode.reset();
    city.reset();
    address.reset();
    this.storeStateApiArray = this?.stateApiArray?.filter((x:any)=>x.countryId==id)
    // this.stateApiArray = this.storeStateApiArray
  }
  // onSubmitChild(form: FormGroup, x:any) {
  //   const formData = form.value;
    
  //   console.log(formData);
  async onSubmit(formdata: any) {

    try{
      if (this.editParentDataForm.valid) {
      if(this.editParentDataForm.touched){
        let userObj={
          "firstName": formdata.firstName,
          "lastName": formdata.lastName,
          "email": formdata.email,
          "alternateEmail": formdata.alternateEmail,
          "phone": formdata.phoneNumber,
          "alternatePhone": formdata?.alternatePhoneNumber,
          "address": formdata?.address,
          "countryId": formdata?.Country.id?formdata.Country?.id:this.editParentDataForm.controls['Country'].value.id,
          "stateName": formdata.State?.stateName?formdata.State?.stateName:formdata.State ,
          "city": formdata?.city,
          "pinCode": formdata?.zipCode
        }
          await this.validation.decodeLoggedInToken();
            
          this.parentButton = true
          this.confirmationService.confirm({
            
            message: 'If you\'ve already taken a course this year, remember that any books on the way will still be sent to your old address.',
            header: 'Confirmation',
            icon: 'pi pi-info-circle',
            acceptIcon:"none",
            rejectIcon:"none",
            rejectButtonStyleClass:"p-button-text",
            accept: () => {
              
                // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Resuming the payment procedure' });
             this.enrollmentService.updateParentDetails(userObj,this.parentData.id).subscribe((data)=>{
                  this.parentButton =false;
                  if(data.statusCode == 200){
                 
                  this.messageService.add({
                    severity:'success', 
                    summary: 'Success', 
                    detail: 'Details saved successfully',
                    sticky: true
                  });
                  this.editParentDataForm.reset();
                  this.getDetails()
               
                }
              },(error:any)=>{
                this.parentButton =false;
                this.appinsightsService.logException(error,1);
                this.appinsightsService.logEvent("Error: Getting error in upsertParent api", {  payload: JSON.stringify(userObj),error:JSON.stringify(error)})
                if(error.status == 401){
                  this.sharedService.token_Expired.next(true);
                  localStorage.clear()
                 this.confirmationService.confirm({
                   message: 'Apologies, but your session has timed out. To proceed, please log in again.',
                   header: 'Confirmation',
                   icon: 'pi pi-exclamation-triangle',
                   key:'toastMsg',
                   accept: () => {
                    localStorage.clear()
                    this.homeDetails.isUserLoggedIn.next(false);
                    this.editParentDataForm.reset();
                   },
                 })
               }
               else if(error.status == 404){
                this.messageService.add({
                  severity:'warn',
                  summary: 'Warning',
                  detail: error.message
                });
               }
               else if(error.status == 500){
                 this.messageService.add({
                   severity:'warn',
                   summary: 'Warning',
                   detail: "Something went wrong at server side!"
                 });
               }
               else{
                 this.messageService.add({
                   severity:'warn',
                   summary: 'Warning',
                   detail: error.message
                 });
               }
             })
              
            },
            reject: () => {
                // this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Payment Process Rejected', life: 3000 });
                this.parentButton =false;
            },
            key: 'positionDialog'
        });
      }
      } else {
        this.parentButton =false;
        for (const field in this.editParentDataForm.controls) {
          this.editParentDataForm.controls[field].markAsDirty();
        }
      }
    }
    catch(error:any){
      this.parentButton =false;
      this.appinsightsService.logException(error,2);
    }
  }



  
  validateAlternateEmail(){
    if(this.editParentDataForm['controls']['email'].value == this.editParentDataForm['controls']['alternateEmail'].value && this.editParentDataForm['controls']['alternateEmail'].dirty){
      this.editParentDataForm['controls']['alternateEmail'].setErrors({'incorrect': true});
      this.validateEmailError = "Email and alternate Email cannot be same"
    }
    else{
      this.validateEmailError = ""
    }
  }

  validatePhoneNumber(){
    if(this.editParentDataForm.controls['phoneNumber'].value == this.editParentDataForm?.controls['alternatePhoneNumber']?.value && this?.editParentDataForm.controls['alternatePhoneNumber'].dirty)
    {
      this.editParentDataForm['controls']['alternatePhoneNumber'].setErrors({'incorrect': true});
       this.validatePhoneNumberError ="Phone number and alternate phone number cannot be same"
    }
    else{
      this.validatePhoneNumberError = ""
    }
  }
  getCountries(){
    try{
      this.loader = true;
      this.courseDetails.getCountry().subscribe((data)=>{
        this.loader = false;
       this.countryApiArray=data?.result;
       this.prepopulateParentDetails(this?.parentData);
      },(error:any)=>{
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: Getting error in get all courses Country list api", {error:JSON.stringify(error)})
      })
    }
    catch(error:any){
      this.appinsightsService.logException(error,2)
    }
  }
  async getState(){
    try{
      this.loader = true;
      let token = JSON.parse(localStorage.getItem('token')|| 'null')
  await this.parentDetails.getStates().subscribe((data)=>{
        this.loader = false;
       this.stateApiArray=data?.result;     
       this.storeStateApiArray = this?.stateApiArray;

      },(error:any)=>{
        if(error.status == 401){
          localStorage.clear()
          this.confirmationService.confirm({
            message: 'Apologies, but your session has timed out. To proceed, please log in again.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key:'toastMsg',
            accept: () => {
             localStorage.clear()
  
            },
          })
        }
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: Getting error in get all courses Country list api", {error:JSON.stringify(error)})
      })
    }
    catch(error:any){
      this.appinsightsService.logException(error,2)
    }
  }

  getDetails(){
    try{
      this.loader = true;
  this.enrollmentService.getParentDetails(this.parentData?.id).subscribe((data)=>{
        this.loader = false;
        this.registeredCoursesList=[data?.result];
        this.parentData =  this?.registeredCoursesList?.[0];
        this.prepopulateParentDetails(this?.registeredCoursesList?.[0]);
        this.registeredCoursesList[0]?.students?.forEach((student: any) => {
          const group = this.fb.group({
            'firstName': [student?.firstName, Validators.required],
            'lastName': [student?.lastName, Validators.required],
            'age': [student?.age, Validators.required],
            'teluguName': [student?.teluguName, Validators.required]
          });
          this?.coursesFormArray?.push(group);
        });
    

      },(error:any)=>{
        this.parentData = JSON.parse(localStorage.getItem('userData') || '{}')
        this.prepopulateParentDetails(this.parentData);
        if(error.status == 401){
          localStorage.clear()
          this.confirmationService.confirm({
            message: 'Apologies, but your session has timed out. To proceed, please log in again.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key:'toastMsg',
            accept: () => {
             localStorage.clear()
  
            },
          })
        }
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: Getting error in get all courses Country list api", {error:JSON.stringify(error)})
      })
    }
    catch(error:any){
      this.appinsightsService.logException(error,2)
    }
  }
  rePrepopulate(userData:any){
    this.prepopulateParentDetails(userData)
  }
  prepopulateParentDetails(userData:any){
    try{
      const email = this.editParentDataForm?.controls['email'];
      const alternateEmail = this.editParentDataForm?.controls['alternateEmail'];
      const firstName = this.editParentDataForm?.controls['firstName'];
      const lastName = this.editParentDataForm?.controls['lastName'];
      const phoneNumber = this.editParentDataForm?.controls['phoneNumber'];
      const alternatePhoneNumber = this.editParentDataForm?.controls['alternatePhoneNumber'];
      const address = this.editParentDataForm?.controls['address'];
      const country = this.editParentDataForm?.controls['Country'];
      const state = this.editParentDataForm?.controls['State'];
      const city = this.editParentDataForm?.controls['city'];
      const zipCode = this.editParentDataForm?.controls['zipCode'];
      let SelectedCountry = this.countryApiArray?.filter((x:any)=>(x?.id == userData?.countryId))
      country.patchValue(SelectedCountry[0])
      zipCode.patchValue(userData?.pinCode)
      city.patchValue(userData?.city)
      if(userData.stateId){
        this.storeStateApiArray  =  this?.stateApiArray?.filter((x:any)=>(x?.countryId ==userData?.countryId))
        let selected_state = this?.stateApiArray?.filter((x:any)=>(x?.id==userData?.stateId))
       
        state.patchValue(selected_state[0])
      }else{
        this.storeStateApiArray = 0
        state.patchValue(userData?.state)
      }
     
      const emailid: any  = (localStorage.getItem('email'));
      let emailAddress =  emailid? JSON?.parse(emailid): "";
      email.patchValue(emailAddress);
      alternateEmail.patchValue(userData?.alternateEmail);
      firstName.patchValue(userData?.firstName);
      lastName.patchValue(userData?.lastName);
      phoneNumber.patchValue(userData?.phone);
      alternatePhoneNumber.patchValue(userData?.alternatePhone);
      address.patchValue(userData?.address);
    }
    catch(error:any){
      this.appinsightsService.logException(error,2);
    }
  }
}
