import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { InputSwitchModule } from 'primeng/inputswitch';
import { StepsModule } from 'primeng/steps';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { TableModule } from 'primeng/table';
import { RadioButtonModule } from 'primeng/radiobutton';
import { HttpClientModule } from '@angular/common/http';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TabViewModule } from 'primeng/tabview';
import { AccordionModule } from 'primeng/accordion';
import { GalleriaModule } from 'primeng/galleria';
import { CheckboxModule } from 'primeng/checkbox';
import { EditorModule } from 'primeng/editor';
import { CardModule } from 'primeng/card';
import { InputNumberModule } from 'primeng/inputnumber';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { routing } from './app-routing';
import { RegistrationComponent } from './components/registration/registration.component';
import { ParentDetailsComponent } from './components/registration/registration-forms/parent-details/parent-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StudentDetailsComponent } from './components/registration/registration-forms/student-details/student-details.component';
import { CourseDetailsComponent } from './components/registration/registration-forms/course-details/course-details.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AllCourseDetailsComponent } from './components/all-course-details/all-course-details.component';
import { PaymentDetailsComponent } from './components/registration/registration-forms/payment-details/payment-details.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { ConctacUsComponent } from './components/conctac-us/conctac-us.component';
import { JoinUsComponent } from './components/join-us/join-us.component';
import { DonateComponent } from './components/donate/donate.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { FaqComponent } from './components/faq/faq.component';
import { LoginComponent } from './components/login/login.component';
import { EnrollmentsComponent } from './components/enrollments/enrollments.component';
import { CourseManagementComponent } from './components/course-management/course-management.component';
import { EnrollmentDetailViewComponent } from './components/enrollment-detail-view/enrollment-detail-view.component';
import { EventsComponent } from './components/events/events.component';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { AdminViewComponent } from './components/admin-view/admin-view.component';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { ParentDetailsEditComponent } from './components/parent-details-edit/parent-details-edit.component';



@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    RegistrationComponent,
    ParentDetailsComponent,
    StudentDetailsComponent,
    CourseDetailsComponent,
    AllCourseDetailsComponent,
    PaymentDetailsComponent,
    CalendarComponent,
    ConctacUsComponent,
    JoinUsComponent,
    DonateComponent,
    TermsAndConditionsComponent,
    FaqComponent,
    LoginComponent,
    EnrollmentsComponent,
    CourseManagementComponent,
    EnrollmentDetailViewComponent,
    EventsComponent,
    AdminViewComponent,
    UserManagementComponent,
    ParentDetailsEditComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    StepsModule,
    CalendarModule,
    ReactiveFormsModule,
    InputTextareaModule,
    ConfirmPopupModule ,
    ButtonModule,
    DialogModule,
    InputSwitchModule,
    BrowserAnimationsModule,
    DropdownModule,
    TooltipModule,
    TableModule,
    RadioButtonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ProgressSpinnerModule,
    ToastModule,
    ConfirmDialogModule,
    GalleriaModule,
    MultiSelectModule,
    TabViewModule,
    AccordionModule,
    CheckboxModule,
    EditorModule,
    CardModule,
    InputNumberModule,
    routing,
  ],
  providers: [MessageService,ConfirmationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
