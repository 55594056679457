import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { requestHeaders } from './requestHeaders';

const baseUrl = environment.apiEndpoint;

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  constructor(private http: HttpClient) { }
 public token_Expired: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
 
  get(url:string, params?: any): Observable<any> {
    const options = {
      headers: this.getHeaders(), // Pass the requestHeader in the options
      params: params // If you have query parameters, you can pass them here
    };
    return this.http.get<any>(baseUrl + url, options);
  }
  post(url:string, body?:any): Observable<any> {
    const options = {
      headers: this.getHeaders(),  // Pass the requestHeader in the options
    };
    return this.http.post<any>(baseUrl + url, body,options);
  }

  patch(url:string, body:any): Observable<any> {
    return this.http.patch<any>(baseUrl + url, body);
  }

  put(url:string, body:any): Observable<any> {
    const options = {
      headers: this.getHeaders(),  // Pass the requestHeader in the options
    };
    return this.http.put<any>(baseUrl + url, body, options);
  }

  delete(url:string, params?: any): Observable<any> {
    return this.http.delete<any>(baseUrl + url, params);
  }

  getHeaders(){
   const value: any  = (localStorage.getItem('token'));
   let token =  value? JSON.parse(value): "";
   let requestHeader = new HttpHeaders({'Content-Type': 'application/json','Authorization': `Bearer ${token}`});
   return requestHeader
  }
}
