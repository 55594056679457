<div class="container-fluid">
    <p-toast position="top-center"></p-toast>
    <div class="row">
        <div class="col-12 text-center  py-3">
            <h4 class="fw-semibold m-0">Contact Us</h4>
        </div>
    </div>
    <div class="row px-md-5 mx-md-5">
        <div class="col-12">
            <form [formGroup]="contactusForm" (ngSubmit)="onSubmit(contactusForm.value)">
                <div class="row p-4 rounded bg-white card_view shadow">
                    <div class=" col-md-6 p-2 px-md-5">
                        <label for="fullname" class="dark-ask">Full Name<span class="text-danger"> *</span></label>
                        <input pInputText name="fullname" id="fullname" type="text" class="clsControl form-control"
                            formControlName="fullname" />
                        <div class="text-danger">
                            <span class="text-capitalize">{{ validation.validateFormControl('fullname','Full Name',contactusForm) }}</span>
                        </div>
                    </div>
                    <div class="col-md-6 p-2 px-md-5">
                        <label for="phonenumber" class="dark-ask">Phone Number<span class="text-danger">
                                *</span></label>
                        <input pInputText name="phonenumber" id="fullname" type="text" class="clsControl form-control"
                            formControlName="phonenumber" placeholder="Eg: 4251234567" />
                        <div class="text-danger">
                            <span class="text-capitalize">{{ validation.validateFormControl('phonenumber','Phone Number',contactusForm) }}</span>
                        </div>
                    </div>
                    <div class="col-md-6 p-2 px-md-5">
                        <label for="email" class="dark-ask">E-Mail<span class="text-danger"> *</span></label>
                        <input pInputText name="email" id="email" type="text" class="clsControl form-control"
                            formControlName="email" placeholder="Eg: abc@gmail.com" />
                        <div class="text-danger">
                            <span class="text-capitalize">{{validation.validateFormControl('email','Email',contactusForm) }}</span>
                        </div>
                    </div>
                    <div class=" col-md-6 p-2 px-md-5">
                        <label for="category" class="dark-ask">Category<span class="text-danger"> *</span></label>
                        <p-dropdown name="category" id="category" placeholder="Select a Category"[options]="categoryList" optionLabel="label" 
                         formControlName="category" class="d-block dropdown"></p-dropdown>
                         <div class="text-danger">
                            <span class="text-capitalize">{{ validation.validateFormControl('category','Category',contactusForm) }}</span>
                        </div>
                    </div>
                    <div class="col-12 p-2 px-md-5">
                        <label for="comment" class="dark-ask">Comment<span class="text-danger"> *</span></label>                     
                        <textarea name="comment" id="comment" type="text" class="clsControl form-control"
                        formControlName="comment" rows="5" cols="30"></textarea>
                        <div class="text-danger">
                            <span class="text-capitalize">{{ validation.validateFormControl('comment','Comment',contactusForm) }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 p-3 text-end">
                            <a (click)="reset()"><span
                                    class="text-danger d-inline-block p-3 cursor">Reset</span></a>
                            <button pButton pRipple type="submit" label="Submit"
                                class="btn-blue  btn-lg"></button>
                            <p-progressSpinner *ngIf="loader" class="custom-spinner"  strokeWidth="3"
                                animationDuration=".5s" ></p-progressSpinner>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <hr>
    <div class="row px-md-5 mx-md-5">
        <div class="col-12 text-center p-2">
            <h4 class="fw-semibold">Locations</h4>
        </div>
        <div class="col-12 p-2">
            <div class="row">
                <div class="col-12 col-md-4 p-2" *ngFor="let branch of branchesData">
                    <p-card class="shadow">
                            <div>
                                <h5 class="color-dark fw-bold">{{branch?.name}}</h5>
                            </div>
                             <p class="m-0">
                                {{branch?.address}}
                             </p>
                             <i class="pi pi-map-marker shadow text-white btn-blue rounded p-2 mt-2 cursor text-center" (click)="navigateToLocation(branch?.bingMapLink)" *ngIf="branch?.bingMapLink"></i>
                    </p-card>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-center p-2 pb-0">
            <h4 class="fw-semibold">Email Us</h4>
            <a href="mailto:info@ManaSamskriti.org" class="text-decoration-underline text-black">info&#64;ManaSamskriti.org</a>
        </div>
    </div>
</div>