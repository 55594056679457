import { Injectable } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { HomeService } from './home.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {

  constructor(  private router: Router,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,private homeDetails: HomeService,) { }
  ErrorHandling(res:any)
  {
    if(res.status == 401)
    {
      this.confirmationService.confirm({
        message: 'Apologies, but your session has timed out. To proceed, please log in again.',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        key:'toastMsg',
        accept: () => {
         localStorage.clear()
         this.homeDetails.isUserLoggedIn.next(false);
        //phase 2  this.router.navigate(['/login']);
        this.router.navigate(['/registration/parent-details']);
        },
      })
    }
    else if(res.status == 400)
    {
      this.messageService.add({
        severity:'warn',
        summary: 'Warning',
        detail: res.error.responseException,
  })
    }
    else if(res.status ==500)
    {
      this.messageService.add({
        severity:'warn',
        summary: 'Warning',
        detail: "Something went wrong at server side!"
      });
    }
    else if(res.status ==404)
    {
      this.messageService.add({
        severity:'warn',
        summary: 'Warning',
        detail: res.error.title
      });
    }
    else
    {
      this.messageService.add({
        severity:'warn',
        summary: 'Warning',
        detail: res.message
      });
    }

  }
}
