import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';
import { map } from 'rxjs';
import { student } from '../components/registration/registration-forms/student-details/student-details';

@Injectable({
  providedIn: 'root'
})
export class StudentDetailsService {

  constructor(private http: SharedService) { }

  
  getStudentDetails(){
    let url = 'api/Students';
    return this.http.get(url)
    .pipe(map((data: any) => data));
  }

  addStudent(studentData:student){
    let url = 'api/Students';
    return this.http.post(url,studentData).pipe(map(data => data))  
  }

  updateStudent(studentData:student, studentId:number){
    let url = `api/Students/${studentId}`;
    return this.http.put(url,studentData).pipe(map(data => data)) 
  }
}
