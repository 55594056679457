import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CourseDetailsService } from 'src/app/services/course-details.service';
import { HomeService } from 'src/app/services/home.service';
import { LoggingService } from 'src/app/services/logging.service';
import { SharedService } from 'src/app/services/shared.service';
import { StudentDetailsService } from 'src/app/services/student-details.service';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'app-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.css']
})
export class CourseDetailsComponent implements OnInit {
  addCourseDetails: FormGroup;
  termsAndConditions: FormGroup;
  courseTypeArray = [{"id":"2","label":"In-Person"},{"id":"1","label":"Online"}];
  locationsArray:any;
  selectedCourseType:any;
  selectedLocation:any;
  storeLocation:any=null;
  showLocations: boolean = false;
  categoryList:any;
  coursesList:any;
  timings:any;
  completeCourseDetails:any;
  filteredCompleteData:any;
  addedCoursesList:any;
  totalAmount:number = 0;
  selectedStudentName: string = "";
  studentsList:any;
  showAddressFlag:boolean = false
  showAddress:any=null;
  registeredCourses:any;
  preFerredLocation:boolean=false;
  storeSelectedCountry:any;
  countryId:any;
  duplicateEntry: boolean = false;
  duplicateEntryText: string = "";
  selectedCountry:any;
  selectedAcademicYear: any;
  academicYearsList: any;
  shippingAmount=0
  countryApiArray: any;
  storeCountryApiArray:any
  loader: any;
  validateTypeofCourse:any
  constructor(private fb: FormBuilder,
              private homeDetails: HomeService,
              private router: Router,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              private studentDetails: StudentDetailsService, 
              private courseDetails: CourseDetailsService,
              private appinsightsService: LoggingService,
              private sharedService: SharedService,
              public validation: ValidationService) {
    this.addCourseDetails = this.fb.group({
      courseType: new FormControl(null, Validators.compose([Validators.required])),
      category: new FormControl(null, Validators.compose([Validators.required])),
      coursename:  new FormControl(null, Validators.compose([Validators.required])),
      timings: new FormControl(null, Validators.compose([Validators.required])),
      locationName: new FormControl(null),
      amount: new FormControl(null),
      shippingAmount: new FormControl(null),
      groupId: new FormControl(null),
      courseCategoryName: new FormControl(null),
      Country:new FormControl(null,Validators.compose([Validators.required])),
    });
    this.termsAndConditions =  this.fb.group({
      terms: new FormControl(null, Validators.compose([Validators.required])),
    })
   }
  verifyCoursesList:any;
  CourseAvailable:boolean=true;

  ngOnInit(): void {
    try{
      window.scrollTo(0,0) 
      this.getCountries()
      this.appinsightsService.logEvent('course-details form viewed')
      this.addedCoursesList=[];
    
      let name: any  = (localStorage.getItem('selectedStudentName'));
      this.selectedStudentName =  name? JSON.parse(name): [];
      // this.courseDetails.country.subscribe((data:any)=>{
      //   if(data!=undefined||null){
      //     this.countryId = data
      //   }else{
      //     this.countryId=null
      //   }
      // })
      this.countryId = JSON.parse(localStorage.getItem('CountryID')||'1')
      if(localStorage.getItem("addedCourses")){
        const value: any  = (localStorage.getItem('addedCourses'));
        this.addedCoursesList =  value? JSON.parse(value): [];
        this.calculateAmount();
      }
      this.validateTypeofCourse =  JSON.parse(localStorage.getItem('cityFlag')|| 'null')
        this.selectedCourseType = this.courseTypeArray[0]
     
      this.getAcademicYears();
      this.getRegisteredCourses();

    }
    catch(error:any){
      this.appinsightsService.logException(error,2)
    }
  }
  storeFilterList:any
  onSelectedCountry(countryID:any){
    const locationName = this.addCourseDetails.controls['locationName']
    this.selectedCountry = countryID;
    this.showAddress=null
    if(this.selectedCourseType?.id == 1){
      this.showAddress=null;
      this.storeLocation = null
      this.showLocations = false;
      locationName.clearValidators();
      this.reset()
      this.storeFilterList = this.completeCourseDetails?.onlineCourses
      // this.storeFilterList = this.completeCourseDetails?.
      // onlineCourses.filter((x:any)=>x.countryId == this.selectedCountry)
      this.filteredCompleteData = this.storeFilterList;
      this.filterCategoryBasedOnType(this.filteredCompleteData)
    }
    else{  
      this.showLocations = true;
      locationName.setValidators(Validators.required)
      this.reset();
      this.storeFilterList  = this.completeCourseDetails?.offlineCourses.filter((x:any)=>x.countryId == this.selectedCountry)
      this.filteredCompleteData =  this.storeFilterList;
      this.filterLocationsBasedOnType(this.filteredCompleteData)
    }
   }
   

  async getCountries(){
    try{
      this.loader = true;
     await this?.courseDetails.getCountry().subscribe((data)=>{
        this.loader = false;
       this.countryApiArray=data?.result;
       this.storeCountryApiArray = this?.countryApiArray
    //  this.storeSelectedCountry = this?.countryApiArray.filter((x:any)=>x.id=== this.countryId)
    //    this.addCourseDetails.controls['Country'].patchValue(this.storeSelectedCountry[0])
      },(error:any)=>{
        this.loader = false;
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: Getting error in get all courses Country list api", {error:JSON.stringify(error)})
      })
    }
    catch(error:any){
      this.loader = false;
      if(error.status == 401){
        this.sharedService.token_Expired.next(true);
        localStorage.clear()
        this.confirmationService.confirm({
          message: 'Apologies, but your session has timed out. To proceed, please log in again.',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          key:'toastMsg',
          accept: () => {
           localStorage.clear()
           this.homeDetails.isUserLoggedIn.next(false);
           this.reset();
          },
        })
      }
      else if(error.status == 404){
       this.messageService.add({
         severity:'warn',
         summary: 'Warning',
         detail: error.message
       });
      }
      else if(error.status == 500){
        this.messageService.add({
          severity:'warn',
          summary: 'Warning',
          detail: "Something went wrong at server side!"
        });
      }
      else{
        this.messageService.add({
          severity:'warn',
          summary: 'Warning',
          detail: error.message
        });
      }
      this.appinsightsService.logException(error,2)
    }
  }
  onCourseTypeSelection(){
    try{
      this.storeSelectedCountry = this?.countryApiArray.filter((x:any)=>x.id=== this.countryId)
      this.addCourseDetails.controls['Country'].patchValue(this.storeSelectedCountry[0])
      this.CourseAvailable = true;
      this.duplicateEntry = false;
      const locationName = this.addCourseDetails.controls['locationName']
      if(this.selectedCourseType?.id == 1){
        this.showAddress=null;
        this.storeLocation = null
        this.showLocations = false;
        locationName.clearValidators();
        this.reset()
        this.storeFilterList = this.completeCourseDetails?.onlineCourses
        // this.storeFilterList = this.completeCourseDetails?.
        // onlineCourses.filter((x:any)=>x.countryId == this.selectedCountry)
        this.filteredCompleteData = this.storeFilterList;
        this.filterCategoryBasedOnType(this.filteredCompleteData)
      }
      else{  
        this.showLocations = true;
        locationName.setValidators(Validators.required)
        this.reset();
        this.storeFilterList  = this.completeCourseDetails?.offlineCourses.filter((x:any)=>x.countryId == this.selectedCountry)
        this.filteredCompleteData =  this.storeFilterList;
        this.filterLocationsBasedOnType(this.filteredCompleteData)
      }
      locationName.updateValueAndValidity();
    }
    catch(error:any){
      this.appinsightsService.logException(error,2)
    }
  }
  async getCoursesList(academicId:any,countryId:any){
    try{
      this.loader = true;
      await this.validation.decodeLoggedInToken();
      await this.courseDetails.getCourseDetails(academicId,countryId).subscribe((data)=>{
      this.loader = false;
        if(data.statusCode == 200){
            this.completeCourseDetails = data.result;
        }
        this.onCourseTypeSelection()
      },(error:any)=>{
        this.loader = false;
        this.appinsightsService.logException(error,1);
        this.appinsightsService.logEvent("Error: Getting error in get course list api", {error:JSON.stringify(error)})
        if(error.status == 401){
          localStorage.clear()
          this.sharedService.token_Expired.next(true);
          this.confirmationService.confirm({
            message: 'Apologies, but your session has timed out. To proceed, please log in again.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key:'toastMsg',
            accept: () => {
             localStorage.clear()
             this.homeDetails.isUserLoggedIn.next(false);
             this.reset();
            },
          })
        }
        else if(error.status == 404){
         this.messageService.add({
           severity:'warn',
           summary: 'Warning',
           detail: error?.error?.title
         });
        }
        else if(error.status == 400){
          this.messageService.add({
            severity:'warn',
            summary: 'Warning',
            detail: error?.error?.title
          });
         }
        else if(error.status == 500){
          this.messageService.add({
            severity:'warn',
            summary: 'Warning',
            detail: "Something went wrong at server side!"
          });
        }
        else{
          this.messageService.add({
            severity:'warn',
            summary: 'Warning',
            detail: error?.error?.title
          });
        }
     })
    }
    catch(error:any){
      this.loader = false;
      this.appinsightsService.logException(error,2);
    }
  }

  getAcademicYears() {
    try {
      this.courseDetails.getAcademicYears().subscribe((data: any) => {
        this.academicYearsList = data.result;
        this.academicYearsList.forEach((item: any) => {
          if (item.isRegistrationsOpen == true) {
            this.selectedAcademicYear  = item;
          }
        }); 
    
        this.getCoursesList(this.selectedAcademicYear?.id, null);


      });
    } catch (error: any) {
      if(error.status == 401){
        this.sharedService.token_Expired.next(true);
        localStorage.clear()
        this.confirmationService.confirm({
          message: 'Apologies, but your session has timed out. To proceed, please log in again.',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          key:'toastMsg',
          accept: () => {
           localStorage.clear()
           this.homeDetails.isUserLoggedIn.next(false);
           this.reset();
          },
        })
      }
      else if(error.status == 404){
       this.messageService.add({
         severity:'warn',
         summary: 'Warning',
         detail: error.message
       });
      }
      else if(error.status == 500){
        this.messageService.add({
          severity:'warn',
          summary: 'Warning',
          detail: "Something went wrong at server side!"
        });
      }
      else{
        this.messageService.add({
          severity:'warn',
          summary: 'Warning',
          detail: error.message
        });
      }
      this.appinsightsService.logException(error);
    }
  }
  
  async getRegisteredCourses(){
    try{
      this.studentsList=[];
      let name: any  = (localStorage.getItem('selectedStudentId'));
      let selectedStudentId =  name? JSON.parse(name): [];
      await this.validation.decodeLoggedInToken();
     await this.studentDetails.getStudentDetails().subscribe((data)=>{
       if(data.statusCode == 200){
       data.result.filter((x:any)=>{
          if(x.id == selectedStudentId){
            this.registeredCourses =  x.registeredCourses
          }
        })
       }
      },(error:any)=>{
        this.appinsightsService.logException(error,1);
        this.appinsightsService.logEvent("Error: Getting error in get student details api", {error:JSON.stringify(error)})
        if(error.status == 401){
          this.sharedService.token_Expired.next(true);
          localStorage.clear()
         this.confirmationService.confirm({
           message: 'Apologies, but your session has timed out. To proceed, please log in again.',
           header: 'Confirmation',
           icon: 'pi pi-exclamation-triangle',
           key:'toastMsg',
           accept: () => {
            localStorage.clear()
            this.homeDetails.isUserLoggedIn.next(false);
            this.router.navigate(['/registration/parent-details']);
           },
         })
       }
       else if(error.status == 500){
         this.messageService.add({
           severity:'warn',
           summary: 'Warning',
           detail: "Something went wrong at server side!"
         });
       }
       else{
         this.messageService.add({
           severity:'warn',
           summary: 'Warning',
           detail: error.message
         });
       }
     })
    }
    catch(error){
      console.log(error)
    }
  }

  filterCategoryBasedOnType(courseTypeData:any){
    try{
      this.duplicateEntry = false;
        this.categoryList = [];
        courseTypeData.map((x:any)=>{
        this.categoryList.push({label:x.categoryName, id:x.categoryId})
        // console.log("categories",this.categoryList)
      })
      const catogoriesFiltered = Array.from(this.categoryList.reduce((m:any, t:any) => m.set(t.label, t), new Map()).values())
      this.categoryList = catogoriesFiltered;
    }
    catch(error:any){
      this.appinsightsService.logException(error,2);
    }
  }
  filterLocationsBasedOnType(courseTypeData:any){
    try{
      this.duplicateEntry = false;
        this.locationsArray = [];
        courseTypeData.map((x:any)=>{
          x.branchSlots.map((y:any)=>{
            this.locationsArray.push(y)
          })
      })


      const locationsFiltered = Array.from(this.locationsArray.reduce((m:any, t:any) => m.set(t.branchName, t), new Map()).values())
      this.locationsArray = locationsFiltered;
    }

    catch(error:any){
      this.appinsightsService.logException(error,2);
    }
  }
  onLocationSelection(){
    try{
      if(this.preFerredLocation){
        const category = this.addCourseDetails.controls['category']
       const coursename = this.addCourseDetails.controls['coursename']
       const timings = this.addCourseDetails.controls['timings']
         category.reset();
        coursename.reset();
         timings.reset();
         this.categoryList=[];
         this.coursesList=[];
         this.timings=[];
      }
      this.preFerredLocation = true;
      this.storeLocation = this.selectedLocation.branchId
      this.duplicateEntry = false;
      let filteredcoursesBasedOnLocation:any = [];
      for(let i of this.filteredCompleteData){
        if(i?.branchSlots[0]?.branchId == this.selectedLocation.branchId){
          filteredcoursesBasedOnLocation.push(i)
        }
      }
      // this.filteredCompleteData = filteredcoursesBasedOnLocation
      this.filterCategoryBasedOnType(filteredcoursesBasedOnLocation )
    }
    catch(error:any){
      this.appinsightsService.logException(error,2);
    }
    
  }
 
  onCategorySelection(event:any){
    try{
      this.duplicateEntry = false;
      this.coursesList =[];
      if(this?.validateTypeofCourse?.cityFlag&&this?.selectedCourseType?.id==1){
        let storeOnline = this.completeCourseDetails?.onlineCourses
        let storeOffline = this.completeCourseDetails?.offlineCourses.filter((course:any) =>
          course?.branchSlots.some((slot:any) => slot.branchId === this.validateTypeofCourse?.cityId)
        );  
        let completed = storeOnline.filter((itemFilter:any) => {
          return !storeOffline.some((itemOffline:any) => itemOffline.courseName === itemFilter.courseName);
      });
      completed.map((x:any)=>{
        this.coursesList.push({label:x.courseName, id:x.courseId})
      })
      }
      else{
        this.filteredCompleteData.map((x:any)=>{
          if(x.categoryId == event.value.id )
        
          if(x.branchSlots && x.branchSlots[0].branchId == this.selectedLocation.branchId){
           
              this.coursesList.push({label:x.courseName, id:x.courseId})
           
          } 
          else if(!x.branchSlots){
            this.coursesList.push({label:x.courseName, id:x.courseId})
          }
        })
      }
     
      const coursesFiltered = Array.from(this.coursesList.reduce((m:any, t:any) => m.set(t.label, t), new Map()).values())
      this.coursesList = coursesFiltered;
    }
    catch(error:any){
      this.appinsightsService.logException(error,2);
    }
  }
  
  onCourseSelection(event:any){
    try{
      this.CourseAvailable = true;
      this.duplicateEntry = false;
      this.timings =[];
      const amount = this.addCourseDetails['controls']['amount'];
      const shippingAmount = this.addCourseDetails['controls']['shippingAmount'];
      const groupId = this.addCourseDetails['controls']['groupId'];
      const courseCategoryName = this.addCourseDetails['controls']['courseCategoryName'];
      if(this.selectedCourseType?.id == 1){
        this.filteredCompleteData.map((x:any)=>{
          if(x.courseId == event.value.id && this.addCourseDetails['controls']['category'].value.id ==  x.categoryId){
            if(x.branchSlots && x.branchSlots[0].branchId == this.selectedLocation.branchId ){
              this.timings = x.branchSlots[0]?.slots
            }
            else if(!x.branchSlots){
              x.courseSlots.forEach((y: any) => {
                this.timings.push({ name: y.name, id: y.slotId ,courseSlotId:y.courseSlotId });
              });
            }
            if(this.addCourseDetails['controls']['courseType'].value.id==1){
              shippingAmount.patchValue(this.storeSelectedCountry[0].deliveryCharge)
            }else{
              this.addCourseDetails['controls']['shippingAmount'].value>0? '': shippingAmount.patchValue(0)  
            }
            amount.patchValue(x.amount);
            groupId.patchValue(x.groupId);
            courseCategoryName.patchValue(x.courseCategory)
          }
        })

      }else
      {
        this.filteredCompleteData.map((x:any)=>{
          if(x.courseId == event.value.id && this.addCourseDetails['controls']['category'].value.id ==  x.categoryId){
            if(x.branchSlots && x.branchSlots[0].branchId == this.selectedLocation.branchId ){
              this.timings = x.branchSlots[0]?.slots
            }
            else if(!x.branchSlots){
              this.timings = x.courseSlots;
            }
            if(this.addCourseDetails['controls']['courseType'].value.id==1){
              shippingAmount.patchValue(x.deliveryCharge)
            }else{
              this.addCourseDetails['controls']['shippingAmount'].value>0? '': shippingAmount.patchValue(0)
             
            }
            amount.patchValue(x.amount);
            groupId.patchValue(x.groupId);
            courseCategoryName.patchValue(x.courseCategory)
          }
        })
      }    
    }
    catch(error:any){
      this.appinsightsService.logException(error,2);
    }
  }
  checkAvailability(){ 
    localStorage.setItem("academicYearId",  this.selectedAcademicYear.id)
      this.verifyCoursesList=[]
      this.verifyCoursesList.push(this.addCourseDetails.value)  
      if(!this.duplicateEntry){
      if(this.verifyCoursesList[0].timings.isSlotsAvailable == false ){    
        this.duplicateEntry = true;
         this.CourseAvailable = false;
        this.duplicateEntryText = "Slots are Closed"
      return;
    }}
      //  if(!this.duplicateEntry){
      //   this.verifyCoursesList=[]
      //   this.verifyCoursesList.push(this.addCourseDetails.value)
      //   for(let i of this.verifyCoursesList){
      //     if(i.groupId == this.addCourseDetails['controls']['groupId'].value){
          
      //       if(i.seatsRemaining == false){

      //         this.verifyCoursesList=[]
      //         this.timings =[];
      //         return;
      //       }
      //     }
      //     this.duplicateEntry = false;
      //   }
      // }
   }

  addCoursesToArray(){
    try{
      if (this.addCourseDetails.valid) {
      this.validateCourse();
      }
      else {
        for (const field in this.addCourseDetails.controls) {
          this.addCourseDetails.controls[field].markAsDirty();
        }
      }
    }
    catch(error:any){
      this.appinsightsService.logException(error,2);
    }
  }

  validateCourse(){
    try{
      if(this.registeredCourses.length > 0){
      for(let i of this.registeredCourses){
        if(i.groupId == this.addCourseDetails['controls']['groupId'].value && i.paymentStatus == 'Completed' ){
          this.duplicateEntry = true;
          this.duplicateEntryText = i.courseCategory + " already registered"
          return;
        }
        this.duplicateEntry = false;
      }
    }
  
      if(!this.duplicateEntry){
        if(this.addedCoursesList.length > 0){
        for(let i of this.addedCoursesList){
          if(i.groupId == this.addCourseDetails['controls']['groupId'].value  ){
            this.duplicateEntry = true;
            this.duplicateEntryText = i.courseCategoryName + " already added"
            return;
          }
          this.duplicateEntry = false;
        }
      }
      }


      if(!this.duplicateEntry&&this.CourseAvailable){
        this.addedCoursesList.push(this.addCourseDetails.value)
        localStorage.setItem("addedCourses", JSON.stringify(this.addedCoursesList))
        this.reset()
        this.showLocations = false;
        this.calculateAmount();
        this.onCourseTypeSelection();
      }
    }
    catch(error:any){
      this.appinsightsService.logException(error,2);
    }
  }
  clearAddedCourse(){
    localStorage.removeItem("addedCourses")
  }

  deleteCourse(index:number){
    try{
      this.clearAddedCourse()
      this.shippingAmount=0
      this.duplicateEntry = false;
      this.addedCoursesList.splice(index, 1);
      if(this.addedCoursesList.length > 0){
        localStorage.setItem("addedCourses", JSON.stringify(this.addedCoursesList))
      }
      else{
        localStorage.removeItem("addedCourses")
      }
      this.calculateAmount();
    }
    catch(error:any){
      this.appinsightsService.logException(error,2);
    }
    // this.getCoursesList(this.selectedAcademicYear.id, this.countryId);
  }

  calculateAmount(){
    try{
      let value: any  = (localStorage.getItem('addedCourses'));
      let data =  value? JSON.parse(value): [];
      let checkForOnlineCourse = data.filter((item:any) => item.courseType.id === "1");
      if(data.length > 0){
  
       this.totalAmount = 0;
         data.map((x:any)=>{
          checkForOnlineCourse.length>0? this.shippingAmount=x.shippingAmount : this.shippingAmount=0;
          this.totalAmount += x.amount;

        })
        this.totalAmount+=this.shippingAmount;
      }
      else{
        this.totalAmount = 0;
      }
    }
    catch(error:any){
      this.appinsightsService.logException(error,2);
    }
  }
  reset(){
    this.duplicateEntry = false;
    const category = this.addCourseDetails.controls['category']
    const coursename = this.addCourseDetails.controls['coursename']
    const timings = this.addCourseDetails.controls['timings']
    const locationName = this.addCourseDetails.controls['locationName']  
    locationName.reset();
    category.reset();
    coursename.reset();
    timings.reset();
    this.categoryList=[];
    this.coursesList=[];
    this.timings=[];
  }

  onSubmit(){
    try{
      if(this.termsAndConditions.valid){
      this.addCourseDetails.reset();
     if(this.addedCoursesList.length > 0){
      this.router.navigate(['registration/payment-details']);
     }
     else{
      this.messageService.add({
                 severity:'warn',
                 summary: 'Warning',
                 detail: 'Please add atleast one course to proceed'
               });
     }
    }
    else{
      for (const field in this.termsAndConditions.controls) {
        this.termsAndConditions.controls[field].markAsDirty();
      }
    }
  }
    catch(error:any){
      this.appinsightsService.logException(error,2);
    }
  }
}
