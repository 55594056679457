<div class="container-fluid">
  <p-toast position="top-center"></p-toast>
  <div class="row">
    <div class="col-12 py-3">
      <h4 class="fw-semibold text-center m-0">Manage Courses</h4>
    </div>
  </div>
  <div class="row pt-4" *ngIf="loader">
    <div class="col text-center">
      <p-progressSpinner
        *ngIf="loader"
        class="custom-spinner"
        strokeWidth="3"
        animationDuration=".5s"
      ></p-progressSpinner>
    </div>
  </div>
  <div class="row" *ngIf="!loader">
    <!-- *ngIf="this.categories.length>=0" -->
    <div class="d-flex justify-content-end">
      <div
        class="col-12 col-xl-2 col-md-4 p-1 largeScreen"
        *ngIf="academicYearsList"
      >
        <p-dropdown
          name="academicYr"
          id="academicYr"
          placeholder="Select a Academic Year"
          [options]="academicYearsList"
          optionLabel="name"
          [(ngModel)]="selectedAcademicYear"
          class="d-block dropdown searchBar"
          (onChange)="selecteAcademicYear(selectedAcademicYear.id)"
        ></p-dropdown>
      </div>
    </div>
    <p-tabView>
      <p-tabPanel header="Categories">
        <p-table
          #categoriesData
          [tableStyle]="{ 'min-width': '50rem' }"
          [value]="categories"
          *ngIf="activeIndex == 0"
          class="enrollments_table"
          sortMode="single"
          [responsive]="true"
          [rows]="5"
          [paginator]="true"
          [rowsPerPageOptions]="getPaginationOptions()"
          [globalFilterFields]="['name', 'createdDate']"
        >
          <ng-template pTemplate="caption">
            <div class="flex row d-flex align-items-center">
              <div class="col-12 p-1 col-md-4 p-input-icon-left ml-auto">
                <input
                  pInputText
                  type="text"
                  class="form-control search_field"
                  placeholder=" Search "
                  (input)="filterGlobal($event, 'contains')"
                />
              </div>

              <div
                class="col-12 col-md-4 offset-md-4 p-input-icon-left ml-auto d-flex align-items-center justify-content-end"
              >
                <button
                  pButton
                  pRipple
                  type="button"
                  label="Edit"
                  [disabled]="disableEdit"
                  class="btn-blue register_btn m-1"
                  (click)="openCategoryDialog('Edit')"
                ></button>
                <!-- // (click)="openCategoryDialog('edit')" -->
                <button
                  pButton
                  pRipple
                  type="button"
                  label="Add"
                  class="bg-strawberry p-button-danger register_btn m-1"
                  (click)="openCategoryDialog('Add')"
                ></button>
                <!-- //(click)="openCategoryDialog('add')" -->
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th class="bg-white"></th>
              <th class="bg-white">Category</th>
              <th class="bg-white">Added Date</th>
              <th class="bg-white">Status</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-category let-rowIndex="rowIndex">
            <tr>
              <td>
                <p-radioButton
                  class="pr-2"
                  required
                  name="category"
                  [inputId]="category.categoryId"
                  name="category"
                  [value]="category"
                  (click)="selectCategory(category)"
                  [(ngModel)]="selectedCategory"
                  [value]="category"
                ></p-radioButton>
              </td>
              <td>{{ category.name }}</td>
              <td>{{ category.createdDate }}</td>
              <td>
                <p-inputSwitch
                  (click)="isActive(category)"
                  [(ngModel)]="category.isActive"
                  [inputId]="category.categoryId"
                ></p-inputSwitch>
              </td>
              <!-- | date:'dd-MMM-yyyy' -->
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="4">No Categories found.</td>
            </tr>
          </ng-template>
        </p-table>
      </p-tabPanel>
      <p-tabPanel header="Courses">
        <p-table
          #courseData
          [tableStyle]="{ 'min-width': '50rem' }"
          [value]="courses"
          *ngIf="activeIndex == 0"
          class="enrollments_table"
          sortMode="single"
          [responsive]="true"
          [rows]="5"
          [paginator]="true"
        >
          <ng-template pTemplate="caption">
            <div class="flex row d-flex align-items-center">
              <div class="col-12 col-md-4 p-input-icon-left ml-auto">
                <input
                  pInputText
                  type="text"
                  class="form-control search_field"
                  placeholder=" Search "
                />
              </div>
              <div
                class="col-12 col-md-4 offset-md-4 p-input-icon-left ml-auto d-flex align-items-center justify-content-end"
              >
                <!-- openCategoryDialog('edit') for opending the dialog box editing  -->
                <button
                  pButton
                  pRipple
                  type="button"
                  label="Edit"
                  [disabled]="disableEdit"
                  class="btn-blue register_btn m-1"
                  (click)="openCourseDialog('edit')"
                ></button>
                <!-- // (click)="openCategoryDialog('edit')" -->
                <button
                  pButton
                  pRipple
                  type="button"
                  label="Add"
                  class="bg-strawberry p-button-danger register_btn m-1"
                  (click)="openCourseDialog('add')"
                ></button>
                <!-- //(click)="openCategoryDialog('add')" -->
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th class="bg-white"></th>
              <th class="bg-white">Category Name</th>
              <th class="bg-white">Course Name</th>
              <th class="bg-white">Course Type</th>
              <th class="bg-white">Added Date</th>
              <th class="bg-white">Status</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-course let-rowIndex="rowIndex">
            <tr>
              <td>
                <!-- [value]="category"
                                    (click)="selectCategory(category)"  [(ngModel)]="selectedCategory" [value]="category" change it as specific for course  -->

                <p-radioButton
                  class="pr-2"
                  required
                  name="course"
                  [inputId]="course.courseId"
                  name="course"
                  [value]="course"
                  (click)="selectCourse(course)"
                  [(ngModel)]="selectedCourse"
                ></p-radioButton>
              </td>
              <td>{{ course.categoryName }}</td>
              <td>{{ course.courseName }}</td>
              <td *ngIf="course.courseType == 1; else offline">Online</td>
              <ng-template #offline>
                <td>Offline</td>
              </ng-template>
              <td></td>
              <td>
                <p-inputSwitch
                  (click)="isActive(course)"
                  [(ngModel)]="course.isActive"
                  [inputId]="course.courseId"
                ></p-inputSwitch>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="4">No Courses found.</td>
            </tr>
          </ng-template>
        </p-table>
      </p-tabPanel>
      <p-tabPanel header="Timings">
        <p-table
          #categoriesData
          [tableStyle]="{ 'min-width': '50rem' }"
          [value]="categories"
          *ngIf="activeIndex == 0"
          class="enrollments_table"
          sortMode="single"
          [responsive]="true"
          [rows]="5"
          [paginator]="true"
        >
          <ng-template pTemplate="caption">
            <div class="flex row d-flex align-items-center">
              <div class="col-12 col-md-4 p-input-icon-left ml-auto">
                <input
                  pInputText
                  type="text"
                  class="form-control search_field"
                  placeholder=" Search "
                />
              </div>
              <div
                class="col-12 col-md-4 offset-md-4 p-input-icon-left ml-auto d-flex align-items-center justify-content-end"
              >
                <button
                  pButton
                  pRipple
                  type="button"
                  label="Edit"
                  [disabled]="disableEdit"
                  class="btn-blue register_btn m-1"
                  (click)="openCategoryDialog('edit')"
                ></button>
                <!-- // (click)="openCategoryDialog('edit')" -->
                <button
                  pButton
                  pRipple
                  type="button"
                  label="Add"
                  class="bg-strawberry p-button-danger register_btn m-1"
                  (click)="openCategoryDialog('add')"
                ></button>
                <!-- //(click)="openCategoryDialog('add')" -->
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th class="bg-white"></th>
              <th class="bg-white">TimeSlot</th>
              <th class="bg-white">Country</th>
              <th class="bg-white">Mode</th>
              <th class="bg-white">Added Date</th>
              <th class="bg-white">Status</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-category let-rowIndex="rowIndex">
            <tr>
              <td>
                <p-radioButton
                  class="pr-2"
                  required
                  name="category"
                  [inputId]="category.categoryId"
                  name="category"
                  [value]="category"
                  (click)="selectCategory(category)"
                  [(ngModel)]="selectedCategory"
                  [value]="category"
                ></p-radioButton>
              </td>
              <td>{{ category.categoryName }}</td>
              <td>{{ category.addedDate }}</td>
              <td>{{ category.courseType }}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="4">No Timings found.</td>
            </tr>
          </ng-template>
        </p-table>
      </p-tabPanel>
      <p-tabPanel header="Locations">
        <p-table
          #categoriesData
          [tableStyle]="{ 'min-width': '50rem' }"
          [value]="categories"
          *ngIf="activeIndex == 0"
          class="enrollments_table"
          sortMode="single"
          [responsive]="true"
          [rows]="5"
          [paginator]="true"
        >
          <ng-template pTemplate="caption">
            <div class="flex row d-flex align-items-center">
              <div class="col-12 col-md-4 p-input-icon-left ml-auto">
                <input
                  pInputText
                  type="text"
                  class="form-control search_field"
                  placeholder=" Search "
                />
              </div>
              <div
                class="col-12 col-md-4 offset-md-4 p-input-icon-left ml-auto d-flex align-items-center justify-content-end"
              >
                <button
                  pButton
                  pRipple
                  type="button"
                  label="Edit"
                  [disabled]="disableEdit"
                  class="btn-blue register_btn m-1"
                  (click)="openCategoryDialog('edit')"
                ></button>
                <!-- // (click)="openCategoryDialog('edit')" -->
                <button
                  pButton
                  pRipple
                  type="button"
                  label="Add"
                  class="bg-strawberry p-button-danger register_btn m-1"
                  (click)="openCategoryDialog('add')"
                ></button>
                <!-- //(click)="openCategoryDialog('add')" -->
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th class="bg-white"></th>
              <th class="bg-white">Location Name</th>
              <th class="bg-white">Added Date</th>
              <th class="bg-white">Status</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-category let-rowIndex="rowIndex">
            <tr>
              <td>
                <p-radioButton
                  class="pr-2"
                  required
                  name="category"
                  [inputId]="category.categoryId"
                  name="category"
                  [value]="category"
                  (click)="selectCategory(category)"
                  [(ngModel)]="selectedCategory"
                  [value]="category"
                ></p-radioButton>
              </td>
              <td>{{ category.categoryName }}</td>
              <td>{{ category.addedDate }}</td>
              <td>{{ category.courseType }}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="4">No Locations found.</td>
            </tr>
          </ng-template>
        </p-table>
      </p-tabPanel>
      <p-tabPanel header="Course Slot">
        <p-table
          #categoriesData
          [tableStyle]="{ 'min-width': '50rem' }"
          [value]="categories"
          *ngIf="activeIndex == 0"
          class="enrollments_table"
          sortMode="single"
          [responsive]="true"
          [rows]="5"
          [paginator]="true"
        >
          <ng-template pTemplate="caption">
            <div class="flex row d-flex align-items-center">
              <div class="col-12 col-md-4 p-input-icon-left ml-auto">
                <input
                  pInputText
                  type="text"
                  class="form-control search_field"
                  placeholder=" Search "
                />
              </div>
              <div
                class="col-12 col-md-4 offset-md-4 p-input-icon-left ml-auto d-flex align-items-center justify-content-end"
              >
                <button
                  pButton
                  pRipple
                  type="button"
                  label="Edit"
                  [disabled]="disableEdit"
                  class="btn-blue register_btn m-1"
                  (click)="openCategoryDialog('edit')"
                ></button>
                <!-- // (click)="openCategoryDialog('edit')" -->
                <button
                  pButton
                  pRipple
                  type="button"
                  label="Add"
                  class="bg-strawberry p-button-danger register_btn m-1"
                  (click)="openCategoryDialog('add')"
                ></button>
                <!-- //(click)="openCategoryDialog('add')" -->
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th class="bg-white"></th>
              <th class="bg-white">Course Type</th>
              <th class="bg-white">Course Name</th>
              <th class="bg-white">Total Slots</th>
              <th class="bg-white">Time Slot</th>
              <th class="bg-white">Status</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-category let-rowIndex="rowIndex">
            <tr>
              <td>
                <p-radioButton
                  class="pr-2"
                  required
                  name="category"
                  [inputId]="category.categoryId"
                  name="category"
                  [value]="category"
                  (click)="selectCourseSlot(category)"
                  [(ngModel)]="selectedCategory"
                  [value]="category"
                ></p-radioButton>
              </td>
              <td>{{ category.categoryName }}</td>
              <td>{{ category.addedDate }}</td>
              <td>{{ category.courseType }}</td>
              <td>{{ category.courseType }}</td>
              <td>
                <p-inputSwitch
                  (click)="isActive(category)"
                  [(ngModel)]="category.isActive"
                  [inputId]="category.categoryId"
                ></p-inputSwitch>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="4">No Course Slot found.</td>
            </tr>
          </ng-template>
        </p-table>
      </p-tabPanel>
    </p-tabView>
    <!-- <p-dialog  [(visible)]="showCategoryDialog" [style]="{width: '50vw'}" [modal]=true>
            <div class="row">
                <div class="col-12">
                    <h2 class="fw-bold">Add Category</h2>
                </div>
                <div class="col-12 col-md-6 py-4">
                    <form [formGroup]="categoryForm" (ngSubmit)="onCategorySubmit(categoryForm.value)">
                        <label for="category" class="dark-ask">Category<span class="text-danger"> *</span></label>
                        <input pInputText name="category" id="category" type="text" class="clsControl form-control"
                            formControlName="category" />
                        <div class="text-danger">
                            <span class="text-capitalize">{{ validation.validateFormControl('category','Category',categoryForm) }}</span>
                        </div>
                        <div class="row">
                            <div class="col-12 p-3 text-start">
                                <button pButton pRipple type="submit" label="Add"
                                    class="btn-blue  btn-lg"></button>
                                    <a (click)="reset()"><span
                                        class="text-danger d-inline-block p-3 cursor">Cancel</span></a>
                                <p-progressSpinner *ngIf="loader" class="custom-spinner"  strokeWidth="3"
                                    animationDuration=".5s" ></p-progressSpinner>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </p-dialog> -->
    <p-dialog
      [(visible)]="showCategoryDialog"
      [style]="{ width: '80vw' }"
      [modal]="true"
    >
      <div class="row">
        <div class="col-12">
          <h4 class="fw-bold text-dark mb-4">{{ pageTitle }} User</h4>
          <h5 class="fw-bold text-dark mb-4">
            Academic Year - {{ selectedAcademicYear?.name }}
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h5 class="fw-bold text-dark mb-4">Parent Details</h5>
        </div>
      </div>
      <form
        [formGroup]="categoryForm"
        (ngSubmit)="onCategorySubmit(categoryForm.value, pageTitle)"
      >
        <div class="row">
          <div class="col-12 col-md-6 pb-md-3 pb-2">
            <label class="dark-ask"
              >Category <span class="text-danger"> *</span></label
            >
            <input
              type="text"
              id="Category"
              name="Category"
              class="clsControl form-control"
              placeholder="Category"
              formControlName="category"
            />
            <div class="text-danger">
              <span class="text-capitalize">{{
                validation.validateFormControl(
                  "category",
                  "Category",
                  categoryForm
                )
              }}</span>
            </div>
          </div>

          <div class="col-12 col-md-6 pb-md-3 pb-2">
            <label for="teluguName" class="dark-ask"
              >Category Telugu Name<span class="text-danger"> *</span></label
            >
            <input
              type="text"
              id="teluguName"
              name="teluguName"
              class="clsControl form-control"
              placeholder="Catgeory Telugu Name"
              formControlName="teluguName"
            />
            <div class="text-danger">
              <span class="text-capitalize">{{
                validation.validateFormControl(
                  "teluguName",
                  "Catgeory
                                Telugu Name",
                  categoryForm
                )
              }}</span>
            </div>
          </div>

          <div class="col-12 pb-md-1 pb-2">
            <label class="dark-ask"
              >Description<span class="text-danger"> *</span></label
            >
            <textarea
              type="text"
              id="Description"
              name="Description"
              class="clsControl form-control"
              formControlName="Description"
              rows="8"
              cols="80"
            ></textarea>
            <!-- <input > -->
            <div class="text-danger pt-2 pb-2">
              <span class="text-capitalize">{{
                validation.validateFormControl(
                  "Description",
                  "Description",
                  categoryForm
                )
              }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex align-items-center justify-content-start">
            <button
              pButton
              pRipple
              type="submit"
              [ngClass]="this.pageTitle === 'Edit' ? 'btn-blue' : 'btn-Green'"
              class="d-flex align-items-center justify-content-center"
              [ngClass]="{ 'px-5 text-center text-white': Buttonloader }"
            >
              <span *ngIf="!loader" class="text-white">{{ buttonTitle }}</span>
              <p-progressSpinner
                *ngIf="Buttonloader"
                class="custom-spinner"
                strokeWidth="3"
                animationDuration=".5s"
              ></p-progressSpinner>
            </button>
            <a (click)="closeScreen()"
              ><span class="text-danger d-inline-block p-3 cursor"
                >Cancel</span
              ></a
            >
          </div>
        </div>
      </form>
    </p-dialog>
    <p-dialog
      [(visible)]="showCourseDialog"
      [style]="{ width: '80vw' }"
      [modal]="true"
    >
      <form
        [formGroup]="courseForm"
        (ngSubmit)="onCourseSubmit(courseForm.value, pageTitle)"
      >
        <div class="row">
          <div class="col-12">
            <h4 class="fw-bold text-dark mb-4">{{ pageTitle }} Add Course</h4>
            <h5 class="fw-bold text-dark mb-4">
              Academic Year - {{ selectedAcademicYear?.name }}
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 pb-md-3 pb-2">
            <label class="dark-ask"
              >Category <span class="text-danger"> *</span></label
            >
            <input
              type="text"
              id="Category"
              name="Category"
              class="clsControl form-control"
              placeholder="Category"
              formControlName="category"
            />
            <div class="text-danger">
              <span class="text-capitalize">{{
                validation.validateFormControl(
                  "category",
                  "Category",
                  courseForm
                )
              }}</span>
            </div>
          </div>

          <div class="col-12 col-md-6 pb-md-3 pb-2">
            <label for="courseName" class="dark-ask"
              >Course Name<span class="text-danger"> *</span></label
            >
            <input
              type="text"
              id="courseName"
              name="courseName"
              class="clsControl form-control"
              placeholder="Course Name"
              formControlName="courseName"
            />
            <div class="text-danger">
              <span class="text-capitalize">{{
                validation.validateFormControl(
                  "courseName",
                  "Course
                                Name",
                  courseForm
                )
              }}</span>
            </div>
          </div>

          <div class="col-12 pb-md-1 pb-2">
            <label class="dark-ask"
              >Description<span class="text-danger"> *</span></label
            >
            <textarea
              type="text"
              id="Description"
              name="Description"
              class="clsControl form-control"
              formControlName="Description"
              rows="8"
              cols="80"
            ></textarea>
            <!-- <input > -->
            <div class="text-danger pt-2 pb-2">
              <span class="text-capitalize">{{
                validation.validateFormControl(
                  "Description",
                  "Description",
                  courseForm
                )
              }}</span>
            </div>
          </div>

          <div class="col-12 col-md-6 pb-md-3 pb-2">
            <label for="preRequisite" class="dark-ask"
              >Pre-requisites<span class="text-danger"> *</span></label
            >
            <input
              type="text"
              id="preRequisite"
              name="preRequisite"
              class="clsControl form-control"
              placeholder="Pre-requisites"
              formControlName="preRequisite"
            />
            <div class="text-danger">
              <span class="text-capitalize">{{
                validation.validateFormControl(
                  "preRequisite",
                  "Pre-requisites",
                  courseForm
                )
              }}</span>
            </div>
          </div>
          <div class="col-12 col-md-6 pb-md-3 pb-2">
            <label for="minAge" class="dark-ask"
              >Minimum Age<span class="text-danger"> *</span></label
            >
            <input
              type="text"
              id="minAge"
              name="minAge"
              class="clsControl form-control"
              placeholder="Minimum Age"
              formControlName="minAge"
            />
            <div class="text-danger">
              <span class="text-capitalize">{{
                validation.validateFormControl(
                  "minAge",
                  "Minimum
                                Age",
                  courseForm
                )
              }}</span>
            </div>
          </div>
          <div class="col-12 col-md-6 pb-md-3 pb-2">
            <div class="p-fluid">
              <div class="p-field">
                <label for="dropdown" class="d-md-block dark-ask"
                  >Group Id <span class="text-danger"> *</span></label
                >
                <p-dropdown
                  [options]="dropdownValues"
                  formControlName="group"
                  [(ngModel)]="selectedValue"
                  placeholder="Select Group Id"
                  (ngModelChange)="onDropdownChange($event)"
                ></p-dropdown>
              </div>
              <div class="text-danger">
                <span class="text-capitalize">{{
                  validation.validateFormControl(
                    "group",
                    "Group-Id",
                    courseForm
                  )
                }}</span>
              </div>
            </div>
          </div>
          <div class="p-field" *ngIf="showInputField">
            <div class="row pt-3">
              <div class="col-md-3 col-12 pb-md-3 pb-2">
                <label for="inputValue" class="dark-ask d-block"
                  >Add New Value</label
                >
                <input
                  type="text"
                  #inputValue
                  class="clsControl form-control"
                  placeholder="Add Value"
                />
                <!-- <input name="inputValue"   type="text" pInputText class="pl-5"  /> -->
              </div>
              <div class="col-md-3 col-12 pb-md-3 pb-2">
                <label for="inputValue" class="dark-ask d-block"
                  >Add New Name</label
                >
                <input
                  type="text"
                  name="inputName"
                  #inputName
                  class="clsControl form-control"
                  placeholder="Add Name"
                />
                <!-- <input name="inputName"  #inputName type="text" pInputText class="pl-5"  /> -->
              </div>
              <div class="col-md-6 col-12 tabScreen pt-3">
                <button
                  pButton
                  type="button"
                  label="Add"
                  (click)="addNewValue()"
                ></button>
              </div>
            </div>
          </div>
          <div class="text-danger">
            <span class="text-capitalize">{{
              validation.validateFormControl("group", "Group Id", courseForm)
            }}</span>
            <span class="text-capitalize text-danger" *ngIf="this.errorMessage"
              >Add Course Name and New Group Id</span
            >
          </div>
        </div>
        <div class="row">
          <!-- <div class="col-md-6 col-xs-12 p-2 "   *ngFor="let location of testLocation">
<label for="{{location.name}}" class="dark-ask">{{location.name}} <span class="text-danger"> *</span></label>
<p-multiSelect id="{{location.name}}" [options]="location.loction"  formControlName="{{location.name}}" optionLabel="name"></p-multiSelect>
<div class="text-danger" >
    <span class="text-capitalize">{{ validation.validateFormControl(location.name,'testing....',courseForm) }}</span>
    
</div>
    </div> -->

          <div
            class="col-md-6 col-xs-12 p-2"
            *ngFor="let location of ApiCountryArray"
          >
            <label for="{{ location.name }}" class="dark-ask"
              >{{ location.name }} <span class="text-danger"> *</span></label
            >
            <input
              id="{{ location.name }}"
              type="text"
              [formControlName]="location.name"
              class="clsControl form-control"
            />
            <div class="text-danger">
              <span class="text-capitalize">{{
                validation.validateFormControl(location.name, "Fee", courseForm)
              }}</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 d-flex align-items-center justify-content-start">
            <!-- [ngClass]="this.pageTitle === 'Edit' ? 'btn-blue' : 'btn-Green'"  -->
            <button
              pButton
              pRipple
              type="submit"
              class="d-flex align-items-center justify-content-center"
              [ngClass]="{ 'px-5 text-center text-white': loader }"
            >
              Save
              <!-- <span *ngIf="!loader" class="text-white"  >{{buttonTitle}}</span> -->
              <!-- <p-progressSpinner *ngIf="loader" class="custom-spinner" strokeWidth="3"
                        animationDuration=".5s"></p-progressSpinner> -->
            </button>
            <a (click)="closeScreen()"
              ><span class="text-danger d-inline-block p-3 cursor"
                >Cancel</span
              ></a
            >
          </div>
        </div>
      </form>
    </p-dialog>

    <p-dialog
      [(visible)]="showTimingDialog"
      [style]="{ width: '80vw' }"
      [modal]="true"
    >
      <form
        [formGroup]="timingForm"
        (ngSubmit)="onTimingSubmit(timingForm.value, pageTitle)"
      >
        <div class="row">
          <div class="col-12">
            <h4 class="fw-bold text-dark mb-4">{{ pageTitle }} Add TimeSlot</h4>
            <h5 class="fw-bold text-dark mb-4">
              Academic Year - {{ selectedAcademicYear?.name }}
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 pb-3">
            <label for="courseType" class="dark-ask pt-md-3"
              >Mode Type<span class="text-danger"> *</span></label
            >
            <div class="d-flex align-items-center">
              <div
                *ngFor="let course of courseTypeArray"
                class="d-flex align-items-center"
              >
                <p-radioButton
                  formControlName="courseType"
                  class="pr-2"
                  required
                  [inputId]="course.id"
                  name="courseType"
                  [value]="course"
                  [(ngModel)]="selectedCourseType"
                  (ngModelChange)="onTimingSelection()"
                ></p-radioButton>

                <label class="m-2">{{ course.label }}</label>
              </div>
            </div>
            <div class="text-danger">
              <span class="text-capitalize">{{
                validation.validateFormControl(
                  "courseType",
                  "Course
                                Type",
                  timingForm
                )
              }}</span>
            </div>
          </div>
          <!-- <div class="col-12">
                    <h5 class="fw-bold text-dark mb-4">Parent Details </h5>
                </div> -->
        </div>

        <div class="row">
          <div class="col-xs-12 col-md-6">
            <label for="Country" class="dark-ask"
              >Country<span class="text-danger"> *</span></label
            >
            <p-dropdown
              name="Country"
              id="Country"
              formControlName="Country"
              class="dropdown d-block studentDetail_dropdown"
              (ngModelChange)="onSelectedCountry($event?.id)"
              [options]="countryApiArray"
              placeholder="Select Country"
              optionLabel="countryName"
            ></p-dropdown>
            <div class="text-danger">
              <span class="text-capitalize">
                <!-- <p-progressSpinner *ngIf="loader" class="custom-spinner" strokeWidth="3"
                            animationDuration=".5s"></p-progressSpinner> -->
              </span>
              <span class="text-capitalize">{{
                validation.validateFormControl("Country", "Country", timingForm)
              }}</span>
            </div>
          </div>
          <div
            class="col-xs-12 col-md-6"
            *ngIf="this.selectedCourseType.id != 1"
          >
            <label for="Country" class="dark-ask"
              >City/Town<span class="text-danger"> *</span></label
            >
            <p-dropdown
              name="City"
              id="City"
              formControlName="City"
              class="dropdown d-block studentDetail_dropdown"
              [options]="cityApiArray"
              placeholder="Select City/Town"
              optionLabel="label"
            ></p-dropdown>
            <div class="text-danger">
              <span class="text-capitalize">
                <!-- <p-progressSpinner *ngIf="loader" class="custom-spinner" strokeWidth="3"
                            animationDuration=".5s"></p-progressSpinner> -->
              </span>
              <span class="text-capitalize">{{
                validation.validateFormControl("City", "City/Town", timingForm)
              }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-md-6 pt-md-2">
            <label for="Day" class="dark-ask"
              >Day<span class="text-danger"> *</span></label
            >
            <p-dropdown
              name="Day"
              id="Day"
              formControlName="Day"
              class="dropdown d-block studentDetail_dropdown"
              [options]="DayApiArray"
              placeholder="Select Day"
              optionLabel="label"
            ></p-dropdown>
            <div class="text-danger">
              <span class="text-capitalize">
                <!-- <p-progressSpinner *ngIf="loader" class="custom-spinner" strokeWidth="3"
                            animationDuration=".5s"></p-progressSpinner> -->
              </span>
              <span class="text-capitalize">{{
                validation.validateFormControl("Day", "Day", timingForm)
              }}</span>
            </div>
          </div>

          <div class="col-xs-12 col-md-6 d-md-flex pt-md-2">
            <div class="col-6 col-md-3">
              <label for="StartTime" class="dark-ask"
                >From<span class="text-danger"> *</span></label
              >
              <p-calendar
                inputId="calendar-timeonly"
                [(ngModel)]="startTime"
                [timeOnly]="true"
                [minDate]="startMin"
                formControlName="startTime"
                (ngModelChange)="onSelectingStartTime($event)"
              >
              </p-calendar>
            </div>
            <div class="col-6 col-md-3 offset-md-2">
              <label for="EndTime" class="dark-ask"
                >To<span class="text-danger"> *</span></label
              >
              <p-calendar
                inputId="calendar-timeonly"
                [(ngModel)]="endTime"
                [timeOnly]="true"
                [minDate]="minEndTime"
                formControlName="endTime"
                [disabled]="isEndDateDisabled"
              >
              </p-calendar>
            </div>
          </div>
        </div>

        <div class="row pt-3">
          <div class="col-12 d-flex align-items-center justify-content-start">
            <!-- [ngClass]="this.pageTitle === 'Edit' ? 'btn-blue' : 'btn-Green'"  -->
            <button
              pButton
              pRipple
              type="submit"
              class="d-flex align-items-center justify-content-center"
              [ngClass]="{ 'px-5 text-center text-white': loader }"
            >
              Save
              <!-- <span *ngIf="!loader" class="text-white"  >{{buttonTitle}}</span> -->
              <!-- <p-progressSpinner *ngIf="loader" class="custom-spinner" strokeWidth="3"
                        animationDuration=".5s"></p-progressSpinner> -->
            </button>
            <a (click)="closeScreen()"
              ><span class="text-danger d-inline-block p-3 cursor"
                >Cancel</span
              ></a
            >
          </div>
        </div>
      </form>
    </p-dialog>

    <p-dialog
      [(visible)]="showLocationDialog"
      [style]="{ width: '80vw' }"
      [modal]="true"
    >
      <form
        [formGroup]="locationForm"
        (ngSubmit)="onLocationSubmit(locationForm.value, pageTitle)"
      >
        <div class="row">
          <div class="col-12">
            <h4 class="fw-bold text-dark mb-4">{{ pageTitle }} Add Location</h4>
            <h5 class="fw-bold text-dark mb-4">
              Academic Year - {{ selectedAcademicYear?.name }}
            </h5>
          </div>
        </div>
        <!-- <div class="row">
    
                <div class="col-md-12 pb-3">
                    <label for="courseType" class="dark-ask pt-md-3">Mode Type<span class="text-danger"> *</span></label>
                    <div class="d-flex align-items-center">
                        <div  *ngFor="let course of courseTypeArray" class="d-flex align-items-center">
                            <p-radioButton formControlName="courseType"  class="pr-2" required [inputId]="course.id" name="courseType"
                            [value]="course" [(ngModel)]="selectedCourseType" (ngModelChange)="onTimingSelection()" ></p-radioButton>
                        
                            <label class="m-2">{{ course.label }}</label>
                        </div>
                    </div>
                    <div class="text-danger" >
                        <span class="text-capitalize">{{ validation.validateFormControl('courseType','Course Type',timingForm) }}</span>
                    </div>
                </div>
            </div> -->

        <div class="row">
          <div class="col-xs-12 col-md-6">
            <label for="Country" class="dark-ask"
              >Country<span class="text-danger"> *</span></label
            >
            <p-dropdown
              name="Country"
              id="Country"
              formControlName="Country"
              class="dropdown d-block studentDetail_dropdown"
              (ngModelChange)="onSelectedCountry($event?.id)"
              [options]="countryApiArray"
              placeholder="Select Country"
              optionLabel="countryName"
            ></p-dropdown>
            <div class="text-danger">
              <span class="text-capitalize">
                <!-- <p-progressSpinner *ngIf="loader" class="custom-spinner" strokeWidth="3"
                            animationDuration=".5s"></p-progressSpinner> -->
              </span>
              <span class="text-capitalize">{{
                validation.validateFormControl(
                  "Country",
                  "Country",
                  locationForm
                )
              }}</span>
            </div>
          </div>
          <div class="col-xs-12 col-md-6">
            <label for="Country" class="dark-ask"
              >City/Town<span class="text-danger"> *</span></label
            >
            <p-dropdown
              name="City"
              id="City"
              formControlName="City"
              class="dropdown d-block studentDetail_dropdown"
              [options]="cityApiArray"
              placeholder="Select City/Town"
              optionLabel="label"
            ></p-dropdown>
            <div class="text-danger">
              <span class="text-capitalize">
                <!-- <p-progressSpinner *ngIf="loader" class="custom-spinner" strokeWidth="3"
                            animationDuration=".5s"></p-progressSpinner> -->
              </span>
              <span class="text-capitalize">{{
                validation.validateFormControl(
                  "City",
                  "City/Town",
                  locationForm
                )
              }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-md-6 pt-md-2">
            <label for="zipCode" class="dark-ask"
              >Zip Code<span class="text-danger">*</span></label
            >
            <input
              pInputText
              name="zipCode"
              id="zipCode"
              type="text"
              class="clsControl form-control"
              formControlName="zipCode"
              placeholder="Zip Code"
            />
            <div class="text-danger">
              <span class="text-capitalize">{{
                validation.validateFormControl(
                  "zipCode",
                  "Zip
                                Code",
                  locationForm
                )
              }}</span>
            </div>
          </div>
          <div class="col-xs-12 col-md-6 pt-md-2">
            <label for="location" class="dark-ask"
              >Location Map URL<span class="text-danger">*</span></label
            >
            <input
              pInputText
              name="location"
              id="location"
              type="text"
              class="clsControl form-control"
              formControlName="location"
              placeholder="Location Map (URL)"
            />
            <div class="text-danger">
              <span class="text-capitalize">{{
                validation.validateFormControl(
                  "location",
                  "Location",
                  locationForm
                )
              }}</span>
            </div>
          </div>
          <div class="col-xs-12 col-md-12 pt-md-2">
            <label for="location" class="dark-ask"
              >Description<span class="text-danger">*</span></label
            >
            <textarea
              type="text"
              id="Description"
              name="Description"
              class="clsControl form-control"
              formControlName="Description"
              rows="8"
              cols="80"
            ></textarea>
            <div class="text-danger">
              <span class="text-capitalize">{{
                validation.validateFormControl(
                  "Description",
                  "Description",
                  locationForm
                )
              }}</span>
            </div>
          </div>
        </div>

        <div class="row pt-3">
          <div class="col-12 d-flex align-items-center justify-content-start">
            <!-- [ngClass]="this.pageTitle === 'Edit' ? 'btn-blue' : 'btn-Green'"  -->
            <button
              pButton
              pRipple
              type="submit"
              class="d-flex align-items-center justify-content-center"
              [ngClass]="{ 'px-5 text-center text-white': loader }"
            >
              Save
              <!-- <span *ngIf="!loader" class="text-white"  >{{buttonTitle}}</span> -->
              <!-- <p-progressSpinner *ngIf="loader" class="custom-spinner" strokeWidth="3"
                        animationDuration=".5s"></p-progressSpinner> -->
            </button>
            <a (click)="closeScreen()"
              ><span class="text-danger d-inline-block p-3 cursor"
                >Cancel</span
              ></a
            >
          </div>
        </div>
      </form>
    </p-dialog>
    <p-dialog
      [(visible)]="showCourseSlot"
      [style]="{ width: '80vw' }"
      [modal]="true"
    >
      <form
        [formGroup]="courseSlotForm"
        (ngSubmit)="onCourseSlotSubmit(courseSlotForm.value, pageTitle)"
      >
        <div class="row">
          <div class="col-12">
            <h4 class="fw-bold text-dark mb-4">
              {{ pageTitle }} Add Course Slot
            </h4>
            <h5 class="fw-bold text-dark mb-4">
              Academic Year - {{ selectedAcademicYear?.name }}
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 pb-3">
            <label for="courseType" class="dark-ask pt-md-3"
              >Course Type<span class="text-danger"> *</span></label
            >
            <div class="d-flex align-items-center">
              <div
                *ngFor="let course of courseTypeArray"
                class="d-flex align-items-center"
              >
                <p-radioButton
                  formControlName="courseType"
                  class="pr-2"
                  required
                  [inputId]="course.id"
                  name="courseType"
                  [value]="course"
                  [(ngModel)]="selectedCourseSlotType"
                  (ngModelChange)="onCourseTypeSlotSelection($event)"
                ></p-radioButton>

                <label class="m-2">{{ course.label }}</label>
              </div>
            </div>
            <div class="text-danger">
              <span class="text-capitalize">{{
                validation.validateFormControl(
                  "courseType",
                  "CourseType",
                  courseSlotForm
                )
              }}</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-md-6">
            <label for="CourseName" class="dark-ask"
              >Course Name<span class="text-danger">*</span></label
            >
            <p-dropdown
              name="courseName"
              id="courseName"
              formControlName="courseName"
              class="dropdown d-block studentDetail_dropdown"
              [options]="courseApiArray"
              placeholder="Select Course Name"
              optionLabel="name"
            >
            </p-dropdown>
            <div class="text-danger">
              <span class="text-capitalize">{{
                validation.validateFormControl(
                  "courseName",
                  "Course
                                Name",
                  courseSlotForm
                )
              }}</span>
            </div>
          </div>
          <div formArrayName="offlineSlots">
            <div *ngFor="let location of testLocation; let i = index" [formGroupName]="i">
              <div class="row">
                <div class="col-md-6 col-xs-12 p-2">
                  <label for="selectedLocation" class="dark-ask">{{ location.name }} <span class="text-danger">*</span></label>
                  <p-multiSelect id="{{location.name}}" [options]="location.loction"  [formControlName]="'selectedLocation'" optionLabel="name"></p-multiSelect>
                  <!-- <div class="text-danger">
                    <span class="text-capitalize">{{
                      validation.validateFormControl(
                        "selectedLocation",
                        "Time slot",
                    courseSlotForm
                      )
                    }}</span>
                  </div> -->
                </div>


                <div class="col-md-6 col-xs-12 p-2">
                  <label for="totalSlot" class="dark-ask">Total Slots <span class="text-danger">*</span></label>
                  <input
                  pInputText
                  name="totalSlot"
                  id="totalSlot"
                  type="text"
                  class="clsControl form-control"
                  [formControlName]="'totalSlot'"
                  placeholder="Total Slots"

                />
                <!-- <div class="text-danger">
                  <span class="text-capitalize">{{
                    validation.validateFormControl(
                      "totalSlot",
                      "Total Slots",
                  courseSlotForm
                    )
                  }}</span>
                </div> -->
                </div>
              </div>
            </div>
          </div>

          
        </div>

        <div class="row pt-3">
          <div class="col-12 d-flex align-items-center justify-content-start">
            <!-- [ngClass]="this.pageTitle === 'Edit' ? 'btn-blue' : 'btn-Green'"  -->
            <button
              pButton
              pRipple
              type="submit"
              class="d-flex align-items-center justify-content-center"
              [ngClass]="{ 'px-5 text-center text-white': loader }"
            >
              Save
              <!-- <span *ngIf="!loader" class="text-white"  >{{buttonTitle}}</span> -->
              <!-- <p-progressSpinner *ngIf="loader" class="custom-spinner" strokeWidth="3"
                        animationDuration=".5s"></p-progressSpinner> -->
            </button>
            <a (click)="closeScreen()"
              ><span class="text-danger d-inline-block p-3 cursor"
                >Cancel</span
              ></a
            >
          </div>
        </div>
      </form>
    </p-dialog>
  </div>
</div>
