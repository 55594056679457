import { RouterModule, Routes,Route, UrlSegment, UrlMatchResult } from '@angular/router';
import { HomeComponent } from "./components/home/home.component";
import { RegistrationComponent } from "./components/registration/registration.component";
import { ParentDetailsComponent } from "./components/registration/registration-forms/parent-details/parent-details.component";
import { StudentDetailsComponent } from "./components/registration/registration-forms/student-details/student-details.component";
import { CourseDetailsComponent } from "./components/registration/registration-forms/course-details/course-details.component";
import { AllCourseDetailsComponent } from "./components/all-course-details/all-course-details.component";
import { PaymentDetailsComponent } from "./components/registration/registration-forms/payment-details/payment-details.component";
import { CalendarComponent } from "./components/calendar/calendar.component";
import { ConctacUsComponent } from "./components/conctac-us/conctac-us.component";
import { JoinUsComponent } from "./components/join-us/join-us.component";
import { DonateComponent } from "./components/donate/donate.component";
import { FaqComponent } from "./components/faq/faq.component";
import { TermsAndConditionsComponent } from "./components/terms-and-conditions/terms-and-conditions.component";
import { LoginComponent } from "./components/login/login.component";
import { EnrollmentsComponent } from "./components/enrollments/enrollments.component";
import { CourseManagementComponent } from "./components/course-management/course-management.component";
import { EnrollmentDetailViewComponent } from "./components/enrollment-detail-view/enrollment-detail-view.component";
import { EventsComponent } from "./components/events/events.component";
import { AdminViewComponent } from './components/admin-view/admin-view.component';
import { AuthGuardService } from './services/guards/auth-guard.service';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { ParentDetailsEditComponent } from './components/parent-details-edit/parent-details-edit.component';

const appRoutes: Routes = [

    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'login', component: LoginComponent, pathMatch: 'full' },
    { path: 'home', component: HomeComponent , pathMatch: 'full'},
    { path: 'all-courses/:id', component: AllCourseDetailsComponent, pathMatch: 'full' },
    { path: 'calendar', component: CalendarComponent , pathMatch: 'full'},
    {path:'manaUgadi2024' ,component: HomeComponent, pathMatch: 'full'},
    { path:'Events' , component: HomeComponent, pathMatch: 'full'},
    { path: 'contact-us', component: ConctacUsComponent, pathMatch: 'full' },
    { path: 'join-us', component: JoinUsComponent , pathMatch: 'full' },
    { path: 'Donate', component: DonateComponent ,},
    { path: 'FAQ', component: FaqComponent , pathMatch: 'full' },
    // { path: 'faq', component: FaqComponent , pathMatch: 'full' },
    { path: 'terms&conditions', component: TermsAndConditionsComponent , pathMatch: 'full'},
    { path: 'Parent-Details', component: ParentDetailsEditComponent , pathMatch: 'full'},
   
    { path: 'registration', component: RegistrationComponent ,
    children: [
        {path: 'parent-details', component: ParentDetailsComponent , pathMatch: 'full'},
        {path: 'student-details', component: StudentDetailsComponent , pathMatch: 'full'},
        {path: 'course-details', component: CourseDetailsComponent , pathMatch: 'full'},
        {path: 'payment-details', component: PaymentDetailsComponent , pathMatch: 'full'},
    ]},

    {path:'Admin',component:AdminViewComponent,canActivate: [AuthGuardService],
        children:[
            { path: 'enrollments', component: EnrollmentsComponent , pathMatch: 'full'},
            { path: 'user-management', component:UserManagementComponent, pathMatch: 'full'},
            { path: 'enrollments/:enrollmentId', component: EnrollmentDetailViewComponent, pathMatch: 'full' },
            { path: 'course-management', component: CourseManagementComponent , pathMatch: 'full'},

        ]
    },
]

export const routing = RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' });
