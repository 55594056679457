import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { donate } from './donate';
import { PaymentService } from 'src/app/services/payment.service';
import { MessageService } from 'primeng/api';
import { ValidationService } from 'src/app/services/validation.service';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-donate',
  templateUrl: './donate.component.html',
  styleUrls: ['./donate.component.css'],
})
export class DonateComponent implements OnInit {
  donateForm: FormGroup;
  loader: boolean = false;
  paypalLink: string = 'https://www.paypal.com/donate/?hosted_button_id=QUG5B6MVATNJ8';
  donationAmount: any;
  showPrompt: boolean = false;
  constructor(private fb: FormBuilder,private messageService: MessageService,private appinsightsService: LoggingService, private paymentDetails: PaymentService, public validation: ValidationService) {
    this.donateForm = this.fb.group({
      firstname: new FormControl(
        null,
        Validators.compose([Validators.required, Validators.maxLength(150),Validators.pattern('^[^a-zA-Z]*[a-zA-Z]+.*$')])
      ),
      lastname: new FormControl(
        null,
        Validators.compose([Validators.required, Validators.maxLength(150),Validators.pattern('^[^a-zA-Z]*[a-zA-Z]+.*$')])
      ),
      donationamount: new FormControl(null, Validators.compose([Validators.required, Validators.pattern('^[1-9][0-9]?(\.[0-9]{1,2})?$')])),
      email: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
        ])
      ),
    });
  }

  ngOnInit(): void {this.appinsightsService.logEvent('Donate screen viewed')}


  onSubmit(formdata: any,e:any) {
    try{
      if (this.donateForm.valid) {
        this.loader = true;
        let donateObj: donate = {
          "firstName": formdata.firstname,
          "lastName": formdata.lastname,
          "email": formdata.email,
          "amount": formdata.donationamount
        }
        this.paymentDetails.donate(donateObj).subscribe((data)=>{
        },(error:any)=>{
          this.appinsightsService.logException(error,1)
          this.appinsightsService.logEvent("Error: Getting error in adding donation api", {payload: JSON.stringify(donateObj),error:JSON.stringify(error)})
        })
        this.loader = false;
        this.donateForm.reset();
        this.showPrompt = true;
        e.target.submit();
      } else {
        for (const field in this.donateForm.controls) {
          this.donateForm.controls[field].markAsDirty();
        }
      }
    }
    catch(error:any){
      this.appinsightsService.logException(error,2)
    }
  }
  reset() {
    this.showPrompt = false;
    this.donateForm.reset();
  }

  validateAmount(amount:any){
    if (amount > 0){

        // make our API call-fire & forget
        this.donationAmount = amount;
       const element:any =  document.getElementsByName('amount');
       element[0].value = amount; 
        return true;
    }
    else {
      for (const field in this.donateForm.controls) {
        this.donateForm.controls[field].markAsDirty();
      }
        return false;
    }
}
}
