import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { GlobalResources } from 'src/app/services/global-resource/global-resource';
import { StudentDetailsService } from 'src/app/services/student-details.service';
import { student, studentList } from './student-details';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { HomeService } from 'src/app/services/home.service';
import { ValidationService } from 'src/app/services/validation.service';
import { LoggingService } from 'src/app/services/logging.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-student-details',
  templateUrl: './student-details.component.html',
  styleUrls: ['./student-details.component.css'],
})
export class StudentDetailsComponent implements OnInit {
  addStudentDetails: FormGroup;
  public globalResource: any;
  studentsList:any;
  showData: boolean = false;
  showCourses: boolean = false;
  studentsListOriginal: studentList[] = [];
  registeredCoursesList:any;
  selectedStudentId: number = 0;
  loader: boolean = false;
  genderArray=[{id:'M', label:"Male"},{id:'F', label:"Female"}];
  previousCentersArray=[{id:"Bellevue",label:"Bellevue"},{id:"Redmond",label:"Redmond"},{id:"Bothell",label:"Bothell"},{id:"Sammamish",label:"Sammamish"},{id:"Monroe",label:"Monroe"},]
  relationShipArray = [{id:'parent', label:'Parent'},{id:'guardian', label:'Guardian'},{id:'self', label:'Self'}]

  constructor(private fb: FormBuilder, 
              private homeDetails: HomeService,
              private router: Router, 
              private confirmationService: ConfirmationService, 
              private studentDetails: StudentDetailsService,
              private messageService: MessageService,
              private appinsightsService: LoggingService,
              public validation: ValidationService,
              private sharedService: SharedService ) {
    this.addStudentDetails = this.fb.group({
      addOrEditStudent: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      firstName: new FormControl(null, Validators.compose([Validators.required, Validators.maxLength(150),Validators.pattern('^[^a-zA-Z]*[a-zA-Z]+.*$')])),
      lastName: new FormControl(null, Validators.compose([Validators.required, Validators.maxLength(150),Validators.pattern('^[^a-zA-Z]*[a-zA-Z]+.*$')])),
      teluguName: new FormControl(null, Validators.compose([ Validators.maxLength(200)])),
      age: new FormControl(null, Validators.compose([Validators.required,Validators.pattern('^[1-9][0-9]?$|^100$')])),
      relationship:new FormControl(null, Validators.required),
      gender: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
      previousCenter:  new FormControl(null),
      studentId: new FormControl(null)
    });
  }

  ngOnInit(): void {
    try{
      window.scrollTo(0,0) 
      this.globalResource = GlobalResources.getResources().en;
      this.getStudentDetails();
      localStorage.removeItem('addedCourses')
      this.appinsightsService.logEvent('student-details form viewed')
    }
    catch(error:any){
      this.appinsightsService.logException(error,2)
    }
  }


  async getStudentDetails(){
    try{
      this.studentsList=[];
      this.loader = true;
      await this.validation.decodeLoggedInToken();
      await this.studentDetails.getStudentDetails().subscribe((data)=>{
       if(data.statusCode == 200){
        this.loader = false;
        if(data.result.length == 0){
          this.studentsList.push({label:"Add New Student", id:0});
          this.addStudentDetails['controls']['addOrEditStudent'].patchValue(this.studentsList[0])
        }
        else{
          data.result.map((x:any)=>{
            this.studentsListOriginal = data.result;
            this.studentsList.push({label:x.studentName, id:x.id})
          })
         let index =  this.studentsList.length;
         this.studentsList[index] = {label:"Add New Student", id:0}
         let id: any  = (localStorage.getItem('selectedStudentId'));
         let selectedStudentId =  id? JSON.parse(id): 0;
         if(selectedStudentId > 0 ){
           this.selectedStudentId = selectedStudentId
          this.showData  =true;
           this.prepopulatedStudentData(selectedStudentId);
         }
        }
       }
      },(error:any)=>{
        this.loader = false;
        this.appinsightsService.logException(error,1);
        this.appinsightsService.logEvent("Error: Getting error in get student details api", {error:JSON.stringify(error)})
        if(error.status == 401){
          this.sharedService.token_Expired.next(true);
          localStorage.clear()
          this.confirmationService.confirm({
            message: 'Apologies, but your session has timed out. To proceed, please log in again.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key:'toastMsg',
            accept: () => {
             localStorage.clear()
             this.homeDetails.isUserLoggedIn.next(false);
            },
          })
        }
        else if(error.status == 404){
          this.studentsList.push({label:"Add New Student", id:0});
          this.addStudentDetails['controls']['addOrEditStudent'].patchValue(this.studentsList[0])
        //  this.messageService.add({
        //    severity:'warn',
        //    summary: 'Warning',
        //    detail: error.message
        //  });
        }
        else if(error.status == 500){
          this.messageService.add({
            severity:'warn',
            summary: 'Warning',
            detail: "Something went wrong at server side!"
          });
        }
     
        else{
          this.messageService.add({
            severity:'warn',
            summary: 'Warning',
            detail: error.message
          });
        }
     })
    }
    catch(error:any){
      this.loader = false;
      this.appinsightsService.logException(error,2);
    }
  }

  onSelectingStudent(event:any){
    try{
     const firstName =  this.addStudentDetails['controls']['firstName']
     const lastName =  this.addStudentDetails['controls']['lastName']
     const teluguName =  this.addStudentDetails['controls']['teluguName']
     const age =  this.addStudentDetails['controls']['age']
     const gender =  this.addStudentDetails['controls']['gender']
     const relationshipData =  this.addStudentDetails['controls']['relationship']
     const previousCenter =  this.addStudentDetails['controls']['previousCenter']
     firstName.reset();
     lastName.reset();
     teluguName.reset();
     age.reset();
     previousCenter.reset();
     gender.reset();
     relationshipData.reset();
     localStorage.removeItem('addedCourses')
     localStorage.removeItem('academicYearId')
     this.selectedStudentId = event.value.id;
      if(event.value.id == 0){
        this.showData = false;
        this.showCourses = false;
      }
      else{
        this.showData = true;
        localStorage.setItem("selectedStudentId", JSON.stringify(this.selectedStudentId));
        this.prepopulatedStudentData(event.value.id);
      }
    }
    catch(error:any){
      this.appinsightsService.logException(error,2);
    }
  }

  prepopulatedStudentData(studentId:number){
    try{
      this.showCourses = true;
     this.studentsListOriginal.filter((x:any)=>{
        if(x.id == studentId){
          const student = this.studentsList.filter((x:any) => x.id == studentId)
          const genderData = this.genderArray.filter((y:any) => y.id == x.gender)
          const previousCenterData = this.previousCentersArray.filter((z:any) => z.id == x.previousAttendedCenter)
          const relationshipData = this.relationShipArray.filter((z:any) => z.id == x.relation)
          this.addStudentDetails['controls']['addOrEditStudent'].patchValue(student[0])
          this.addStudentDetails['controls']['firstName'].patchValue(x.firstName)
          this.addStudentDetails['controls']['lastName'].patchValue(x.lastName)
          this.addStudentDetails['controls']['teluguName'].patchValue(x.teluguName)
          this.addStudentDetails['controls']['age'].patchValue(x.age)
          this.addStudentDetails['controls']['studentId'].patchValue(x.id)
          this.addStudentDetails['controls']['gender'].patchValue(genderData[0])
          this.addStudentDetails['controls']['relationship'].patchValue(relationshipData[0])
          this.addStudentDetails['controls']['previousCenter'].patchValue(previousCenterData[0])
          this.registeredCoursesList =  x.registeredCourses
          localStorage.setItem("selectedStudentName", JSON.stringify(x.studentName));
        }
      })
    }
    catch(error:any){
      this.appinsightsService.logException(error,2);
    }
  }
  
  async onSubmit(formdata: any) {
    try{
    if (this.addStudentDetails.valid) {
      let studentObj:student = {
        "firstName": formdata.firstName,
        "lastName": formdata.lastName,
        "age": formdata.age,
        "gender": formdata.gender.id,
        "relation": formdata.relationship.id,
        "previousAttendedCenter": formdata?.previousCenter?.id,
        "teluguName": formdata.teluguName,

      }
      if(this.selectedStudentId == 0){
        await this.validation.decodeLoggedInToken();
     await this.studentDetails.addStudent(studentObj).subscribe((data)=>{
          if(data.statusCode == 201){
            this.messageService.add({
              severity:'success', 
              summary: 'Success', 
              detail: 'Details saved successfully'
            });
           
            localStorage.setItem("selectedStudentId", JSON.stringify(data.result.id));
            localStorage.setItem("selectedStudentName", JSON.stringify(data.result.studentName));
            this.addStudentDetails.reset();
            setTimeout(()=>{
              this.router.navigate(['registration/course-details']);
            },1000)
          }
      },(error:any)=>{
        this.appinsightsService.logException(error,1);
        this.appinsightsService.logEvent("Error: Getting error in upsert student details api", {payload: JSON.stringify(studentObj),error:JSON.stringify(error)})
        if(error.status == 401){
          this.sharedService.token_Expired.next(true);
          localStorage.clear()
         this.confirmationService.confirm({
           message: 'Apologies, but your session has timed out. To proceed, please log in again.',
           header: 'Confirmation',
           icon: 'pi pi-exclamation-triangle',
           key:'toastMsg',
           accept: () => {
            localStorage.clear()
            this.homeDetails.isUserLoggedIn.next(false);
            this.router.navigate(['/registration/parent-details']);
           },
         })
       }
       else if(error.status == 500){
         this.messageService.add({
           severity:'warn',
           summary: 'Warning',
           detail: "Something went wrong at server side!"
         });
       }
       else if(error.status == 400){
        this.messageService.add({
          severity:'warn',
          summary: 'Warning',
          detail: error.error.detail
        });
      }
       else{
         this.messageService.add({
           severity:'warn',
           summary: 'Warning',
           detail: error.message
         });
       }
     })
      }
      else if(this.selectedStudentId != 0 && this.addStudentDetails.dirty){
        this.studentDetails.updateStudent(studentObj,this.selectedStudentId).subscribe((data)=>{
          if(data.statusCode == 200){
            this.messageService.add({
              severity:'success', 
              summary: 'Success', 
              detail: 'Details saved successfully'
            });
            localStorage.setItem("selectedStudentId", JSON.stringify(this.selectedStudentId));
            localStorage.setItem("selectedStudentName", JSON.stringify(formdata.firstName+" "+formdata.lastName));
            this.addStudentDetails.reset();
            setTimeout(()=>{
              this.router.navigate(['registration/course-details']);
            },1000)
          }
      },(error:any)=>{
        this.appinsightsService.logException(error,1);
        this.appinsightsService.logEvent("Error: Getting error in upsert student details api", {payload: JSON.stringify(studentObj),error:JSON.stringify(error)})
        if(error.status == 401){
          this.sharedService.token_Expired.next(true);
          localStorage.clear()
         this.confirmationService.confirm({
           message: 'Apologies, but your session has timed out. To proceed, please log in again.',
           header: 'Confirmation',
           icon: 'pi pi-exclamation-triangle',
           key:'toastMsg',
           accept: () => {
            localStorage.clear()
            this.homeDetails.isUserLoggedIn.next(false);
            this.router.navigate(['/registration/parent-details']);
           },
         })
       }
       else if(error.status == 400){
        this.messageService.add({
          severity:'warn',
          summary: 'Warning',
          detail: error.error.detail
  
        });
      }
       else if(error.status == 500){
         this.messageService.add({
           severity:'warn',
           summary: 'Warning',
           detail: "Something went wrong at server side!"
         });
       }
       else{
         this.messageService.add({
           severity:'warn',
           summary: 'Warning',
           detail: error.message
         });
       }
     })
      }
      else{
        this.addStudentDetails.reset();
        this.router.navigate(['registration/course-details']);
      }
    } else {
      for (const field in this.addStudentDetails.controls) {
        this.addStudentDetails.controls[field].markAsDirty();
      }
    }
  }
  catch(error:any){
    this.appinsightsService.logException(error,2);
  }
}


  navigateToTranslator(){
    window.open(`https://translate.google.com/?sl=auto&tl=te&text=${this.addStudentDetails.controls['firstName'].value}&op=translate`, "_blank");
  }
}
