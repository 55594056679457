<div class="container-fluid p-md-1">
    <div class="row">
        <div class="col-12 py-3">
            <h4 class="fw-semibold text-center m-0">FAQs</h4>
        </div>
    </div>

    <div class="row">
        <p-accordion [activeIndex]="0">
            <p-accordionTab *ngFor="let faq of faqArray" [header]="faq.question">
                <div class="row">
                    <div class="col-12 px-5">
                        <p-editor class="pEditor__Readonly w-100" [(ngModel)]="faq.answer"  [readonly]="true"></p-editor>
                    </div>
                </div>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>
