import { Component } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { LoaderService } from './services/loader.service';
import { HomeService } from './services/home.service';
import { LoggingService } from './services/logging.service';
import { SharedService } from './services/shared.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'manasamskriti';
  showLoader: boolean = false;
  showLogOut: boolean = false;
  logginUserData:any;
  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private homeDetails: HomeService,
    private appinsightsService: LoggingService,
    private sharedService: SharedService,
    private confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
    this.loaderService.status.subscribe((val: boolean) => {
      this.showLoader = val;
  });
  //Phase 2:=>
   this.homeDetails.isUserLoggedIn.subscribe((data:any)=>{
    const value: any  = (localStorage.getItem('token'));
    this.logginUserData = (localStorage.getItem('userData'));
    this.logginUserData = JSON.parse(this.logginUserData)
    let token =  value? JSON.parse(value): "";
   if( token != "" && this.logginUserData?.roleId != 1){
    this.showLogOut = true;
    // this.router.navigate([''])
   }
   else if(token != "" && this.logginUserData?.roleId == 1){
    this.showLogOut = true;
    if(this.router.url =="/"){
      this.router.navigate(['Admin/enrollments'])
    }
    // this.router.navigate(['Admin/enrollments'])
   }
   else{
    this.showLogOut = false;
   }
  })

  this.sharedService.token_Expired.subscribe((data:any)=>{
    if(data){
    this.confirmationService.confirm({
        message: 'Apologies, but your session has timed out. To proceed, please log in again.',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        key:'toastMsg',
        accept: () => {
         localStorage.clear()
         this.homeDetails.isUserLoggedIn.next(false);
         this.router.navigate(['/login'])
        },
      })
    }
  })
  }


  // this.homeDetails.isUserLoggedIn.subscribe((data:any)=>{
  //  this.homeDetails.isUserLoggedIn.subscribe((data:any)=>{
  //   const value: any  = (localStorage.getItem('token'));
  //   this.logginUserData = (localStorage.getItem('userData'));
  //   this.logginUserData = JSON.parse(this.logginUserData)
  //   let token =  value? JSON.parse(value): "";
  //  if( token != "" && this.logginUserData?.roleId != 1){
  //   this.showLogOut = true;
  //   this.router.navigate(['/home'])
  //  }
  //  else if(token != "" && this.logginUserData?.roleId == 1){
  //   this.showLogOut = true;
  //   this.router.navigate(['/enrollments'])
  //  }
  //  else{
  //   this.showLogOut = false;
  //  }
  // })

  // this.sharedService.token_Expired.subscribe((data:any)=>{
  //   if(data){
  //   this.confirmationService.confirm({
  //       message: 'Apologies, but your session has timed out. To proceed, please log in again.',
  //       header: 'Confirmation',
  //       icon: 'pi pi-exclamation-triangle',
  //       key:'toastMsg',
  //       accept: () => {
  //        localStorage.clear()
  //        this.homeDetails.isUserLoggedIn.next(false);
  //        this.router.navigate(['/login'])
  //       },
  //     })
  //   }
  // })
  // }




  navigateToParentDetailsForm(){
    this.router.navigate(['/registration/parent-details']);
  }
  logout(){
    try{
      localStorage.clear()
      this.homeDetails.isUserLoggedIn.next(false);
      this.router.navigate(['/login']);
    }
    catch(error:any){
      this.appinsightsService.logException(error,1)
    }
  }

}
