// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiEndpoint:'https://manasamskriti-api.azurewebsites.net/',
  paypalClientId: 'AZoMTsoBfDFjBEwFJIbjSSN2N_PuPPS9VhcoBt6Tq4IH8dTuU9h0TQI3nq31NHNhRJve-EElom0tM1cl',
  appInsights: {
    instrumentationKey: '8a541f76-de24-47bc-afef-55c57aff5090'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
