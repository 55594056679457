export class GlobalResources {
    static getResources() {
        return {
            en: {
                invalidEmail: "Email invalid",
                emailRequired: "Email required",
                firstNameRequired: "First Name required",
                lastNameRequired: "Last Name required",
                exceedCharLimit: "Maximum limit exceeded",
                phnnumberRequired: "Phone Number required",
                invalidPhnNumber: "Phone Number invalid",
            }
        }
    }
}
