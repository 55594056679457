import { Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { CourseDetailsService } from 'src/app/services/course-details.service';
import { EnrollmentsService } from 'src/app/services/enrollments.service';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';
import { HomeService } from 'src/app/services/home.service';
import { LoggingService } from 'src/app/services/logging.service';
import { SharedService } from 'src/app/services/shared.service';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'app-course-management',
  templateUrl: './course-management.component.html',
  styleUrls: ['./course-management.component.css'],
})
export class CourseManagementComponent implements OnInit {

  // Form
  categoryForm !: FormGroup;
  courseForm !: FormGroup;
  timingForm !: FormGroup;
  locationForm !:FormGroup;
  courseSlotForm!:FormGroup;

  //Dialog box flag
  showCategoryDialog:boolean = false;
  showCourseDialog:boolean=false;
  showCourseSlot:boolean = true;
  showTimingDialog:boolean = false;
  showLocationDialog:boolean =false;

// Dummy api array 
cityApiArray:any;
DayApiArray:any;
ApiCountryArray:any;

// Api integrated Array
countryApiArray: any;

// ngModel 
selectedCountry: any;
selectedCourseType:any;

// unsure
selectedCategory: any;
selectedCourse:any
category_selected:any;
course_selected:any;
disableEdit: boolean = true;
selectedValue: any;
showInputField: boolean = false;
paginationValues: any;
pageTitle:any;
buttonTitle:any;
activeIndex: number = 0;
loader: boolean = false;
Buttonloader: boolean = false;
disabledDates: Date[] = [];
errorMessage:any; 
selectedCourseSlotType:any;

// Ui variable
categories:any;
coursesStore:any=[];
Timings:any;
Locations:any;
courses:any;
selectedAcademicYear: any;
academicYearsList: any;
startTime: any = null;
endTime: any = null;
minEndTime: any = null;
showChangeCourseDialog: boolean | undefined;
isEndDateDisabled: boolean = false;

// ui Array
courseTypeArray = [{"id":"1","label":"Online"},{"id":"2","label":"In-Person"}];
modeArray=[{id:"online",label:"Online"},{id:"offline",label:"Offline"}]
modules: { label: string; id: number }[] = [
  { label: 'Categories', id: 1 },
  { label: 'Courses', id: 2 },
  { label: 'Timings', id: 3 },
  { label: 'Slots', id: 4 },
];

// view child
@ViewChild('categoriesData') categoriesData: Table | undefined;
@ViewChild('courseData') courseData: Table | undefined;
@ViewChild('inputValue')
inputValueRef!: ElementRef;
@ViewChild('inputName')
inputNameRef!: ElementRef;



  constructor(
    private appinsightsService: LoggingService,
    private fb: FormBuilder, 
    private homeDetails: HomeService,
    private router: Router,
    private courseDetails: CourseDetailsService,
    public validation: ValidationService,
    private enrollmentService: EnrollmentsService,
    private sharedService: SharedService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private errorHandling: ErrorHandlingService
  ) {

  }
  testLocation:any
  courseApiArray = [
    {id: 1, name: "hindi"},
    {id: 2, name: "telugu"},
    {id: 3, name: "Eng"}
  ];
  startMin: any = null;
  ngOnInit(): void {
    try {
    
//dummy array
      this.testLocation = [{
        name:"Hyd",
        id:1,
        loction:[{id:1,name:'vinod'},{id:2,name:'raj'},{id:3,name:'D'}],
      }, {
        name:"Sec",
        id:2,
        loction:[{id:1,name:'vinod1'},{id:2,name:'raj1'},{id:3,name:'D1'}],
      },
      {
        name:"Mal",
        id:3,
        loction:[{id:1,name:'vinod2'},{id:2,name:'raj2'},{id:3,name:'D2'}],
      },
      
    ]
this.startMin = new Date();
this.startMin.setHours(0, 0, 0, 0);
this.startTime = this.startMin
// Country: new FormControl(null, Validators.compose([Validators.required])),
// totalSlot: new FormControl(null, Validators.compose([Validators.required])),
// courseType: new FormControl(null, Validators.compose([Validators.required]))
      // this.courseSlotForm =  this.fb.group({
      //   courseName: new FormControl(
      //     null,
      //     Validators.compose([Validators.required, Validators.maxLength(150)])
      //   ), 
   
      // });
      this.courseSlotForm = this.fb.group({
        courseName: new FormControl(null, Validators.compose([Validators.required, Validators.maxLength(150)])),
        courseType: new FormControl(null, Validators.compose([Validators.required])),
        offlineSlots: this.fb.array([])
      });
    
      this.locationForm = this.fb.group({
        Country: new FormControl(null, Validators.compose([Validators.required])),
        City: new FormControl(null, Validators.compose([Validators.required])),
        zipCode: new FormControl(
          null,
          Validators.compose([
            Validators.required,
            Validators.pattern('^[0-9]{5}$')
          ])
        ),
        location: new FormControl(
          null,
          Validators.compose([Validators.required, Validators.maxLength(150)])
        ), 
        Description: new FormControl(
          null,
          Validators.compose([Validators.required, Validators.maxLength(150)])
        ), 
      });
      
      this.timingForm = this.fb.group({
        courseType: new FormControl(null, Validators.compose([Validators.required])),  
        Country:new FormControl(null,Validators.compose([Validators.required])),
        City:new FormControl(null),
        Day:new FormControl(null,Validators.compose([Validators.required])),
        startTime:new FormControl(null,Validators.compose([Validators.required])),
        endTime:new FormControl(null,Validators.compose([Validators.required])),
      })
      this.courseForm = this.fb.group({
        courseType: new FormControl(null, Validators.compose([Validators.required])),  
        category: new FormControl(
          null,
          Validators.compose([Validators.required, Validators.maxLength(150)])
        ),
        Description: new FormControl(
          null,
          Validators.compose([Validators.required, Validators.maxLength(150)])
        ), 
         courseName: new FormControl(
          null,
          Validators.compose([Validators.required, Validators.maxLength(150)])
        ),
        preRequisite: new FormControl(
          null,
          Validators.compose([Validators.required, Validators.maxLength(150)])
        ),
        minAge: new FormControl(
          null,
          Validators.compose([Validators.required, Validators.maxLength(150)])
        ),
        fee: new FormControl(
          null,
          Validators.compose([Validators.required, Validators.maxLength(150)])
        ),   totalSlot: new FormControl(
          null,
          Validators.compose([Validators.required, Validators.maxLength(150)])
        ),  
        Country:new FormControl(null,Validators.compose([Validators.required])),
        group: new FormControl(null, Validators.compose([Validators.required])),  
      })
      this.categoryForm = this.fb.group({
        category: new FormControl(
          null,
          Validators.compose([Validators.required, Validators.maxLength(150)])
        ),
        Description: new FormControl(
          null,
          Validators.compose([Validators.required, Validators.maxLength(150)])
        ),  teluguName: new FormControl(
          null,
          Validators.compose([Validators.required, Validators.maxLength(150)])
        ),
      })
      this.getAcademicYears();
      this.getCountries()
      this.initOfflineSlots();

    this.DayApiArray = [ 
    { label: 'Mon', id: 1 },
    { label: 'Tue', id: 2 },
    { label: 'Wed', id: 3 },
    { label: 'Thu', id: 4 },
    { label: 'Fri', id: 5},
    { label: 'Sat', id: 6},
    { label: 'Sun', id: 7},
  ]
  this.ApiCountryArray = [{id:1,name:"usa"},{id:2,name:"kuwait"}]

  this.ApiCountryArray.forEach((x: any) => {
    if (!this.courseForm.get(x.name)) {
      this.courseForm.addControl(x.name, this.fb.control('', Validators.required));
    }
  });

  // this.testLocation.forEach((x: any) => {
  //   if (!this.courseSlotForm.get(x.name)) {
  //     this.courseSlotForm.addControl(x.name, this.fb.control('', Validators.required));
  //   }
  // });
  this.cityApiArray =  [
    { label: 'Categories', id: 1 },
    { label: 'Courses', id: 2 },
    { label: 'Timings', id: 3 },
    { label: 'Slots', id: 4 },
  ];
  //try push it in any method
  this.selectedCourseType = this.courseTypeArray[0];
  this.selectedCourseSlotType = this.courseTypeArray[0]

    } catch (error: any) {
      this.appinsightsService.logException(error, 2);
    }
  }
// test 
dropdownValues: any[] = [
  { label: 'Option 1', value: 'Option 1' },
  { label: 'Option 2', value: 'Option 2' },
  { label: 'Option 3', value: 'Option 3' },
  { label: 'Add New...', value: 'new' }
];



// Drop down for add new group id
onDropdownChange(event: any) {
 if(event!=='new'){this.showInputField = false; this.errorMessage = false}
else{
  this.showInputField = true};
}

addNewValue() {
  const inputValue = this.inputValueRef.nativeElement.value+'-'+ this.inputNameRef.nativeElement ;
  if (inputValue) {
    this.errorMessage = false;
    this.dropdownValues.unshift({ label: inputValue, value: inputValue });
    this.showInputField = false;
  }else{
    this.errorMessage = true;
  }
  
}

//  test Slots
get offlineSlots() {
  return this.courseSlotForm.get('offlineSlots') as FormArray;
}
initOfflineSlots() {
  this.testLocation.forEach((location:any) => {
    const group = this.fb.group({
      locationName: [location.name],
      totalSlot: [''],
      selectedLocation: ['']
    });

    this.offlineSlots.push(group);
  });
}



// timer logic


  onSelectingStartTime(event: Date) {
    this.startTime = event;
    this.minEndTime = new Date(this.startTime?.getTime() + 5 * 60000);
    this.endTime = new Date(this.startTime?.getTime() + 5 * 60000);
    this.checkEndTimeValidity();
  }
  onSelectingEndTime(event: Date) {
    this.endTime = event;
    this.checkEndTimeValidity();
  }
  private checkEndTimeValidity() {
    if (this.endTime && this.startTime) {
      this.isEndDateDisabled = this.endTime < this.startTime;
    }
  }


  // onselect func
  onSelectedCountry(countryID:any){
    this.selectedCountry = countryID
    this.reset( )
   }

  onTimingSelection(){ 
  const cityControl = this.timingForm.get('City')!;
 if(this.selectedCourseType.id=="1"){
  cityControl.clearValidators();
  }
  else{
    cityControl.setValidators(Validators.required);
  } 
  cityControl.updateValueAndValidity();
}
selectCategory(category: any) {
  this.disableEdit = false;
  this.category_selected = category;
}
selectCourse(course:any){
this.disableEdit = false;
this.selectedCourse = course;
this.course_selected = course;
console.log("course",  this.course_selected)
}

selectCourseSlot(courseSlot:any){

}
onCourseTypeSlotSelection(x:any){
  if(this.selectedCourseSlotType?.id==1){

    this.testLocation = [{ name:"Hyd",
    loction:[{id:1,name:'vinod'},{id:2,name:'raj'},{id:3,name:'D'}]}]

  }else{
    this.testLocation = [{
      name:"Hyd",
      id:1,
      loction:[{id:1,name:'vinod'},{id:2,name:'raj'},{id:3,name:'D'}],
    }, {
      name:"Sec",
      id:2,
      loction:[{id:1,name:'vinod1'},{id:2,name:'raj1'},{id:3,name:'D1'}],
    },
    {
      name:"Mal",
      id:3,
      loction:[{id:1,name:'vinod2'},{id:2,name:'raj2'},{id:3,name:'D2'}],
    },
    
  ]
  }
 }



//  get call 

getAcademicYears() {
  try {
    this.loader = true;
    this.courseDetails.getAcademicYears().subscribe((data: any) => {
      if(data.statusCode=200){}
      this.loader = false;
      this.academicYearsList = data.result;
      this.academicYearsList.forEach((item: any) => {
        if (item.isRegistrationsOpen == true) {
          this.selectedAcademicYear = item;
        }
      });
      this.selecteAcademicYear(this.selectedAcademicYear.id);
    },
    (error: any) => {
      this.loader = false;
      this.appinsightsService.logException(error, 1);
      this.appinsightsService.logEvent(
        'Error: Getting error in change course api',
        { error: JSON.stringify(error) }
      );
      this.errorHandling.ErrorHandling(error);
    });
  } catch (error: any) {

    this.appinsightsService.logException(error);
  }
}

  getCountries(){
    try{
      this.loader = true;
      this.courseDetails.getCountry().subscribe((data)=>{
        this.loader = false;
       this.countryApiArray=data.result;
      },(error:any)=>{
        this.loader = false;
        this.errorHandling.ErrorHandling(error);
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: Getting error in get all courses Country list api", {error:JSON.stringify(error)})
      })
    }
    catch(error:any){
      this.loader = false;
      this.appinsightsService.logException(error,2)
    }
  }
  selecteAcademicYear(academicYearId:any) {
    try {
      this.loader = true;
      this.courseDetails.getCategories(academicYearId,null).subscribe((data) => {
     
        if(data.statusCode==200){
          this.loader = false;
          this.categories =  data.result
        }
      },
      (error: any) => {
        this.loader = false;
        this.appinsightsService.logException(error, 1);
        this.appinsightsService.logEvent(
          'Error: Getting error in change course api',
          { error: JSON.stringify(error) }
        );
        this.errorHandling.ErrorHandling(error);
      });
     this.getCourses(academicYearId)
     this.getTimings(academicYearId)
      
    } catch (error: any) {
      this.loader = false;
      this.errorHandling.ErrorHandling(error);
      this.appinsightsService.logException(error, 1);
      this.appinsightsService.logEvent(
        'Error: Getting error in get all courses list api in course management',
        { error: JSON.stringify(error) }
      );
    }
  }
getCourses(academicYearId:any){
 try{
  this.courseDetails?.getCourseDetails(academicYearId,null).subscribe((data) => {

    if(data.statusCode==200){
      this.loader = false;
      this.coursesStore = data.result
      this.courses = [...this.coursesStore.onlineCourses,...this.coursesStore.offlineCourses]
    }
  },
  (error: any) => {
    this.loader = false;
    this.appinsightsService.logException(error, 1);
    this.appinsightsService.logEvent(
      'Error: Getting error in change course api',
      { error: JSON.stringify(error) }
    );
    this.errorHandling.ErrorHandling(error);
  });

 }catch (error: any) {
  this.loader = false;
  this.errorHandling.ErrorHandling(error);
  this.appinsightsService.logException(error, 1);
  this.appinsightsService.logEvent(
    'Error: Getting error in get all courses list api in course management',
    { error: JSON.stringify(error) }
  );
}
  }

  getTimings(academicYearId:any){
    try{
     this.courseDetails?.getTimingDetails(academicYearId).subscribe((data) => {
   
       if(data.statusCode==200){
         this.loader = false;
         this.Timings = data.result
         console.log("timing", this.Timings)
   
       }
     },
     (error: any) => {
       this.loader = false;
       this.appinsightsService.logException(error, 1);
       this.appinsightsService.logEvent(
         'Error: Getting error in change course api',
         { error: JSON.stringify(error) }
       );
       this.errorHandling.ErrorHandling(error);
     });
   
    }catch (error: any) {
     this.loader = false;
     this.errorHandling.ErrorHandling(error);
     this.appinsightsService.logException(error, 1);
     this.appinsightsService.logEvent(
       'Error: Getting error in get all courses list api in course management',
       { error: JSON.stringify(error) }
     );
   }
     }

     getLocation(academicYearId:any){
      try{
       this.courseDetails?.getLocation(academicYearId).subscribe((data) => {
     
         if(data.statusCode==200){
           this.loader = false;
           this.Locations = data.result
           console.log("Loaction", this.Locations)
     
         }
       },
       (error: any) => {
         this.loader = false;
         this.appinsightsService.logException(error, 1);
         this.appinsightsService.logEvent(
           'Error: Getting error in change course api',
           { error: JSON.stringify(error) }
         );
         this.errorHandling.ErrorHandling(error);
       });
     
      }catch (error: any) {
       this.loader = false;
       this.errorHandling.ErrorHandling(error);
       this.appinsightsService.logException(error, 1);
       this.appinsightsService.logEvent(
         'Error: Getting error in get all courses list api in course management',
         { error: JSON.stringify(error) }
       );
     }
       }
  public getPaginationOptions(): number[] {
    const stringOptions: string[] = ['5', '10', '15', '20', '25'];
    return stringOptions.map((option) => parseInt(option, 5));
  }
  // filter
  filterGlobal($event: any, stringVal: any) {
    this.categoriesData!.filterGlobal(
      ($event.target as HTMLInputElement).value,
      stringVal
    );
  }


// is active
  isActive(id:any){
    console.log(id)
  }



// category Dialog box
  openCategoryDialog(action:string){   
    this.reset();
    this.showCategoryDialog = true;
    if(action == 'Edit'){
      // let SelectedMode = this.modeArray?.filter((x:any)=>(x.id == 'online'))
      this.buttonTitle='Save'
      this.categoryForm.patchValue({
        category: this.category_selected.name,
        Describe: this.category_selected.description,
        teluguName:this.category_selected.categoryTeluguName,
        // mode:SelectedMode[0]
      })
    }else{
      this.buttonTitle = "Add"
    }
    this.pageTitle = action
  }
  openCourseDialog(action:string){   
    this.reset();
    this.showCategoryDialog = true;
    if(action == 'Edit'){
      // let SelectedMode = this.modeArray?.filter((x:any)=>(x.id == 'online'))
      this.buttonTitle='Save'
      this.categoryForm.patchValue({
        category: this.category_selected.name,
        Describe: this.category_selected.description,
        teluguName:this.category_selected.categoryTeluguName,
        // mode:SelectedMode[0]
      })
    }else{
      this.buttonTitle = "Add"
    }
    this.pageTitle = action
  }


  closeScreen() 
  {
    this.showChangeCourseDialog = false;
  }


// on submit function
onCategorySubmit(formdata:any,type:any){
  try {
    if(this.categoryForm.valid){
     
      this.Buttonloader = true;
      let categories:category = {
        "name": formdata.category,
        "teluguName":formdata.category,
        "academicYearId":this.selectedAcademicYear.id,
        "description":formdata.Description
      }
      // this.courseDetails.addCategory(categories,type, this.category_selected).subscribe((data)=>{

      // })
      this.courseDetails
      .addNewCategory(
       categories,type,this.category_selected?.id
      ).subscribe((data:any) => {
        this.Buttonloader = false;
        if(data.statusCode == 200){
          this.messageService.add({
            severity:'success',
            summary: 'Success',
            detail: 'Category Added successfully'
          });
          
        }
        if(data.statusCode == 201){
          this.messageService.add({
            severity:'success',
            summary: 'Success',
            detail: 'Category Updated successfully'
          });
          
        }
        this. showCategoryDialog = false
      },
      (error: any) => {
        this. showCategoryDialog = false
        this.Buttonloader = false;
        this.appinsightsService.logException(error, 1);
        this.appinsightsService.logEvent(
          'Error: Getting error in change course api',
          { error: JSON.stringify(error) }
        );
        this.errorHandling.ErrorHandling(error);
      });
     
    }
    else{
      for (const field in this.categoryForm.controls) {
        this.categoryForm.controls[field].markAsDirty();
      }
    
    }
  } catch (error: any) {
    this.appinsightsService.logException(error, 1);
    this.appinsightsService.logEvent(
      'Error: Getting error in add category api in course management',
      { error: JSON.stringify(error) }
    );
  }
}
onTimingSubmit(formdata:any,type:any){}
  onCourseSubmit(formdata:any,type:any){
console.log(formdata)
  }
  onLocationSubmit(formdata:any,type:any){
console.log(formdata)
  }
onCourseSlotSubmit(formdata:any,type:any){
  if (this.courseSlotForm?.valid) {
    const formData = this.courseSlotForm.value;
    const offlineSlots = formData.offlineSlots;
    const offline: { BranchId: any; slots: any; }[] = [];

    // offlineSlots.forEach((locationData:any, index:any) => {
    //   let selectedLocation = [];
    //   if (Array.isArray(locationData.selectedLocation)) {
    //     selectedLocation = locationData?.selectedLocation.map((location:any) => ({
    //       ...location,
    //       totalSlot: locationData.totalSlot
    //     }));
    //   } else {
    //     selectedLocation = [{
    //       ...locationData.selectedLocation,
    //       totalSlot: locationData.totalSlot
    //     }];
    //   }
    
    //   // Get Bid value from testLocation array
    //   const Bid = this.testLocation[index].id;
    
    //   // Add location data to offline array
    //   offline.push({
    //     Bid: Bid,
    //     slots: selectedLocation
    //   });
    // });
    offlineSlots.forEach((locationData: any, index: any) => {
      let selectedLocation = [];
      if (Array.isArray(locationData.selectedLocation)) {
        // If it's an array, proceed with mapping over its elements
        
        selectedLocation = locationData.selectedLocation.map((location: any) => ({
          slotId: location.id,
          Capacity: locationData.totalSlot
        }));
      } else {
        console.error('locationData.selectedLocation is not an array:', locationData.selectedLocation);
        // Handle this case based on your application logic
        // For example, you might want to set a default value for selectedLocation or skip processing for this location
      }
      
      // Get Bid value from testLocation array
      const Bid = this.testLocation[index].id;
      
      // Add location data to offline array
      offline.push({
        BranchId: Bid,
        slots: selectedLocation
      });
    });
    

    console.log('Offline:', offline);


    // Reset form or perform other actions after submission
  } else {
    // Handle form validation errors if needed
  }
}


  reset(){
    this.categoryForm.reset();
  }


}
export interface category{
  name: string,
  teluguName: string,
  academicYearId: 0,
  description: string
}
