import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../login.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  logginUserData: any;
  constructor(
    private router: Router,private loginService: LoginService,
  ){

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
   if(localStorage.getItem('token')){
    this.logginUserData = (localStorage.getItem('userData'));
    this.logginUserData = JSON.parse(this.logginUserData)
    if(this.logginUserData.roleId == 1 && state.url.includes('Admin'))
    {
      return true;
    }
    else{
      this.router.navigate(['/home']);
      return false;
    }
   }
   else{
    localStorage.clear();
    this.router.navigate(['/home']);
    return false;
   }
  } 
}
