import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';
import { map } from 'rxjs/operators';
import { donate } from '../components/donate/donate';
import { events } from '../components/events/event';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: SharedService) { }

  createOrder(enrollmentIds:any){
    let url = 'api/Payment/order';
    return this.http.post(url,enrollmentIds)
    .pipe(map((data: any) => data));
  }

  approveOrder(orderId:any){
    let url = `api/Payment/capture?orderId=${orderId}`;
    return this.http.post(url,orderId)
    .pipe(map((data: any) => data));
  }

  donate(donationData:donate){
    let url = 'api/Home/Donation';
    return this.http.post(url,donationData)
    .pipe(map((data: any) => data));
  }
  eventDonate(eventObj:events){
    //change this after getting api
    let url = 'api/Home/Donation';
    return this.http.post(url,eventObj)
    .pipe(map((data: any) => data));
  }
  eventTicket(ticketDetails:any){
    let url = 'api/Events/order';
    return this.http.post(url,ticketDetails)
    .pipe(map((data: any) => data));
  }

  approveTicket(id:any,ticketDetails:any){
    let url = `api/Events/capture/?orderId=${id}`;
    return this.http.post(url,ticketDetails)
    .pipe(map((data: any) => data));
  }
  getPrice(){
    let url = `api/Events/unitprice`
    return this.http.get(url)
    .pipe(map((data: any) => data));
  }
  verifyUser(ticketDetails:any){
    let url = `api/Events/verifyuser`
    return this.http.post(url,ticketDetails)
    .pipe(map((data: any) => data));
  }
 
}
