<div class="container-fluid p-3">
    <div class="row">
        <!-- <div class="col-xs-12 col-lg-8 p-0 largeScreen mediumScreen smallScreen extrasmallScreen" style="overflow-x: hidden ; overflow-y: scroll; white-space: nowrap; " >
            <a href="/manaUgadi2024">
            <img src="assets/images/UgadiInvitaionPic.jpg" alt="Ugadi Invitation" class="image" style="width: 100%; height: auto;" />
            </a>
        </div> -->
        
        
        
        <div class="col-xs-12 col-lg-8 carousal_container p-0">
            <!-- <marquee width="100%" class="marqueeHeight" direction="left" height="50px">
                <div class="row">
                    <div class="col-2 col-lg-1 pt-md-1  text-center">
                        <img src="assets/images/Alert.gif" style="width: 57px;" alt="Alert" class="img-fluid gifWidth"/>
                    </div>
                    <div class="col-10 col-lg-11 p-0 pt-lg-3 pt-md-3">
                        <span class="text-danger fw-semibold textFont " style="font-size: 16px;">
                            Please register before July 15, 2024. Fees will increase to $175 after this date.
                        </span>
                    </div>
                </div>
            </marquee> -->
            
            <p-galleria class="imageCarousal" [value]="carousalImages"  [autoPlay]="true"  [circular]="true" [responsiveOptions]="responsiveOptions" [containerStyle]="{ 'max-width': '640px' }"
            [showItemNavigators]="true" [showThumbnails]="false"  [containerStyle]="{ 'max-width': '100%' }">
                <ng-template pTemplate="item" let-item>
                    <img [src]="item.imagepath" class="carousal_images" />
                </ng-template>
                <!-- <ng-template pTemplate="caption" let-item >
                    <button *ngIf="item.showButton" pButton pRipple type="button" label="Register" class="bg-strawberry p-button-danger register_btn" (click)="navigateToRegistration()"></button>
                </ng-template> -->
            </p-galleria>
        </div>
        <div class="col-xs-12 col-lg-4  ps-0 pe-0 pt-3 pt-md-0 pe-md-0 ps-lg-3">
            <div class="p-3 rounded bg-white card_view shadow h-100">
                <h6 class="fw-bold">Latest News</h6>
                   <hr class="border-bottom breakLine m-0 my-1">
                   <div class="row text-center h-100" *ngIf="loader">
                    <div class="col-12 d-flex align-items-center justify-content-center">
                        <p-progressSpinner class="custom-spinner"  strokeWidth="3"
                        animationDuration=".5s" ></p-progressSpinner>
                    </div>
                   </div>
                   <div class="row" *ngFor="let news of latestNews">
                        <div class="col-12">
                            <h6 class="fw-bold pt-3 text-danger">{{news?.heading | uppercase}}</h6>
                        </div>
                        <div class="col-12">
                            <p class="text-wrap mb-1">{{news?.description}}</p>
                        </div>
                        <div class="col-12">
                           <a [href]="news?.linkUrl" class="blue-berry fw-semibold">{{news?.linkText}}</a>
                        </div>
                   </div>
                   <div class="row pt-3" *ngIf="latestNews.length == 0 && !loader">
                        <div class="col-12">
                            <p>No news available</p>
                        </div>
                   </div>
            </div>
        </div>
    </div>
    <div class="row pt-4">
        <div class="col-12 rounded bg-white card_view shadow p-4">
            <div class="row">
                <div class="col-12">
                    <h6 class="fw-bold">Who We Are | మా గురించి</h6>
                </div>
            </div>
            <hr class="border-bottom breakLine m-0 my-2">
            <div class="row">
                <div class="col-12">
                    <p>Here at Mana Samskriti, our collective vision is to serve the community by imparting the language
                        and cultural aspects of India so as to imbibe the richness and diversity of that tradition and
                        spark the innate, creative intent inside everyone.</p>
                </div>
            </div>
            <hr class="border-bottom breakLine m-0 my-2">
            <div class="row d-flex align-items-center">
                <div class="col-xs-3 col-md-2 px-md-5">
                    <img src="assets/images/aksharaLogo.png" alt="akshara" class="img-fluid p-sm-0 p-5"/>
                </div>
                <div class="col-xs-9 col-md-10">
                    <div class="row">
                        <div class="col-12">
                            <h6 class="fw-bold">Akshara | అక్షర</h6>
                        </div>
                        <div class="col-12">
                            <p>Mana Samskriti’s accredited language teaching program is called Akshara. This popular
                                program has been helping many students all over the world gain Telugu language skills in
                                reading, writing and speaking. In addition, many students obtained high school and
                                college level credits with the skills they gained in Akshara.</p>
                            <p class="text-danger cursor" (click)="navigateToCourses(0)">Read more</p>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="border-bottom breakLine m-0 my-2">
            <!-- <div class="row d-flex align-items-center">
                <div class="col-xs-3 col-md-2 px-md-5">
                    <img src="assets/images/kalaLogo.png" alt="kala" class="img-fluid p-sm-0 p-5" />
                </div>
                <div class="col-xs-9 col-md-10">
                    <div class="row">
                        <div class="col-12">
                            <h6 class="fw-bold">Kala | కళ</h6>
                        </div>
                        <div class="col-12">
                            <p>Mana Samskriti’s instruction in all art forms comes under the umbrella of Kala. From time
                                to time, we offer classes in dance (natya) and music (swara).</p>
                            <p class="text-danger cursor" (click)="navigateToCourses(1)">Read more</p>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    <div class="row pt-4">
        <div class="col-xs-12 col-md-3 rounded card_view shadow bg-pistaGreen p-3 text-center d-flex align-items-center justify-content-center flex-column">
            <h6 class="dark-pista fs-5 fw-semibold ">ఆనందం మనకు లభించే <br>దాని నుండి కాదు, మనం <br>ఇచ్చే దాని నుండి వస్తుంది</h6>
            <button pButton pRipple type="button" label="Donate Now" class="btn-green p-button-success btn-lg mt-5" (click)="navigateToDonate()"></button>
        </div>
        <div class="col-xs-12 col-md-9  ps-0 pe-0 pt-3 pt-md-0 pe-md-0 ps-md-3">
            <div class="p-3 px-5 rounded bg-white card_view shadow">
                <h5 class="amita text-danger fw-bold pb-3 fs-5">Best Telugu Classes with Integrated Curriculum in Reading, Writing
                    and Speaking</h5>
                <p class="text-dark fw-bold d-flex align-items-center m-0"><img src="assets/images/checkmark.svg"
                        alt="checkmark" /><span class="p-2">University Approved Professional Curriculum</span></p>
                <p class="text-dark fw-bold d-flex align-items-center m-0"><img src="assets/images/checkmark.svg" alt="checkmark" /><span class="p-2">   High
                        Quality Books & Simplified Lesson Plans</span></p>
                <p class="text-dark fw-bold d-flex align-items-center m-0"><img src="assets/images/checkmark.svg"
                        alt="checkmark" /><span class="p-2">   Curriculum Designed for Getting High School Credits</span></p>
                <p class="text-dark fw-bold d-flex align-items-center m-0"><img src="assets/images/checkmark.svg"
                        alt="checkmark" /><span class="p-2"> Speaking Exercises, Kahoot, Quizzes, Puzzles & more..</span></p>
                <p class="text-dark fw-bold d-flex align-items-center m-0"><img src="assets/images/checkmark.svg" alt="checkmark" /><span class="p-2">   All
                        our classes now available for online instruction</span></p>
            </div>
        </div>
    </div>
    <div class="row mt-4 rounded bg-white card_view shadow p-2 d-flex align-items-center">
        <div class="col-xs-12 col-md-3 text-center px-5">
            <img src="assets/images/certificateLogo.png" alt="WASC Certificate" class="img-fluid px-4" />
        </div>
        <div class="col-xs-12 col-md-9">
            <p class="fw-bold mb-1">We, at Akshara school of Mana Samskriti, proudly celebrate recent accreditation of our<br>
                Supplementary Education Program by</p>
            <p><small>Accrediting Commission for Schools, Western Association of Schools and Colleges<br>
                533 Airport Blvd, Suite 200,<br>
                Burlingame, CA 94010 | www.acswasc.org</small>
            </p>
        </div>
    </div>
</div>