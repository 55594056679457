import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  items: MenuItem[] =[];
  activeIndex: number = 0;
  completed=0;

  constructor() { }

  ngOnInit(): void {
    this.items = [
      {
          label: 'Parent Details',
          routerLink: 'parent-details',
          command: (event: any) => {
            this.activeIndex = 0;
            this.completed=0;
          },
      },
      {
          label: 'Student Details',
          routerLink: 'student-details',
          command: (event: any) => {
            this.activeIndex = 1;
            this.completed=1;
          },
      },
      {
          label: 'Course Details',
          routerLink: 'course-details',
          command: (event: any) => {
            this.activeIndex = 2;
            this.completed=2;
          },
      },
      {
          label: 'Payment & Confirmation',
          routerLink: 'payment-details',
          command: (event: any) => {
            this.activeIndex = 3;
            this.completed=3;
          },
      }
  ];
  }

  onActiveIndexChange(event: number) {
    this.activeIndex = event;
  }


}
