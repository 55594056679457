import { Component,OnInit,} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CourseDetailsService } from 'src/app/services/course-details.service';
import { HomeService } from 'src/app/services/home.service';
import { LoggingService } from 'src/app/services/logging.service';


@Component({
  selector: 'app-all-course-details',
  templateUrl: './all-course-details.component.html',
  styleUrls: ['./all-course-details.component.css']
})
export class AllCourseDetailsComponent implements OnInit {
  courseTypeArray = [{"id":"1","label":"Telugu"},];
  countryApiArray:any
  // {"id":"2","label":"Sanskrit"}
  errorMessage:any;
  selectedCourseType:any;
  storeAcademicyearId:any;
  courseData: any;
  allCoursesCopyObj: any;
  activeIndex:number = 0;
  loader: boolean = true;
  academicYearsList: any;
  selectedAcademicYear: any;
defaultCountry: any;
  constructor(private courseDetails: CourseDetailsService, private home: HomeService,private appinsightsService: LoggingService, private route:ActivatedRoute,  private messageService: MessageService,
    private confirmationService: ConfirmationService,) { }
  selectedCountryId: number = 1;


  ngOnInit(): void {
    try{
     this.getAcademicYears()
    
    this.appinsightsService.logEvent('all courses screen viewed')
    this.route.params.forEach((urlParams:any) => {
      this.activeIndex = urlParams['id'];
    })
     this.selectedCourseType = this.courseTypeArray[0]
    this.getCountries();
  
   
    //  this.home.allCourses.subscribe((data:any)=>{
    //   if(data != null){
    //     this.courseData = data.result;
    //     this.loader = false;
    //     this.allCoursesCopyObj = JSON.parse(JSON.stringify(this.courseData));
    //     this.filterCourses();
    //   }
    //   else{
    //     this.getAcademicYears();
    //   }
    //  })
   
    }
    catch(error:any){
      this.appinsightsService.logException(error,2)
    }

    
  }


  selectedCountry(countryID:any){
   this.selectedCountryId = countryID
    this.getCourseDetails(this.storeAcademicyearId?.id,countryID)
  }

  getAcademicYears() {
    try {
      this.loader = true;
      this.courseDetails.getAcademicYears().subscribe((data: any) => {
        this.loader = false;
        this.academicYearsList = data.result;
        this.academicYearsList.forEach((item: any) => {
          if (item.isRegistrationsOpen == true) {
            this.selectedAcademicYear  = item;
          }
        });
        this.storeAcademicyearId = this.selectedAcademicYear
        this.getCourseDetails(this.selectedAcademicYear.id,this.selectedCountryId)
        // this.getCategoriesDetails(null, this.selectedAcademicYear.id);
      });
    } catch (error: any) {
      if(error.status == 401){
        localStorage.clear()
        this.confirmationService.confirm({
          message: 'Apologies, but your session has timed out. To proceed, please log in again.',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          key:'toastMsg',
          accept: () => {
           localStorage.clear()

          },
        })
      }
      else if(error.status == 404){
       this.messageService.add({
         severity:'warn',
         summary: 'Warning',
         detail: error.message
       });
      }
      else if(error.status == 500){
        this.messageService.add({
          severity:'warn',
          summary: 'Warning',
          detail: "Something went wrong at server side!"
        });
      }
      else{
        this.messageService.add({
          severity:'warn',
          summary: 'Warning',
          detail: error.message
        });
      }
      this.appinsightsService.logException(error);
    }
  }

  getCountries(){
    try{
      this.loader = true;
      this.courseDetails.getCountry().subscribe((data)=>{
        this.loader = false;
       this.countryApiArray=data.result;
       this.defaultCountry= this.countryApiArray[0];
      },(error:any)=>{
        if(error.status == 401){
          localStorage.clear()
          this.confirmationService.confirm({
            message: 'Apologies, but your session has timed out. To proceed, please log in again.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            key:'toastMsg',
            accept: () => {
             localStorage.clear()
            },
          })
        }
        else if(error.status == 404){
         this.messageService.add({
           severity:'warn',
           summary: 'Warning',
           detail: error.message
         });
        }
        else if(error.status == 500){
          this.messageService.add({
            severity:'warn',
            summary: 'Warning',
            detail: "Something went wrong at server side!"
          });
        }
        else{
          this.messageService.add({
            severity:'warn',
            summary: 'Warning',
            detail: error.message
          });
        }
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: Getting error in get all courses Country list api", {error:JSON.stringify(error)})
      })
    }
    catch(error:any){
      this.appinsightsService.logException(error,2)
    }
  }

  getCourseDetails(academicYearId:any,countryID:any){
    this.errorMessage = undefined;
    try{
      this.loader = true;
      this.courseDetails.getAllCoursesData(academicYearId,countryID).subscribe((data)=>{
        this.loader = false;
        this.courseData = data.result;
        this.allCoursesCopyObj =  JSON.parse(JSON.stringify(this.courseData));
        this.selectedCourseType = this.courseTypeArray[0];
        this.filterCourses();
      },(error:any)=>{
        this.loader = false;
         if(error.status == 404){
          this.courseData = undefined;
               this.errorMessage = error?.error?.title
         }
        this.appinsightsService.logException(error,1)
        this.appinsightsService.logEvent("Error: Getting error in get all courses(menu item) list api", {error:JSON.stringify(error)})
      })
    }
    catch(error:any){
      this.appinsightsService.logException(error,2)
    }
  }

  filterCourses(){
    this.courseData =  JSON.parse(JSON.stringify(this.allCoursesCopyObj));
    const data = this.courseData[0]?.courses;
    if(this.selectedCourseType?.id == 1){ 
      const filteredData = data?.filter((x:any)=> x.courseName.includes("Telugu"));
      this.courseData[0].courses = filteredData;
    }
    // else{
    //   const filteredData = data.filter((x:any)=> x.courseName.includes("Sanskrit"));
    //   this.courseData[0].courses = filteredData;
    // }

  }

  navigateToLocation(link:string){
    window.open(link, "_blank");
  }
}