<div class="container-fluid">
    <div class="row" *ngIf="addedCoursesList.length > 0">
        <div class="col-12 p-3 text-center" *ngIf="showPaymentBlock">
            <h4>You are registering below courses for Student <span class="text-danger">{{selectedStudentName}}</span></h4>
        </div>
       
            <div class="col-12 p-3 bg-light-pista border dark-pista rounded"  *ngIf="!showPaymentBlock">
                <h6><img src="assets/images/checkmark.svg" alt="checkmark" />
                    <span class="p-2 m-2 fw-semibold text-dark">Payment Approved</span>
                </h6>
                    <p>
                    You have been registered successfully for {{this.selectedAcademicYear?.name}} academic year with PayPal Order ID #{{orderId}}
                    <br>
                    We have also sent an email with the list of registered courses in this transaction.
                </p>
            </div>
            <div class="col-12 p-3" *ngIf="!showPaymentBlock">
                <h4>Registered Courses for <span class="text-danger">{{selectedStudentName}}</span></h4>
            </div>
        
        <div class="col-12 p-3">
            <p-table [tableStyle]="{ 'min-width': '50rem' }" [value]="addedCoursesList">
                <ng-template pTemplate="header">
                    <tr>
                        <th class="bg-white">S.No</th>
                        <th class="bg-white">Type</th>
                        <th class="bg-white">Location</th>
                        <th class="bg-white">Category</th>
                        <th class="bg-white">Course Name</th>
                        <th class="bg-white">Timings</th>
                        <th class="bg-white">Amount</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-course let-rowIndex="rowIndex">
                    <tr *ngIf="addedCoursesList.length == 0">
                        <p>No courses added</p>
                    </tr>
                    <tr *ngIf="addedCoursesList.length > 0">
                        <td>{{rowIndex + 1 }}</td>
                        <td>{{ course.courseType.label }}</td>
                        <td *ngIf="course?.courseType.id==2; else showCountry">{{ course?.locationName?.branchName }}</td>
                        <ng-template #showCountry [ngSwitch]="this.countryId">
                            <td *ngSwitchCase="1">USA</td>
                            <td *ngSwitchCase="2">Khuwait</td>
                            <td *ngSwitchCase="3">Canada</td>
                            <td *ngSwitchCase="4">UK</td>
                            <td *ngSwitchDefault></td>
                    </ng-template>
                        <td>{{ course.category.label }}</td>
                        <td>{{ course.coursename.label }}</td>
                        <td>{{ course.timings.name }}</td>
                        <td>${{ course.amount }}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr>
                        <!-- <td class="bg-white"></td>
                        <td class="bg-white"></td>
                        <td class="bg-white"></td>
                        <td class="bg-white"></td>
                        <td class="bg-white"></td> -->
                        <td class="bg-white text-end amoutText"  [colSpan]="6">Shipping Charge</td>
                        <td class="bg-white amoutText">${{this.shippingAmount}}</td>
                    </tr>
                    <tr>
                        <!-- <td class="bg-white"></td>
                        <td class="bg-white"></td>
                        <td class="bg-white"></td>
                        <td class="bg-white"></td>
                        <td class="bg-white"></td> -->
                        <td class="bg-white text-end"  [colSpan]="6">Total</td>
                        <td class="bg-white">${{totalAmount}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="col-12 p-3 text-center" *ngIf="!showPaymentBlock">
            <button pButton pRipple type="button" label="Back to Home" class="btn-blue p-0 px-4 py-2" (click)="navigateToHome()"></button>
        </div>
        
        <div *ngIf="showPaymentBlock">
            <div class="col-12 text-center">
                <p>Please proceed and pay using your PayPal account or any debit/credit card of your choice.</p>
            </div>
            <div class="col-12 p-3 d-flex align-items-center justify-content-center w-100">
                <div id="paypal-button-container" style="width:500px;"></div>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-center">
                <p class="text-danger">{{showError}}</p>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="addedCoursesList.length == 0">
        <div class="col-12 text-center">
            <p class="text-danger">NO COURSES ADDED</p>
        </div>
    </div>
    <div class="row" *ngIf="showPaymentBlock">
        <div class="col-12 text-end">
            <a routerLink="/registration/course-details"><span
                    class="text-danger d-inline-block">Previous</span></a>
        </div>
    </div>


    <div class="row">
        <div class="col text-center">
            <p-progressSpinner *ngIf="loader" class="custom-spinner"  strokeWidth="3"
            animationDuration=".5s" ></p-progressSpinner>
            <p *ngIf="loader">Please Wait...</p>
        </div>
    </div>
</div>