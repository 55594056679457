<div class="container-fluid">
    <p-toast position="top-center"></p-toast>
    <div class="row">
        <div class="col-12 text-center py-3">
            <h4 class="fw-semibold m-0">Join as a Volunteer</h4>
        </div>
    </div>
    <div class="row px-md-5">
        <div class="col-md-6 offset-md-3">
            <form [formGroup]="joinasVolunteerForm" (ngSubmit)="onSubmit(joinasVolunteerForm.value)">
                <div class="row p-4 rounded bg-white card_view shadow">
                    <div class="col-12 p-2 px-md-5">
                        <label for="firstname" class="dark-ask">First Name<span class="text-danger"> *</span></label>
                        <input pInputText name="firstname" id="firstname" type="text" class="clsControl form-control"
                            formControlName="firstname" />
                        <div class="text-danger">
                            <span class="text-capitalize">{{validation.validateFormControl('firstname','First Name', joinasVolunteerForm) }}</span>
                        </div>
                    </div>
                    <div class="col-12 p-2 px-md-5">
                        <label for="lastname" class="dark-ask">Last Name<span class="text-danger"> *</span></label>
                        <input pInputText name="lastname" id="lastname" type="text" class="clsControl form-control"
                            formControlName="lastname"  />
                        <div class="text-danger">
                            <span class="text-capitalize">{{ validation.validateFormControl('lastname','Last Name', joinasVolunteerForm) }}</span>
                        </div>
                        <div class="text-danger" >
                            <span class="text-capitalize">{{ validation.validateNames(joinasVolunteerForm, 'firstname', 'lastname') }}</span>
                        </div>
                    </div>
                    <div class="col-12 p-2 px-md-5">
                        <label for="email" class="dark-ask">E-Mail<span class="text-danger"> *</span></label>
                        <input pInputText name="email" id="email" type="text" class="clsControl form-control"
                            formControlName="email"  placeholder="Eg: abc@gmail.com" />
                        <div class="text-danger">
                            <span class="text-capitalize">{{ validation.validateFormControl('email','Email',  joinasVolunteerForm) }}</span>
                        </div>
                    </div>
                    <div class="col-12 p-2 px-md-5">
                        <label for="phonenumber" class="dark-ask">Phone Number<span class="text-danger"> *</span></label>
                        <input pInputText name="phonenumber" id="phonenumber" type="text"
                            class="clsControl form-control" formControlName="phonenumber" placeholder="Eg: 4251234567"/>
                        <div class="text-danger">
                            <span class="text-capitalize">{{ validation.validateFormControl('phonenumber','Phone Number',  joinasVolunteerForm) }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 p-3 text-end">
                            <a (click)="reset()"><span
                                    class="text-danger d-inline-block p-3 cursor">Reset</span></a>
                            <button pButton pRipple type="submit" label="Submit" class="btn-blue  btn-lg"></button>
                            <p-progressSpinner *ngIf="loader" class="custom-spinner"  strokeWidth="3"
                                animationDuration=".5s" ></p-progressSpinner>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>