import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { HomeService } from 'src/app/services/home.service';
import { LoggingService } from 'src/app/services/logging.service';
import { LoginService } from 'src/app/services/login.service';
import { ParentDetailsService } from 'src/app/services/parent-details.service';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  RoleBasedAccessForm: FormGroup;
  parentLoginForm: FormGroup;
  adminLoginForm: FormGroup;
  otpValidationForm: FormGroup;
  forgotPasswordForm: FormGroup;
  createPasswordForm: FormGroup;
  logginUserData:any
  selectedrole: any;
  showParentForm: boolean = true;
  showAdminForm: boolean = false;
  showOTPForm: boolean = false;
  showForgotPasswordForm: boolean = false;
  showcreatePasswordForm: boolean = false;
  loader: boolean = false;
  forgotPassword_flag: boolean = false;
  enteredEmail: string = '';
  invalidCredentials: string = '';
  otpError:boolean=false;
  otpErrorMsg: string = '';
  passwordErr: string = '';
  roles: { id: string; label: string }[] = [
    { id: 'parent', label: 'Parent' },
    { id: 'admin', label: 'Admin' },
  ];
  constructor(
    private fb: FormBuilder,
    private appinsightsService: LoggingService,
    public validation: ValidationService,
    private parentDetails: ParentDetailsService,
    private loginService: LoginService,
    private messageService: MessageService,
    private homeDetails: HomeService,
    private router: Router,
  ) {
    this.RoleBasedAccessForm = this.fb.group({
      roleType: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
    });
    this.parentLoginForm = this.fb.group({
      email: new FormControl(
        null,this.emailValidator()
      ),
    });
    this.otpValidationForm = this.fb.group({
      firstDigit: new FormControl(null, this.otpValidator()),
      secondDigit: new FormControl(null, this.otpValidator()),
      thirdDigit: new FormControl(null, this.otpValidator()),
      fourthDigit: new FormControl(null, this.otpValidator()),
    });
    this.adminLoginForm = this.fb.group({
      email: new FormControl(
        null,this.emailValidator()
      ),
      password: new FormControl(
        null,
        Validators.compose([Validators.required])
      ),
    });
    this.forgotPasswordForm = this.fb.group({
      email: new FormControl(
        null,this.emailValidator()
      ),
    });
    this.createPasswordForm = this.fb.group({
      newPw: new FormControl(
        null,
        Validators.compose([Validators.required, Validators.minLength(6)])
      ),
      confirmPassword: new FormControl(
        null,
        Validators.compose([Validators.required, Validators.minLength(6)])
      ),
    });
  }

  ngOnInit(): void {
    try {
      this.selectedrole = this.roles[0];
    } catch (error: any) {
      this.appinsightsService.logException(error, 1);
    }
  }
  otpValidator() {
    return Validators.compose([
      Validators.required,
      Validators.pattern('[0-9]'),
      Validators.maxLength(1),
      Validators.minLength(1),
    ]);
  }
  emailValidator(){
    return Validators.compose([
      Validators.required,
      Validators.pattern(
        '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'
      ),
    ])
  }
  selectRole() {
    try {
      this.otpError=false;
      this.selectedrole.id == 'admin'
        ? ((this.showAdminForm = true), (this.showParentForm = false))
        : ((this.showParentForm = true), (this.showAdminForm = false));
      this.showOTPForm = false;
      this.showForgotPasswordForm = false;
      this.showcreatePasswordForm = false;
      this.parentLoginForm.reset();
      this.adminLoginForm.reset();
    } catch (error: any) {
      this.appinsightsService.logException(error, 1);
    }
  }
  onParentLogin(formData: any) {
    try {
      this.invalidCredentials = '';
      this.forgotPassword_flag = false;
      if (this.parentLoginForm.valid) {
        this.loader = true;
        this.parentDetails.checkParentExists(formData.email).subscribe(
          (data) => {
            if (data.statusCode == 200) {
              this.sendOTP(formData.email);
            }
          },
          (error: any) => {
            this.loader = false;
            this.invalidCredentials = "Invalid Credentials"
            this.appinsightsService.logException(error, 1);
            this.appinsightsService.logEvent(
              'Error: Getting error in verify Parent api',
              {
                payload: JSON.stringify(formData.email),
                error: JSON.stringify(error),
              }
            );
          }
        );
      } else {
        this.parentLoginForm.controls['email'].markAsDirty();
      }
    } catch (error: any) {
      this.appinsightsService.logException(error, 1);
    }
  }
  onAdminLogin(formData: any) {
    try {
      this.invalidCredentials = '';
      this.forgotPassword_flag = false;
      if (this.adminLoginForm.valid) {
        this.loader = true;
        const adminObj = {
          email: formData?.email,
          password: formData?.password,
        };
        this.loginService.adminLogin(adminObj).subscribe(
          (data: any) => {
            if (data.statusCode == 200) {
              this.sendOTP(adminObj.email);
            }
          },
          (error: any) => {
            this.appinsightsService.logException(error, 1);
            this.appinsightsService.logEvent(
              'Error: Getting error in admin login api',
              {
                payload: JSON.stringify(adminObj),
                error: JSON.stringify(error),
              }
            );
            if (error.status == 500) {
              this.messageService.add({
                severity: 'warn',
                summary: 'Warning',
                detail: 'Something went wrong at server side!',
              });
            } else {
              this.invalidCredentials = 'Invalid Credentials';
            }
            this.loader = false;
          }
        );
      } else {
        this.loader = false;
        this.adminLoginForm.controls['email'].markAsDirty();
        this.adminLoginForm.controls['password'].markAsDirty();
      }
    } catch (error: any) {
      this.loader = false;
      this.appinsightsService.logException(error, 1);
    }
  }

  sendOTP(email: string) {
    try {
      this.loader = true;
      this.otpValidationForm.reset();
      this.otpErrorMsg = '';
      let userData = {
        email: email,
      };
      this.enteredEmail = email;
      this.parentDetails.verifyParent(userData).subscribe(
        (data) => {
          if (data.statusCode == 200) {
            this.loader = false;
            this.showOTPForm = true;
            this.showParentForm = false;
            this.showAdminForm = false;
            this.showForgotPasswordForm = false;
            this.showcreatePasswordForm = false;
          }
        },
        (error: any) => {
          this.appinsightsService.logException(error, 1);
          this.appinsightsService.logEvent(
            'Error: Getting error in sending OTP api',
            { payload: JSON.stringify(userData), error: JSON.stringify(error) }
          );
          if (error.status == 500) {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: 'Something went wrong at server side!',
            });
          } else {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning',
              detail: error.error.title,
            });
          }
          this.loader = false;
        }
      );
    } catch (error: any) {
      this.loader = false;
      this.appinsightsService.logException(error, 1);
    }
  }
  otpController(event: any, next: any, prev: any, index: number) {
    try {
      if (event.target.value && next) {
        next.focus();
        return;
      } else if (event.target.value && !next) {
        document.getElementById('continueOtpVerify')?.focus();
        return;
      } else if (event.target.value == '') {
        prev.focus();
        return;
      }
    } catch (error: any) {
      this.appinsightsService.logException(error, 2);
    }
  }
  onOTPValidation(formData: any) {
    try{
      this.otpError = true;
      if (this.otpValidationForm.valid) {
        this.otpError = false;
        this.loader = true;
        let verifyOtp = {
          "email": this.enteredEmail,
          "otp": formData.firstDigit+formData.secondDigit+formData.thirdDigit+formData.fourthDigit
        }
        this.parentDetails.verifyOtp(verifyOtp).subscribe(async (data)=>{
          this.otpError = false;
          if(data.statusCode == 200){
            localStorage.setItem("email", JSON.stringify(this.enteredEmail))
            localStorage.setItem("token", JSON.stringify(data.result.token))
            localStorage.setItem("isValidationNotRequried", JSON.stringify(data?.result?.isExistingUser));
            await this.parentDetails.getParentDetails().subscribe((data)=>{
              if(data.statusCode == 200){
                localStorage.setItem("userData", JSON.stringify( data.result))
            this.loader= false;
            if(this.forgotPassword_flag){
              this.showOTPForm = false;
              this.showParentForm = false;
              this.showAdminForm = false;
              this.showForgotPasswordForm = false;
              this.showcreatePasswordForm = true;
            }
            else{
              this.homeDetails.isUserLoggedIn.next(true);
              this.logginUserData = (localStorage.getItem('userData'));
              this.logginUserData = JSON.parse(this.logginUserData)
              const value: any  = (localStorage.getItem('token'));
              let token =  value? JSON.parse(value): "";
              if(token && this.logginUserData.roleId==1){
                this.router.navigate(['Admin/enrollments'])
              }else{
                this.router.navigate(['/home'])
              }
             
            }
          }})
          }
        },(error:any)=>{
          this.otpError = false;
          if(error.error.statusCode == 400){
            this.otpErrorMsg = error.error.responseException;
            this.otpValidationForm.reset();
          }
          this.loader= false;
          this.appinsightsService.logException(error,1);
          this.appinsightsService.logEvent("Error: Getting error in otp submit api", {  payload: JSON.stringify(verifyOtp),error:JSON.stringify(error)})
        })
      }
    }
    catch (error: any) {
      this.appinsightsService.logException(error, 2);
    }
    
  }
  ForgotPassword() {
    try {
      this.loader = false;
      this.forgotPasswordForm.reset();
      this.showOTPForm = false;
      this.showParentForm = false;
      this.showAdminForm = false;
      this.showForgotPasswordForm = true;
      this.showcreatePasswordForm = false;
    } catch (error: any) {
      this.loader = false;
      this.appinsightsService.logException(error, 2);
    }
  }
  onResetPassword(formData: any) {
    try {
      if (this.forgotPasswordForm.valid) {
        this.loader = true;
        this.loginService.passwordResetEmail(formData.email).subscribe((data)=>{
          if(data.statusCode == 200){
            this.loader = false;
            this.forgotPassword_flag = true;
            this.sendOTP(formData.email);
          }
        },(error:any)=>{
          if(error.status == 400){
            this.invalidCredentials = "Invalid Email"
          }
          this.loader= false;
          this.appinsightsService.logException(error,1);
          this.appinsightsService.logEvent("Error: Getting error in get OTP for forgot password api", {  payload: JSON.stringify(formData.email),error:JSON.stringify(error)})
        })
      } else {
        this.loader = false;
        this.forgotPasswordForm.controls['email'].markAsDirty();
      }
    } catch (error: any) {
      this.loader = false;
      this.appinsightsService.logException(error, 2);
    }
  }
  onCreateNewPassword(formData: any) {
    try {
      this.loader = true;
      if (this.createPasswordForm.valid) {
        if(formData.newPw.trim() != formData.confirmPassword.trim()){
          this.loader = false
            this.passwordErr = "New Password and Confirm Password should be same";
            return;
        }
        const passwordObj = {
          "password": formData.newPw,
          "confirmPassword":formData.confirmPassword
        }
        this.loginService.createNewPassword(passwordObj).subscribe((data)=>{
          this.loader = false;
          if(data.statusCode == 200){
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Password Reset Successful',
            });
            this.showOTPForm = false;
            this.showParentForm = false;
            this.showAdminForm = true;
            this.showForgotPasswordForm = false;
            this.showcreatePasswordForm = false;
          }
        },(error:any)=>{
          this.loader = false;
          if(error.status == 400){
            this.passwordErr = error.error.title;
          }
          this.appinsightsService.logException(error,1);
          this.appinsightsService.logEvent("Error: Getting error in reset password api", {  payload: JSON.stringify(passwordObj),error:JSON.stringify(error)})
        })
      } else {
        this.loader = false;
        this.forgotPasswordForm.controls['email'].markAsDirty();
      }
    } catch (error: any) {
      this.loader = false;
      this.appinsightsService.logException(error, 2);
    }
  }
  resetError(){
    this.invalidCredentials = '';
    this.passwordErr = '';
    this.otpErrorMsg='';
  }
}
