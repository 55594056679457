import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  faqArray=[
    {question:"Why should my child join ManaSamskriti classes? ", answer:`<p>There are many reasons. See below for a few highlights.</p><p><b>Akshara – Telugu Classes</b></p><ul><li>Integrated curriculum – We use the same common vocabulary and sentence construction methods across all aspects of language learning – speaking, reading, and writing. For example, our speaking workshops use the same vocabulary that students read and write in classes. Our lessons are based on same set of common conversational verb and prepositional forms we use in workshops.  This helps in building language skills uniformly and quickly. </li><li> High School Language Credits – Our curriculum is designed to prepare a student for Telugu credits in high school. In fact, many of our students already got these credits through Mana Samskriti education. </li><li>University Approved – Our curriculum has been approved by Nannaya University and passing students get certificates with the university emblem. </li><li> Run Like a Regular School – We follow specific processes for attendance, homework, testing, entrance tests etc.</li></ul><p><b><a href='${window.location.origin}/registration/parent-details' class='text-decoration-underline'>Register Today!  </a></b></p><p><b>Akshara – Telugu Classes</b></p><ul><li>Experienced Professionals – Kala classes are conducted by experienced professionals with credentials in the art forms and stage performances. </li><li>All Under One Roof – For your convenience, both Kala and Akshara classes are conducted in the same place. This helps minimize run-around time between classes for parents. </li></ul>`},
    {question:"Do you have a sibling discount?", answer:"<p>Sorry, not at this time. </p>"},
    {question:"At what times are the classes conducted?", answer:`<p>Our classes are conducted on Saturdays between the hours of 9am-1pm at each center. Schedule for individual classes vary based on center, but as much as possible, we plan to stick to the last year’s schedule, which is available at <a href='${window.location.origin}/all-courses/0' class='text-decoration-underline'>${window.location.origin}</a> </p>`},
    {question:"Will there be any changes in schedule for the new academic year? ", answer:"<p>There could be some changes based on facility availability, teacher availability and other factors. However, if we change the timings, we will work with parents to see what works best. Preference will be given to students who register early. We may not be able to make any changes after the classes start. In cases where we absolutely can’t make it fit into your schedule, we will refund the amount. This can be done only within the first month. No refunds are allowed after the first month of classes. </p>"},
    {question:"What level should I register my child for the upcoming academic year?  ", answer:"<p>First, please take a look at our courses page. This should give you a good picture of which level to register at. Student needs to satisfy the pre-requisites to join a level. </p>"},
    {question:"My child is currently enrolled in a Mana Samskriti class. Do I need to register again for the new academic year? ", answer:"<p>Yes, you need to register again. We don’t have a way to move your registration automatically to the new academic year. </p>"},
    {question:"My child is currently enrolled in a Mana Samskriti Akshara (Telugu) class. What level should I register him/her for the upcoming academic year? ", answer:"<p>Register your child at the next level. For example, if your child is going to Level 2 in the previous academic year, register him/her at Level 3 for upcoming academic year. For some reason, if a student doesn’t get passing grade in the final test in May, we can downgrade the level later. </p>"},
    {question:"My child is currently enrolled in a Mana Samskriti Akshara (Telugu) class. What are my options if my child didn’t get a passing grade in the previous academic year? ", answer:"<p>There are two options.</p><ul><li>Student can repeat the same level again. Though this may feel uncomfortable, this is the best way to create a solid learning foundation for the student. </li><li>Student can take an entrance test for the next level. Entrance tests are conducted for any level between levels 2 and 6. </li></ul>"},
    {question:"My child is currently enrolled in Telugu class at a different organization. What level should I register him/her for the upcoming academic year? ", answer:"<p>First, please take a look at our courses page. This should give you a good picture of which level to register at. Student needs to satisfy the pre-requisites to join a level. For levels 2 and above, student needs to pass an entrance test.  </p>"},
    {question:"My child is currently not enrolled in any Telugu class. What level should I register him/her for the upcoming academic year?  ", answer:"<p>First, please take a look at our courses page. This should give you a good picture of which level to register at. Student needs to satisfy the pre-requisites to join a level and this might include passing an entrance test for the level. </p>"},
    {question:"When are the entrance tests conducted? ", answer:"<p>Entrance tests are conducted  during the last 3 weeks of August. Based on the level you registered, you will be notified if the student needs to take an entrance test. The location and timings will also be sent in the notification. </p>"},
    {question:"Is there a fee for the entrance test? ", answer:"<p>Yes, there is a $10 fee per student. This will be collected at the time of test.  </p>"},
    {question:"I have a question that is not answered yet. What do I do? ", answer:"<p>Please send your question to <a href='mailto:info@ManaSamskriti.org' class='text-decoration-underline'>info@ManaSamskriti.org</a> </p>"},
  ]
  constructor() { }

  ngOnInit(): void {
  }

  toggleAccordionTab(faq: any): void {
    faq.expanded = !faq.expanded;
  }

}
