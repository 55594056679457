import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { joinus } from './join-us';
import { ParentDetailsService } from 'src/app/services/parent-details.service';
import { Router } from '@angular/router';
import { HomeService } from 'src/app/services/home.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ValidationService } from 'src/app/services/validation.service';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-join-us',
  templateUrl: './join-us.component.html',
  styleUrls: ['./join-us.component.css'],
})
export class JoinUsComponent implements OnInit {
  joinasVolunteerForm: FormGroup;
  loader: boolean = false;
  nameError: string = "";
  constructor(private fb: FormBuilder,
     private parentDetails: ParentDetailsService,
     private router: Router,
     private homeDetails: HomeService,
     private messageService: MessageService,
     private appinsightsService: LoggingService,
     private confirmationService: ConfirmationService,
     public validation: ValidationService) {
    this.joinasVolunteerForm = this.fb.group({
      firstname: new FormControl(
        null,
        Validators.compose([Validators.required, Validators.maxLength(150),Validators.pattern('^[^a-zA-Z]*[a-zA-Z]+.*$')])
      ),
      lastname: new FormControl(
        null,
        Validators.compose([Validators.required, Validators.maxLength(150),Validators.pattern('^[^a-zA-Z]*[a-zA-Z]+.*$')])
      ),
      phonenumber: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('[0-9]{3}[0-9]{3}[0-9]{4}'),
        ])
      ),
      email: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
        ])
      ),
    });
  }

  ngOnInit(): void {this.appinsightsService.logEvent('Join us as volunteer screen viewed')}


  onSubmit(formdata: any) {
    try {
      if (this.joinasVolunteerForm.valid) {
        this.loader = true;
        let joinusObj:joinus = {
          "firstName": formdata.firstname,
          "lastName": formdata.lastname,
          "phone": formdata.phonenumber,
          "email": formdata.email,
        }
        this.parentDetails.joinAsVolunteer(joinusObj).subscribe((data)=>{
            if(data.statusCode >= 200 && data.statusCode < 300){
              this.loader = false;
              this.joinasVolunteerForm.reset();
              this.messageService.add({
                severity:'success',
                summary: 'Success',
                detail: data.result
              });
            }
        },(error:any)=>{
          this.appinsightsService.logException(error,1)
          this.appinsightsService.logEvent("Error: Getting error in upsert Volunteer api", {payload: JSON.stringify(joinusObj),error:JSON.stringify(error)})
            if(error.status == 500){
                this.messageService.add({
                    severity:'warn',
                    summary: 'Warning',
                    detail: "Something went wrong at server side!"
                });
            }
            else{
                this.messageService.add({
                    severity:'warn',
                    summary: 'Warning',
                    detail: error.error.title
                });
            }
         this.loader = false;
       })
      } else {
        for (const field in this.joinasVolunteerForm.controls) {
          this.joinasVolunteerForm.controls[field].markAsDirty();
        }
      }
    } catch (error:any) {
      this.appinsightsService.logException(error,2)
    }
  }
  reset() {
    this.joinasVolunteerForm.reset();
  }

}
