import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {
  annualCalendar: string = "Calendar 2024-2025"
  constructor() { }

  ngOnInit(): void {
  }

}
