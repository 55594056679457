<div class="Container">
<div class="container-fluid" *ngIf="!paymentConfirmationFlag">
  <p-toast></p-toast>

  <div class="row">
    <div class="col-12 text-center py-3 borderLine">
      <h4 class="fw-semibold m-0 p-2">
        Ugadi and Sri Ramanavami Event Registration 2024
      </h4>
    </div>
  </div>
  <div class="row px-md-5">
    <div class="col-md-6 d-flex pt-5">
      <div>
        <img
          src="assets/images/UgadiInvitaionPic.jpg"
          class="w-100 h-100"
          alt="Ugadi"
        />
      </div>
    </div>
    <div class="col-md-6">
      <form
        [formGroup]="eventForm"
        (ngSubmit)="onSubmit(eventForm.value)"
        action="https://www.paypal.com/donate"
        target="_blank"
      >
        <div class="col-md-12 pt-5 ">
          <div class="row p-lg-4 p-2 pb-5  rounded bg-white card_view shadow">
            <div class="col-12 d-md-flex">
              <div class="col-md-6  col-12 p-2">
                <label for="firstname" class="dark-ask"
                  >First Name<span class="text-danger"> *</span></label
                >
                <input
                  pInputText
                  name="firstname"
                  id="firstname"
                  type="text"
                  [disabled]="hideButton"
                  class="clsControl form-control"
                  formControlName="firstname"
                />
                <div class="text-danger">
                  <span class="text-capitalize">{{
                    validation.validateFormControl(
                      "firstname",
                      "First
                                        Name",
                      eventForm
                    )
                  }}</span>
                </div>
              </div>
              <div class="col-md-6  col-12   p-2">
                <label for="lastname" class="dark-ask"
                  >Last Name<span class="text-danger"> *</span></label
                >
                <input
                  pInputText
                  name="lastname"
                  id="lastname"
                  type="text"
                  [disabled]="hideButton"
                  class="clsControl form-control"
                  formControlName="lastname"
                />
                <div class="text-danger">
                  <span class="text-capitalize">{{
                    validation.validateFormControl(
                      "lastname",
                      "Last Name",
                      eventForm
                    )
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-12 d-lg-flex ">
              <div class="col-lg-6  col-12   p-2">
                <label for="Phone" class="dark-ask"
                  >Phone<span class="text-danger"> *</span></label
                >
                <input
                  pInputText
                  name="Phone"
                  id="Phone"
                  [disabled]="hideButton"
                  type="text"
                  class="clsControl form-control"
                  formControlName="Phone"
                />
                <div class="text-danger">
                  <span class="text-capitalize">{{
                    validation.validateFormControl("Phone", "phone", eventForm)
                  }}</span>
                </div>
              </div>
              <div class="col-lg-6  col-12   p-2">
                <label for="email" class="dark-ask d-md-flex"
                  >Email Address<span class="text-danger"> *</span></label
                >
                <input
                  pInputText
                  name="email"
                  [disabled]="hideButton"
                  placeholder="Eg: abc@gmail.com"
                  id="email"
                  type="text"
                  class="clsControl form-control"
                  formControlName="email"
                />
                <div class="text-danger">
                  <span class="text-capitalize">{{
                    validation.validateFormControl("email", "email", eventForm)
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-12 px-4 pb-lg-3">
              <label for="Address" class="dark-ask"
                >Address<span class="text-danger"> *</span></label
              >
              <textarea
                rows="5"
                [disabled]="hideButton"
                cols="30"
                class="clsControl form-control"
                formControlName="Address"
              ></textarea>

              <div class="text-danger">
                <span class="text-capitalize">{{
                  validation.validateFormControl(
                    "Address",
                    "Address",
                    eventForm
                  )
                }}</span>
              </div>
            </div>
          </div>
        </div>
    <div class="Container pt-5">
          <div class="row  p-lg-5 p-3 rounded bg-white card_view shadow">
            <div class="  border rounded-top p-0">
        <div class="border-bottom p-2">
            <div class="col-12 p-2  ">
              <div class="row">
                <div class="col-6 dark-ask fw-semibold">Details</div>
                <div class="col-4 text-end dark-ask fw-semibold">
                  <span class="text-dark">Quantity</span>
                </div>
              </div>
            </div>
        </div>
        <div class="border-bottom p-2">
            <div class="col-12 p-2 ">
              <div class="row">
                <div class="col-lg-8 col-12 pb-2 pt-2 text-dark">
                  Ugadi and Sri Ramanavami Event Registration 2024
                  <br>
                  <span class="text-danger col-12 pt-1 " style="font-size: 12px;">Ticket price includes dinner  <br>
                   Children 5 and under Free admission </span>
                </div>
                <div class="col-lg-4 custom-width  pt-2 col-12">
                  <div class="flex-auto ">
                    <p-inputNumber
                      [showButtons]="true"
                      [disabled]="hideButton"
                      buttonLayout="horizontal"
                      inputId="horizontal"
                      spinnerMode="horizontal"
                      formControlName="amount"
                      [step]="1"
                      [(ngModel)]="count"
                      [min]="1"
                      decrementButtonClass="p-button-danger"
                      incrementButtonClass="p-button-success"
                      incrementButtonIcon="pi pi-plus"
                      decrementButtonIcon="pi pi-minus"
                    ></p-inputNumber>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div class="border-bottom p-2">
            <div class="col-12  p-3 ">
              <div class="row">
                <div class="col-8 dark-ask fw-semibold">SubTotal</div>
                <div class="col-4">
                  <div clas="d-flex ">
                    <span class="m-1 p-1 dark-ask fw-semibold text-center"
                      >${{ this.count*this.storeUnitData }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div class="border-bottom p-2">
            <div class="col-12 p-3 ">
              <div class="row">
                <div class="col-8 dark-ask fw-semibold">Total</div>
                <div class="col-4">
                  <div clas="d-flex ">
                    <span class="m-1 p-1 dark-ask fw-semibold text-center"
                      >${{ this.count * this.storeUnitData }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
        </div>
        </div>
        <div class="text-end m-0  p-0">

          <div class="pt-3" *ngIf="!this.hideButton">
            <div class="fw-bold">Tickets can be purchased at the event site only</div>
            <p-button label="Continue" class="blue_btn  " hidden="true" (onClick)="onSubmit(eventForm.value)"></p-button>
          </div>
<div *ngIf="this.hideButton">
            <div *ngIf="showPaymentBlock ">
              <div class="col-12 pt-2 text-center">
                <p>
                  Please proceed and pay using your PayPal account or any
                  debit/credit card of your choice.
                </p>
              </div>
              <div
                class="col-12 p-3 d-flex align-items-center justify-content-center w-100"
              >
                <div id="paypal-button-container" style="width: 500px"></div>
              </div>
              <div
                class="col-12 d-flex align-items-center justify-content-center"
              >
                <p class="text-danger">{{ showError }}</p>
              </div>
            </div>
          </div>
          <p-progressSpinner *ngIf="loader" class="custom-spinnerforPayment text-center" strokeWidth="3"
          animationDuration=".5s"></p-progressSpinner>
    
          </div>
     
          </div>
         
        </div>
      </form>
    
    </div>
  </div>

  <p-confirmDialog
    key="positionDialog"
    position="bottom-center"
    rejectButtonStyleClass="p-button-outlined"
  ></p-confirmDialog>

</div>

<div *ngIf="paymentConfirmationFlag">
  <div class="row">
    <div class="col-12 text-center py-3 borderLine">
      <h4 class="fw-semibold m-0 p-2">
        Ugadi and Sri Ramanavami Event Registration 2024
      </h4>
    </div>
  </div>
  <div class="successContainer pt-4 px-5 mx-5 mb-5 pt-5"  >
    <div class="col-12 p-3 bg-light-pista border  rounded"  >
      <h6><img src="assets/images/checkmark.svg" alt="checkmark" />
          <span class="p-2 m-2 fw-semibold text-dark">Payment Approved</span>
      </h6>
          <p>
            You're officially registered for the Ugadi and Sri Ramanavami Event
            Registration 2024 with <b class="text-dark"> Payment id: #{{orderId}}  </b>
            We have also sent an confirmation email with payment transaction details
            and event information.
      </p>
    
    </div>
    
    <div class="pt-5 text-center">
      <p-button label="Back to Home" class="Homebtn" (click)="backtoHome()"></p-button>
     </div>
    </div>
   
</div>


</div>