import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LoggingService } from 'src/app/services/logging.service';
import { PaymentService } from 'src/app/services/payment.service';
import { ValidationService } from 'src/app/services/validation.service';
import { events } from './event';
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/services/shared.service';
import { Router } from '@angular/router';
declare var paypal: any;
@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css'],
})
export class EventsComponent implements OnInit {
  showPaymentBlock: boolean = true;
  showError: string = '';
  storeTicketDetails: any;
  storeUnitData: any;
  hideButton: boolean = false;
  totalAmount: number = 0;
  loader: boolean = false;
  orderId: any = 0;
  showButtons: boolean = true;
  eventForm!: FormGroup;
  flag: boolean = false;
  count: any = 1;
  paymentConfirmationFlag: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private messageService: MessageService,
    private appinsightsService: LoggingService,
    private paymentDetails: PaymentService,
    public validation: ValidationService,
    private sharedService: SharedService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.showButtons = true;

    this.eventForm = this.fb.group({
      firstname: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(150),
          Validators.pattern('^[^a-zA-Z]*[a-zA-Z]+.*$'),
        ])
      ),
      lastname: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(150),
          Validators.pattern('^[^a-zA-Z]*[a-zA-Z]+.*$'),
        ])
      ),
      Phone: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]{10}$'),
        ])
      ),
      email: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'
          ),
        ])
      ),
      Address: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(150),
          Validators.pattern('^[^a-zA-Z]*[a-zA-Z]+.*$'),
        ])
      ),
      amount: new FormControl(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern('^[1-9][0-9]?(.[0-9]{1,2})?$'),
        ])
      ),
    });
    this.getPrice();
  }

  onSubmit(formdata: any) {
    try {
      if (this.eventForm.valid) {
        this.hideButton = true;
        this.loader = true;
        let eventObj: events = {
          firstName: formdata.firstname,
          lastName: formdata.lastname,
          phone: formdata.Phone,
          email: formdata.email,
          address: formdata.Address,
          quantity: formdata.amount,
          subtotal: formdata.amount * this.storeUnitData,
          total: formdata.amount * this.storeUnitData,
          EventId: 2,
          unitPrice: this.storeUnitData,
        };
        this.storeTicketDetails = eventObj;
        this.loader = true;
        try{       
          this.loader = true; 
        this.paymentDetails.verifyUser(eventObj).subscribe(
          (data: any) => {
            this.loader = false;
            if (data.statusCode == 200 && data.result=="Welcome! You're new to our system. You can proceed with the registration.") {
            this.paymentRedirection()
              this.loader = false;
            }else{
              this.loader = false;
            }
            if( data.statusCode==201  && data.result=="You've already registered for this event. Would you like to make another purchase?"){
              this.confirmationService.confirm({
                message: 'You have already bought a ticket. Click Yes to buy more.',
                header: 'Confirmation',
                icon: 'pi pi-info-circle',
                acceptIcon:"none",
                rejectIcon:"none",
                rejectButtonStyleClass:"p-button-text",
                accept: () => {
                    this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'Resuming the payment procedure' });
                    this.paymentRedirection()
                  
                },
                reject: () => {
                    this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'Payment Process Rejected', life: 3000 });
                    this.flag= true
                    this.hideButton = false
                    this.resetFormValues()
                },
                key: 'positionDialog'
            });

            }
          },
          (error: any) => {
            this.appinsightsService.logException(error);
            this.appinsightsService.logEvent('Error: Getting latest news api', {
              error: JSON.stringify(error),
            });
          }
        );
        }
        catch(error: any) {
          this.appinsightsService.logException(error, 2);
        }
      } else {
        for (const field in this.eventForm.controls) {
          this.eventForm.controls[field].markAsDirty();
        }
      }
    } catch (error: any) {
      this.appinsightsService.logException(error, 2);
    }
    // this.paymentSuccessMessage();
    this.loader = false
  
  }

  paymentSuccessMessage() {
    this.loader = true;
    let orderID = localStorage.getItem('orderID');
    this.loader = true;
    let paymentData = JSON.parse(orderID || '');
   
    if (
      paymentData != null ||
      (undefined && paymentData.paymentCompletedFlag)
    ) {
  
      this.paymentConfirmationFlag = true;
      this.loader = false;
    }
    localStorage.clear();
  
  }
  paymentRedirection(){
    this.loader = true;
    this.loadPayPalScript().then(
      () => {
        this.loader = false;
        paypal
          .Buttons({
            style: {
              layout: 'vertical',
              color: 'gold',
              shape: 'rect',
              label: 'paypal',
              disableMaxWidth: true,
            },
            createOrder: async (data: any, actions: any) => {
              this.showError = '';
              this.loader = true;
              // localStorage.removeItem("addedCourses")
              try {
                // this.validation.decodeLoggedInToken();

                const ticketDetails = await this.paymentDetails
                  .eventTicket(this.storeTicketDetails)
                  .toPromise();

           
                if (ticketDetails.statusCode == 200) {
                  this.loader = false;
                  this.orderId = ticketDetails.result;
                  this.storeTicketDetails.orderID = this.orderId;
                  return ticketDetails.result;
                }
              } catch (error: any) {
                {
                  this.hideButton = false;
                  this.appinsightsService.logException(error, 1);
                  this.appinsightsService.logEvent(
                    'Error: Getting error in create order api',
                    {
                      payload: JSON.stringify(this.storeTicketDetails),
                      error: JSON.stringify(error),
                    }
                  );

                  if (error.status == 401) {
                    this.messageService.add({
                      severity: 'error',
                      summary: 'Error',
                      detail: error.statusText,
                    });
                  } else if (error.status == 500) {
                    this.showError = 'Something went wrong at server side!';
                    this.messageService.add({
                      severity: 'error',
                      summary: 'Error',
                      detail: this.showError,
                    });
                  } else {
                    this.messageService.add({
                      severity: 'error',
                      summary: 'Error',
                      detail: (this.showError = error.error.detail),
                    });
                  }
                  this.resetFormValues();
                }
                this.loader = false;
              }
            },

            onApprove: (data: any, actions: any) => {
              // Capture the transaction details and handle payment approval
              // Call your API or perform necessary actions

              try {
                this.loader = true;
                {
                  this.paymentDetails
                    .approveTicket(
                      this.storeTicketDetails.orderID,
                      this.storeTicketDetails
                    )
                    .subscribe(
                      (data) => {
                        this.loader = false;
                        if (data.statusCode == 200) {
                          const paymentData = {
                            orderID: this.storeTicketDetails.orderID,
                            paymentCompletedFlag: true,
                          };
                          localStorage.setItem(
                            'orderID',
                            JSON.stringify(paymentData)
                          );
                          this.showPaymentBlock = true;
                          this.loader = false;
                          this.paymentSuccessMessage();
                          // return data;
                        } else {
                          this.showPaymentBlock = false;
                          this.loader = false;
                          this.showError = data.result.message;
                        }
                        this.paymentSuccessMessage();
                      },
                      (error: any) => {
                        this.loader = false;
                        this.appinsightsService.logException(error, 1);
                        this.appinsightsService.logEvent(
                          'Error: Getting error in capture order api'
                        );
                        if (error.status == 500) {
                          this.showError =
                            ' Something went wrong at server side!';
                        } else {
                          this.showError = error.error.responseException;
                        }
                      }
                    );
                }
                this.paymentSuccessMessage();
              } catch (error: any) {
                this.loader = false;
                this.appinsightsService.logException(error, 2);
              }
              this.paymentSuccessMessage();
            },
          })
          .render('#paypal-button-container'); // Render the PayPal button in the specified container
      },
      (error: any) => {
        this.appinsightsService.logException(error, 1);
        this.appinsightsService.logEvent(
          'Error: Getting error in adding donation api'
        );
      }
    );
    this.loader = false;

  }
  backtoHome() {
    this.router.navigate(['/home']);
  }
  resetFormValues() {
    this.eventForm.get('firstname')!.reset();
    this.eventForm.get('lastname')!.reset();
    this.eventForm.get('Phone')!.reset();
    this.eventForm.get('email')!.reset();
    this.eventForm.get('Address')!.reset();
  }

  private loadPayPalScript(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const scriptElement = document.createElement('script');
      scriptElement.src = `https://www.paypal.com/sdk/js?client-id=${environment.paypalClientId}`;
      scriptElement.onload = () => {
        resolve(); // Resolve the promise once the script is loaded
      };
      scriptElement.onerror = reject;
      document.body.appendChild(scriptElement);
    });
  }

  getPrice() {
    try {
      this.loader = true;
      this.paymentDetails.getPrice().subscribe(
        (data: any) => {
          if (data.statusCode == 200) {
            this.loader = false;
            this.storeUnitData = data.result;
          }
        },
        (error: any) => {
          this.appinsightsService.logException(error);
          this.appinsightsService.logEvent('Error: Getting latest news api', {
            error: JSON.stringify(error),
          });
        }
      );
    } catch (error: any) {
      this.loader = false;
      this.appinsightsService.logException(error, 1);
    }
  }
}
