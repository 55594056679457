import { Component, OnInit } from '@angular/core';
import { LoggingService } from 'src/app/services/logging.service';

@Component({
  selector: 'app-admin-view',
  templateUrl: './admin-view.component.html',
  styleUrls: ['./admin-view.component.css']
})
export class AdminViewComponent implements OnInit {

  constructor(private appinsightsService: LoggingService,) { }

  ngOnInit(): void {
    this.appinsightsService.logEvent('Admin screen visited')
  }

}
