import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';
import { map } from 'rxjs';
import { parentDetails } from '../components/registration/registration-forms/parent-details/parent-details';
import { joinus } from '../components/join-us/join-us';
import { contactus } from '../components/conctac-us/contact-us';

@Injectable({
  providedIn: 'root'
})
export class ParentDetailsService {
  constructor(private http: SharedService) { }


  verifyParent(userdata:{email:string}){
    let url = 'api/UserOtp';
    return this.http.post(url,userdata)
    .pipe(map((data: any) => data));
  }
  getStates(){
    let url = 'api/Countries/States';
    return this.http.get(url)
    .pipe(map((data: any) => data));
  }

  checkParentExists(email:string){
    let url = `api/User/Parent?email=${email}`;
    return this.http.get(url)
    .pipe(map((data: any) => data));
  }

  verifyOtp(userData:{email:string, otp:string}){
    let url = 'api/UserOtp/verify';
    return this.http.post(url, userData)
    .pipe(map((data: any) => data));
  }

  addNewUser(userData:parentDetails,id:any){
    if(id){
      let url = `api/User/${id}`;
      return this.http.put(url,userData).pipe(map(data => data))      
    }else{
      let url = 'api/User';
      return this.http.post(url,userData).pipe(map(data => data))      
    }
  
  }

  getParentDetails(){
    let url = 'api/User';
    return this.http.get(url)
    .pipe(map((data: any) => data));
  }

  joinAsVolunteer(volunteerData:joinus){
    let url = 'api/Home/Volunteer';
    return this.http.post(url,volunteerData).pipe(map(data => data))  
  }

  contactUs(contactUsData:contactus){
    let url = 'api/Home/ContactUs';
    return this.http.post(url,contactUsData).pipe(map(data => data))  
  }

   getRenewedToken(token:string){
    let url = `api/Token/renew?token=${token}`;
    return this.http.post(url).pipe(map(data => data));  
  }
  getBranches(){
    let url = 'api/Branches';
    return this.http.get(url)
    .pipe(map((data: any) => data));
  }
}
