import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: SharedService) { }

  adminLogin(adminObj:{  "email": string,"password": string}){
    let url = 'api/User/AdminLogin';
    return this.http.post(url,adminObj).pipe(map(data => data))       
  }

  passwordResetEmail(email:string){
    let url = `api/User/PasswordResetEmail?email=${email}`;
    return this.http.post(url).pipe(map(data => data))   
  }

  createNewPassword(passwordObj:{password: string,confirmPassword:string}){
    let url = 'api/User/Password';
    return this.http.put(url,passwordObj).pipe(map(data => data)) 
  }
}
