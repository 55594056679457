<div class="container-fluid">
    <div class="row px-md-5">
        <div class="col-12 text-center">
            <p role="button" class="text-danger d-flex align-items-center justify-content-start py-3" (click)="navigateBack()"><i
                    class="pi pi-arrow-left px-1"></i> Back to Enrollments</p>
            <h4 class="fw-semibold m-0">Enrollment ID-{{enrollmentId}} </h4>
        </div>
    </div>
    <div *ngIf="!loader">
    <div class="row px-md-5 py-3">
        <div class="col-12">
            <h5 class="fw-semibold py-3">Accademic Year</h5>
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-12 col-md-3">
                    <p class="dark-ask m-0">Parent ID</p>
                    <p class="fw-semibold">{{enrollmentDetails.parentId}}</p>
                </div>
                <div class="col-12 col-md-3">
                    <p class="dark-ask m-0">Registration Date</p>
                    <p class="fw-semibold">{{enrollmentDetails.registrationDate | date:'mediumDate'}}</p>
                </div>
                <div class="col-12 col-md-3">
                    <p class="dark-ask m-0">Email</p>
                    <p class="fw-semibold">{{enrollmentDetails.email}}</p>
                </div>
                <div class="col-12 col-md-3">
                    <p class="dark-ask m-0">Alternative Email</p>
                    <p class="fw-semibold">{{enrollmentDetails.alternativeEmail}}</p>
                </div>
                <div class="col-12 col-md-3">
                    <p class="dark-ask m-0">First Name</p>
                    <p class="fw-semibold">{{enrollmentDetails.firstName}}</p>
                </div>
                <div class="col-12 col-md-3">
                    <p class="dark-ask m-0">Last Name</p>
                    <p class="fw-semibold">{{enrollmentDetails.lastName}}</p>
                </div>
                <div class="col-12 col-md-3">
                    <p class="dark-ask m-0">Phone Number</p>
                    <p class="fw-semibold">{{enrollmentDetails.phoneNumber}}</p>
                </div>
                <div class="col-12 col-md-3">
                    <p class="dark-ask m-0">Alternative Phone Number</p>
                    <p class="fw-semibold">{{enrollmentDetails.alternativePhoneNumber}}</p>
                </div>
                <div class="col-12 col-md-3">
                    <p class="dark-ask m-0">Address</p>
                    <p class="fw-semibold">{{enrollmentDetails.address}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row px-md-5 py-3">
        <div class="col-12">
            <h5 class="fw-semibold py-3">Student Details</h5>
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-12 col-md-3">
                    <p class="dark-ask m-0">First Name</p>
                    <p class="fw-semibold">{{enrollmentDetails.studentFirstName}}</p>
                </div>
                <div class="col-12 col-md-3">
                    <p class="dark-ask m-0">Last Name</p>
                    <p class="fw-semibold">{{enrollmentDetails.studentLastName}}</p>
                </div>
                <div class="col-12 col-md-3">
                    <p class="dark-ask m-0">Telugu Name</p>
                    <p class="fw-semibold">{{enrollmentDetails.studentTeluguName}}</p>
                </div>
                <div class="col-12 col-md-3">
                    <p class="dark-ask m-0">Age</p>
                    <p class="fw-semibold">{{enrollmentDetails.studentAge}}</p>
                </div>
                <div class="col-12 col-md-3">
                    <p class="dark-ask m-0">Gender</p>
                    <p class="fw-semibold">{{enrollmentDetails.studentGender}}</p>
                </div>
                <div class="col-12 col-md-3">
                    <p class="dark-ask m-0">Relationship</p>
                    <p class="fw-semibold">{{enrollmentDetails.relation}}</p>
                </div>
                <div class="col-12 col-md-3">
                    <p class="dark-ask m-0">Previous Attended Center</p>
                    <p class="fw-semibold">{{enrollmentDetails.previousAttendedCenter}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row px-md-5 py-3">
        <div class="col-12">
            <h5 class="fw-semibold py-3">Course Details</h5>
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-12 col-md-3">
                    <p class="dark-ask m-0">Course Type</p>
                    <p class="fw-semibold">{{enrollmentDetails.courseType}}</p>
                </div>
                <div class="col-12 col-md-3">
                    <p class="dark-ask m-0">Category</p>
                    <p class="fw-semibold">{{enrollmentDetails.courseCategory}}</p>
                </div>
                <div class="col-12 col-md-3">
                    <p class="dark-ask m-0">Course Name</p>
                    <p class="fw-semibold">{{enrollmentDetails.courseName}}</p>
                </div>
                <div class="col-12 col-md-3">
                    <p class="dark-ask m-0">Timings(Available slots are in pacific time)</p>
                    <p class="fw-semibold">{{enrollmentDetails.timings}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row px-md-5 py-3">
        <div class="col-12">
            <h5 class="fw-semibold py-3">Payment and Refund Details</h5>
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-12 col-md-3">
                    <p class="dark-ask m-0">Amount</p>
                    <p class="fw-semibold">{{enrollmentDetails.refundAmount}}</p>
                </div>
                <div class="col-12 col-md-3">
                    <p class="dark-ask m-0">Payment Date</p>
                    <p class="fw-semibold">{{enrollmentDetails.paymentDate | date:'mediumDate'}}</p>
                </div>
                <div class="col-12 col-md-3">
                    <p class="dark-ask m-0">Refund Initiated Date</p>
                    <p class="fw-semibold">{{enrollmentDetails.refundInitiatedDate | date:'mediumDate'}}</p>
                </div>
                <div class="col-12 col-md-3">
                    <p class="dark-ask m-0">Refund Amount</p>
                    <p class="fw-semibold">${{enrollmentDetails.refundAmount}}</p>
                </div>
                <div class="col-12 col-md-3">
                    <p class="dark-ask m-0">Refund Status</p>
                    <p class="fw-semibold">{{enrollmentDetails.refundStatus}}</p>
                </div>
            </div>
        </div>
    </div>
    </div>
    <div class="row pt-4" *ngIf="loader">
        <div class="col text-center">
            <p-progressSpinner *ngIf="loader" class="custom-spinner" strokeWidth="3"
                animationDuration=".5s"></p-progressSpinner>
        </div>
    </div>
</div>