<div class="container-fluid">
    <p-toast position="top-center"></p-toast>
    <div class="row">
        <div class="col-12 text-center  py-3">
            <h4 class="fw-semibold m-0">View Enrollments</h4>
        </div>
    </div>
    <div class="row pt-4" *ngIf="loader">
        <div class="col text-center">
            <p-progressSpinner *ngIf="loader" class="custom-spinner" strokeWidth="3"
                animationDuration=".5s"></p-progressSpinner>
        </div>
    </div>
    <div class="row" *ngIf="!loader">
        <div class="col-12 p-0">
            <p-table #enrollmentsData [tableStyle]="{ 'min-width': '50rem' }" [value]="enrollments"
                class="enrollments_table" sortMode="single" [responsive]="true" [rows]="5" [paginator]="true"
                [rowsPerPageOptions]="getPaginationOptions()" currentPageReportTemplate="Total Records {totalRecords} "    [showCurrentPageReport]="true"
                [globalFilterFields]="['enrollmentId', 'parentName','parentId' ,'studentName','regDate','centerName','courseType','courseName','timing','amount']">
                <ng-template pTemplate="caption">
                    <div class="flex row d-flex align-items-center">
                        <div class="col-12 col-md-6 p-input-icon-left ml-auto">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <p-dropdown name="academicYr" id="academicYr" placeholder="Select a Academic Year" [options]="academicYearsList" optionLabel="name" 
                                    [(ngModel)]="selectedAcademicYear" class="d-block dropdown" (onChange)="getEnrollments(null,selectedAcademicYear.id,null)"></p-dropdown>
                                </div>
                                <div class="col-12 col-md-6">
                                    <input pInputText type="text" class="form-control search_field"
                                    (input)="filterGlobal($event, 'contains')" placeholder=" Search " />
                                </div>
                            </div>
                        </div>
                        <div
                            class="col-12 col-md-4 offset-md-2 p-input-icon-left ml-auto d-flex align-items-center justify-content-end">
                           <!-- [disabled]="disableCourseChange" -->
                            <button pButton pRipple type="button" label="Change Course" 
                                class="btn btn-success register_btn m-1 changeCourseButton " [disabled]="true" (click)="openChangeCourseDialog()"></button>
                                <!-- [disabled]="disableRefund" -->
                            <button pButton pRipple type="button" [disabled]="true" label="Refund" 
                                class="btn btn-danger register_btn m-1 refundBtn" (click)="openRefundDialog()"></button>

                                <button  type="button"
                                class="btn btn-warning px-3  " style="padding: 10px;" (click)="downloadExcel()"><span class="fa fa-download" style="font-size: 16px;" aria-hidden="true"></span></button>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th class="bg-white"></th>
                        <th class="bg-white">Enrollment ID</th>
                        <th class="bg-white">Parent ID</th>
                        <th class="bg-white">Parent Name</th>
                        <th class="bg-white">Student Name</th>
                        <th class="bg-white">Reg Date</th>
                        <th class="bg-white">Center Name</th>
                        <th class="bg-white">Course Type</th>
                        <th class="bg-white">Course Name</th>
                        <th class="bg-white">Timings</th>
                        <th class="bg-white">Amount</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-enrollment let-rowIndex="rowIndex">
                    <tr *ngIf="enrollments.length > 0">
                        <td> <p-radioButton class="pr-2" required name="enrollment" [inputId]="enrollment.enrollmentId"
                                name="enrollment" [value]="enrollment" [(ngModel)]="selectedEnrollment"
                                [value]="enrollment" (click)="selectEnrollment(enrollment)"></p-radioButton></td>
                        <td (click)="navigateToDetailView(enrollment)" role="button"
                            class="">{{ enrollment.enrollmentId }}</td>
                        <td>{{ enrollment.parentId }}</td>
                        <td>{{ enrollment.parentName }}</td>
                        <td>{{ enrollment.studentName }}</td>
                        <td>{{ enrollment.regDate | date:'mediumDate'}}</td>
                        <td>{{ enrollment.centerName }}</td>
                        <td>{{ enrollment.courseType }}</td>
                        <td>{{ enrollment.courseName }}</td>
                        <td>{{ enrollment.timing }}</td>
                        <td>{{ enrollment.amount}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">No Enrollments found.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <p-dialog [(visible)]="showChangeCourseDialog" [style]="{width: '80vw'}" [modal]=true>
        <div class="row">
            <div class="col-12">
                <h4 class="fw-bold text-dark mb-4">Change Course - <span class="text-danger text-capitalize">{{selectedEnrollmentDetails?.studentName}}</span></h4>
                <h5 class="fw-bold text-dark mb-4">Academic Year - {{selectedAcademicYear?.name}} </h5>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h5 class="fw-bold text-dark mb-4">General Details </h5>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4">
                <label class="dark-ask">Parent ID</label>
                <p class="text-dark">{{selectedEnrollmentDetails?.parentId}}</p>
            </div>
            <div class="col-12 col-md-4">
                <label class="dark-ask">Parent Name</label>
                <p class="text-dark">{{selectedEnrollmentDetails?.parentName}}</p>
            </div>
            <div class="col-12 col-md-4">
                <label class="dark-ask">Parent Email</label>
                <p class="text-dark">{{selectedEnrollmentDetails?.parentEmail}}</p>
            </div>
            <div class="col-12 col-md-4">
                <label class="dark-ask">Registration Date</label>
                <p class="text-dark">{{selectedEnrollmentDetails?.regDate | date:'mediumDate'}}</p>
            </div>
            <div class="col-12 col-md-4">
                <label class="dark-ask">Student Name</label>
                <p class="text-dark">{{selectedEnrollmentDetails?.studentName}}</p>
            </div>
            <div class="col-12 col-md-4">
                <label class="dark-ask">Student Age</label>
                <p class="text-dark">{{selectedEnrollmentDetails?.studentAge}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h5 class="fw-bold text-dark my-4">Course Details </h5>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <form [formGroup]="addCourseDetails" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="col-md-12 p-2 px-md-3">
                          
                            <label for="courseType" class="dark-ask pt-md-3">Course Type<span class="text-danger"> *</span></label>
                            <div class="d-flex align-items-center">
                                <div  *ngFor="let course of courseTypeArray" class="d-flex align-items-center">
                                    <p-radioButton formControlName="courseType" [(ngModel)]="selectedCourseType" [disabled]="selectedCourseType && selectedCourseType.id !== course.id" class="pr-2" required [inputId]="course.id" name="courseType"
                                    [value]="course" [(ngModel)]="selectedCourseType" ></p-radioButton>
                                    <!-- (click)="onCourseTypeSelection()" -->
                                    <label class="m-2">{{ course.label }}</label>
                                </div>
                            </div>
                            <div class="text-danger" >
                                <span class="text-capitalize">{{ validation.validateFormControl('courseType','Course Type',addCourseDetails) }}</span>
                            </div>
                        </div>
                    <div class="row">
                        <div class="col-xs-12 col-md-6 p-2 px-md-3" >
                            <label for="Country" class="dark-ask">Country<span class="text-danger"> *</span></label>
                            <!-- (ngModelChange)="onSelectedCountry($event?.id)" [options]="countryApiArray" -->
                            <p-dropdown name="Country" id="Country"  
                                        formControlName="Country" class="dropdown d-block studentDetail_dropdown"  [options]="countryApiArray"  [disabled]="true" optionLabel="countryName"></p-dropdown>
                            <div class="text-danger" >
                                <span class="text-capitalize"><p-progressSpinner *ngIf="loader" class="custom-spinner" strokeWidth="3"
                                    animationDuration=".5s"></p-progressSpinner></span>
                                <span class="text-capitalize">{{ validation.validateFormControl('Country','Country',addCourseDetails) }}</span>
                            </div>
                        </div>
                    </div>
                        <!-- <div class="col-12 pb-md-2 px-md-5" *ngIf="!showLocations">
                          
                            <span class="text-danger col-12  " style="font-size: 14px;">( Note: 1. International shipping charges: $60 , &#160; 2. Domestic shipping charges: $12 )</span>
                        </div> -->
                       
                        <div class="col-12 p-2 px-md-3" *ngIf="showLocations && locationsArray.length > 0; else noLocations">
                            <h6 class="fw-bold">Preferred Location Center</h6>
    
                        </div>
                        <ng-template #noLocations>
                            <div class="col-12 p-2 px-md-3" *ngIf="showLocations">
                                <h6 class="fw-bold">No locations found.</h6>
                            </div>
                        </ng-template>
                        
                        <div class=" col-md-12 p-2 px-md-3" *ngIf="showLocations">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="row d-flex w-100">
                                    <div *ngFor="let location of locationsArray" class=" col-md-2 d-flex align-items-center">
                                        <p-radioButton formControlName="locationName" class="pr-2" [inputId]="location.branchId"  name="locationName"
                                        [value]="location" [(ngModel)]="selectedLocation" (click)="onLocationSelection()"></p-radioButton>
                                        <label  class="m-2">{{ location.branchName }}</label>
                                    </div>
                                    <!-- <div *ngIf="this.preFerredLocation">
                                        <span class="text-capitalize">Please Select Location, If you stay in US</span>
                                    </div> -->
                                </div>
                            </div>
                            <div class="text-danger" >
                                <span class="text-capitalize">{{ validation.validateFormControl('locationName','Location',addCourseDetails) }}</span>
                            </div>
                        </div>
    
                    
                        <div class="col-md-6 p-2 px-md-3">
                            <label for="category" class="dark-ask">Category<span class="text-danger"> *</span></label>
                            <p-dropdown name="category" id="category" placeholder="Select a Category" [options]="categoryList" optionLabel="label" 
                            (onChange)="onCategorySelection($event)" formControlName="category" class="d-block dropdown"></p-dropdown>
                            <div class="text-danger" >
                                <span class="text-capitalize">{{ validation.validateFormControl('category','category',addCourseDetails) }}</span>
                            </div>
                        </div>
                        <div class=" col-md-6 p-2 px-md-3">
                            <label for="coursename" class="dark-ask">Course Name<span class="text-danger"> *</span></label>
                            <p-dropdown name="coursename" id="coursename" placeholder="Select a Course" [options]="coursesList" optionLabel="label" 
                            (onChange)="onCourseSelection($event)" formControlName="coursename" class="d-block dropdown"></p-dropdown>
                            <div class="text-danger" >
                                <span class="text-capitalize">{{ validation.validateFormControl('coursename','Course Name',addCourseDetails) }}</span>
                            </div>
                        </div>
                        <div class=" col-md-6 p-2 px-md-3">
                            <label for="timings" class="dark-ask">Timings<span class="text-danger"> *</span> (Available slots are in Pacific time)</label>
                            <p-dropdown name="timings" id="timings" placeholder="Select a slot"  [options]="timings" optionLabel="name" 
                            formControlName="timings" class="d-block dropdown"></p-dropdown>
                            <!-- [(ngModel)]="timingsPatch" -->
                            <div class="text-danger" >
                                <span class="text-capitalize">{{ validation.validateFormControl('timings','timings',addCourseDetails) }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 d-flex align-items-center justify-content-start">
                            <button pButton pRipple type="submit" class="btn-blue d-flex align-items-center justify-content-center"
                            [ngClass]="{'px-5 text-center text-white': loader}">
                            <span *ngIf="!loader" class="text-white">Save</span>
                            <p-progressSpinner *ngIf="loader" class="custom-spinner" strokeWidth="3"
                                animationDuration=".5s"></p-progressSpinner>
                            </button>
                            <a (click)="closeScreen()"><span
                                class="text-danger d-inline-block p-3 cursor">Cancel</span></a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </p-dialog>

    <p-dialog [(visible)]="showRefundCourseDialog" [style]="{width: '80vw'}" [modal]=true>
        <div class="row">
            <div class="col-12">
                <h4 class="fw-bold text-dark mb-4">Refund</h4>
                <h5 class="fw-bold text-dark mb-4">Academic Year - {{selectedAcademicYear?.name}} </h5>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-4">
                <p class="dark-ask m-0">Enrollment ID</p>
                <p class="text-dark">{{selectedEnrollment?.enrollmentId}}</p>
            </div>
            <div class="col-12 col-md-4">
                <p class="dark-ask m-0">Parent ID</p>
                <p class="text-dark">{{refundDetails?.parentId}}</p>
            </div>
            <div class="col-12 col-md-4">
                <p class="dark-ask m-0">Parent Name</p>
                <p class="text-dark">{{refundDetails?.firstName}} {{refundDetails?.lastName}} </p>
            </div>
            <div class="col-12 col-md-4">
                <p class="dark-ask m-0">Reg Date</p>
                <p class="text-dark">{{refundDetails?.registrationDate | date:'mediumDate'}}</p>
            </div>
            <div class="col-12 col-md-4">
                <p class="dark-ask m-0">Center Name</p>
                <p class="text-dark">{{refundDetails?.location}}</p>
            </div>
            <div class="col-12 col-md-4">
                <p class="dark-ask m-0">Course Type</p>
                <p class="text-dark">{{refundDetails?.courseType}}</p>
            </div>
            <div class="col-12 col-md-4">
                <p class="dark-ask m-0">Student Name</p>
                <p class="text-dark">{{refundDetails?.studentFirstName}} {{refundDetails?.studentLastName}}</p>
            </div>
            <div class="col-12 col-md-4">
                <p class="dark-ask m-0">Course Name</p>
                <p class="text-dark">{{refundDetails?.courseName}}</p>
            </div>
            <div class="col-12 col-md-4">
                <p class="dark-ask m-0">Course Timings</p>
                <p class="text-dark">{{refundDetails?.timings}}</p>
            </div>
            <div class="col-12 col-md-4">
                <p class="dark-ask m-0">Amount</p>
                <p class="text-dark">{{refundDetails?.courseFee}}</p>
            </div>
        </div>
        <div class="row py-4">
            <div class="col-12 d-flex align-items-center justify-content-start">
                <button pButton pRipple type="button" class="btn-blue d-flex align-items-center justify-content-center"
                [ngClass]="{'px-5 text-center text-white': loader}" (click)="onRefundInitiation()">
                <span *ngIf="!loader" class="text-white">OK</span>
                <p-progressSpinner *ngIf="loader" class="custom-spinner" strokeWidth="3"
                    animationDuration=".5s"></p-progressSpinner>
                </button>
                <a (click)="closeScreen()"><span
                    class="text-danger d-inline-block p-3 cursor">Cancel</span></a>
            </div>
        </div>
    </p-dialog>
</div>
<p-confirmDialog key="confirmationMsg" #rcd [style]="{width: '50vw'}">
    <ng-template pTemplate="header">
        <h3 class="text-dark fw-semibold mb-4">Refund</h3>
    </ng-template>
    <ng-template pTemplate="footer">
        <button type="button" pButton   class="btn-blue " label="OK" (click)="rcd.accept()"></button>
        <a (click)="rcd.reject()"><span
            class="text-danger d-inline-block p-3">Cancel</span></a>
    </ng-template>
  </p-confirmDialog>
