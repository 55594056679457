import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { PaymentService } from 'src/app/services/payment.service';
import { orderData } from './payment-details';
import { ConfirmationService, MessageService } from 'primeng/api';
import { HomeService } from 'src/app/services/home.service';
import { Router } from '@angular/router';
import { LoggingService } from 'src/app/services/logging.service';
import { environment } from 'src/environments/environment';
import { ValidationService } from 'src/app/services/validation.service';
import { SharedService } from 'src/app/services/shared.service';
import { CourseDetailsService } from 'src/app/services/course-details.service';
import { ErrorHandlingService } from 'src/app/services/error-handling.service';

declare var paypal: any; // Declare PayPal variable

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.css']
})
export class PaymentDetailsComponent implements OnInit {
  // carddata:{"enrollmentIds":[]};
  shippingAmount:any
  showPaymentBlock: boolean = true;
  showError: string = "";
  selectedStudentName: string = "";
  addedCoursesList:any;
  totalAmount:number = 0;
  loader: boolean = false;
  orderId: any = 0;
  countryId: any;
  isValidationNotRequried:any;
  academicYearsList: any;
  selectedAcademicYear: any;
  constructor(
    private paymentDetails: PaymentService, 
    private homeDetails: HomeService,
    private courseDetails: CourseDetailsService,
    private router: Router, 
    private sharedService: SharedService,
    private appinsightsService: LoggingService,
    private confirmationService: ConfirmationService,
    public validation: ValidationService,
    private errorHandling: ErrorHandlingService
  ) {}

  ngOnInit() {
    try{
      window.scrollTo(0,0) 
      this.appinsightsService.logEvent('payment-details form viewed')
    // Load the PayPal script and render the button
    this.loader = true;
    let name: any  = (localStorage.getItem('selectedStudentName'));
    this.selectedStudentName =  name? JSON.parse(name): "";
    this.countryId = JSON.parse(localStorage.getItem('CountryID')||'1')
    this.getCoursesList();
    this.getAcademicYears();
    let value: any  = (localStorage.getItem('selectedStudentId'));
    let studentId =  value? JSON.parse(value): 0;
    let data: any  = (localStorage.getItem('addedCourses'));
    let totalCourses =  value? JSON.parse(data): [];
      let courseObj:any = [{
        "studentId": studentId,
        courseSlotIds: [],
        "academicYearId": localStorage.getItem('academicYearId')
      }]
    totalCourses.forEach((element:any, index:number) => {
        courseObj[0].courseSlotIds.push( element?.timings?.courseSlotId)
    })
    this.loadPayPalScript().then(() => {
      this.loader = false;
      paypal.Buttons({
        style: {
          layout: 'vertical',
          color:  'gold',
          shape:  'rect',
          label:  'paypal',
          disableMaxWidth: true,
        },
        createOrder: async (data:any, actions:any) => {
          this.showError = "";
          this.loader = true;
          localStorage.removeItem("addedCourses")
          try{
          this.validation.decodeLoggedInToken();
           const orderDetails =   await this.paymentDetails.createOrder(courseObj).toPromise()
              if(orderDetails.statusCode == 200){
                this.loader = false;
                this.orderId = orderDetails.result.id;
                return orderDetails.result.id;
              }
            }
          catch(error:any){
           {
            this.appinsightsService.logException(error,1);
            this.appinsightsService.logEvent("Error: Getting error in create order api", {payload: JSON.stringify(courseObj),error:JSON.stringify(error)})
            if(error.status == 401){
             this.sharedService.token_Expired.next(true);
             localStorage.clear();
             this.confirmationService.confirm({
              message: 'Apologies, but your session has timed out. To proceed, please log in again.',
              header: 'Confirmation',
              icon: 'pi pi-exclamation-triangle',
              key:'toastMsg',
              accept: () => {
               localStorage.clear()
               this.homeDetails.isUserLoggedIn.next(false);
              },
            })
            }
            else if(error.status == 500){
              this.showError ="Something went wrong at server side!"
            }
            else {
              this.showError = error.error.detail
            }
            }
            this.loader = false;
          }
        },
        onApprove: (data:any, actions:any) => {
          // Capture the transaction details and handle payment approval
          // Call your API or perform necessary actions
          try{
            this.loader = true;
            this.validation.decodeLoggedInToken();
             this.paymentDetails.approveOrder(data.orderID).subscribe((data)=>{
              if(data.statusCode == 200){
                this.showPaymentBlock = false;
                
                this.isValidationNotRequried = true
                localStorage.setItem("isValidationNotRequried", this.isValidationNotRequried);
          
                this.loader = false;
                return data;
              }
              else{
                this.showPaymentBlock = true;
                this.loader = false;
                this.showError = data.result.message
              }
            },(error:any)=>{
              this.loader = false;
              this.appinsightsService.logException(error,1);
              this.appinsightsService.logEvent("Error: Getting error in capture order api", {payload: JSON.stringify(data.orderID),error:JSON.stringify(error)})
              if(error.status == 500){
                this.showError =" Something went wrong at server side!"
              }
              else {
                this.showError = error.error.responseException
              }
            })
          }
          catch(error:any){
            this.loader = false;
            this.appinsightsService.logException(error,2);
          }
        },
      }).render('#paypal-button-container'); // Render the PayPal button in the specified container
    });
  }
  catch(error){
    console.log(error)
  }
}
getAcademicYears() {
  try {
    this.loader = true;
    this.courseDetails.getAcademicYears().subscribe((data: any) => {
      if(data.statusCode=200){}
      this.loader = false;
      this.academicYearsList = data.result;
      this.academicYearsList.forEach((item: any) => {
        if (item.isRegistrationsOpen == true) {
          this.selectedAcademicYear = item;
        }
      });
    
    },
    (error: any) => {
      this.loader = false;
      this.appinsightsService.logException(error, 1);
      this.appinsightsService.logEvent(
        'Error: Getting error in change course api',
        { error: JSON.stringify(error) }
      );
      this.errorHandling.ErrorHandling(error);
    });
  } catch (error: any) {

    this.appinsightsService.logException(error);
  }
}


  private loadPayPalScript(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const scriptElement = document.createElement('script');
      scriptElement.src = `https://www.paypal.com/sdk/js?client-id=${environment.paypalClientId}`; // Replace with your PayPal client ID
      scriptElement.onload = () => {
        resolve(); // Resolve the promise once the script is loaded
      };
      scriptElement.onerror = reject;
      document.body.appendChild(scriptElement);
    });
  }

  getCoursesList(){
    try{
  
    this.addedCoursesList=[];
    if(localStorage.getItem("addedCourses")){
      const value: any  = (localStorage.getItem('addedCourses'));
      this.addedCoursesList =  value? JSON.parse(value): [];
      this.calculateAmount();
    }
    else{
      this.loader = false;
    }
  }
  catch(error:any){
    this.appinsightsService.logException(error,2)
  }
  }
 
  calculateAmount(){
    try{
      let value: any  = (localStorage.getItem('addedCourses'));
      let data =  value? JSON.parse(value): [];
      let checkForOnlineCourse = data.filter((item:any) => item.courseType.id === "1");
      if(data.length > 0){
       this.totalAmount = 0;
         data.map((x:any)=>{
          checkForOnlineCourse.length>0? this.shippingAmount=x.shippingAmount : this.shippingAmount=0;
          this.totalAmount += x.amount;
        })
        this.totalAmount+=this.shippingAmount;
      }
      else{
        this.totalAmount = 0;
      }
    }
    catch(error:any){
      this.appinsightsService.logException(error,2);
    }
  }
  navigateToHome(){
    this.router.navigate(['/home']);
  }
}
